import config from '@/config/config'
import axios from 'axios'

export default {
    verify() {
        return axios.get(`${config.apiUrl}/usuario`, {
            headers: {
                Authorization: `${localStorage.getItem('token')}`
            }
        })
    },
}
