<template>
    <div class="box-coluna ">
        <div class=" p-0 py-3 pb-0" v-if="showCharts && showGraphicsFilters">
            <!-- 5o vendas -->
            <div class=" px-2" style="">
                <h2 class="divisor-bottom h5 px-2 pb-2">
                    {{ dataGraphics.mensal.cpf.vendas }}
                    vendas para pessoas físicas (CPFs)
                </h2>
                <!-- Chart -->
                <div class="px-3">
                    <div class="fs-5 d-flex align-items-center gap-0">
                        <span class="text-green">
                            <img src="@/assets/img/icons/dollar-sign.svg" alt="" width="25">
                        </span>
                        R$ {{ dataGraphics.mensal.cpf.faturamento | money }}
                        <span class="text-muted fs-12px ms-2">
                            {{textFilterDays}}
                        </span>
                    </div>
                </div>
                <div class="text-dark">
                    <apexchart width="100%" height="140" type="area" :options="chart50Sales.chartOptions"
                        :series="chart50Sales.chartSeries" />
                </div>
            </div>

            <!-- 24 vendas -->
            <div class="divisor-top px-2 pt-4 pb-0 " style="margin-top: -20px; margin-bottom: -10px;">
                <h2 class="divisor-bottom h5 px-2 pb-2">
                    {{ dataGraphics.mensal.cnpj.vendas }}
                    vendas para pessoas jurídicas (CNPJs)
                </h2>
                <div class="px-3">
                    <div class="fs-5 d-flex align-items-center gap-0">
                        <span class="text-green">
                            <img src="@/assets/img/icons/dollar-sign.svg" alt="" width="25">
                        </span>
                        R$ {{ dataGraphics.mensal.cnpj.faturamento | money }}
                        <span class="text-muted fs-12px ms-2">
                            {{textFilterDays}}
                        </span>
                    </div>
                </div>
                <!-- Chart -->
                <div class="text-dark">
                    <apexchart width="100%" height="140" type="area" :options="chart24Sales.chartOptions"
                        :series="chart24Sales.chartSeries" />
                </div>
            </div>
        </div>

        <!-- Load -->
        <div class="py-5" v-else>
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-secondary" style="width: 1rem; height: 1rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

    </div>



</template>
<script>
    import apexchart from 'vue-apexcharts'
    export default {
        name: 'SalesPeople',
        components: {
            apexchart
        },
        data() {
            return {
                showCharts: false,
                chart50Sales: {
                    chartOptions: {
                        show: true,
                        borderColor: '#fff',
                        chart: {
                            id: 'vuechart-example',
                            curve: 'smooth',
                            toolbar: {
                                show: false
                            },
                        },
                        tooltip: {
                            marker: {
                                fillColors: ['#FFA895']
                            },
                            y: {
                                formatter: function (val) {
                                    return val.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    });

                                }
                            }
                        },
                        fill: {
                            colors: ['#FFA895', '#E91E63', '#9C27B0'],
                            opacity: 1,
                            gradient: {
                                shade: 'dark',
                                type: "vertical",
                                shadeIntensity: 0.5,
                                gradientToColors: undefined,
                                inverseColors: false,
                                opacityFrom: 1,
                                opacityTo: '0.8',
                                colorStops: []
                            },
                        },
                        markers: {
                            // xxxxxxxxxx line wave
                            size: 0,
                            strokeColors: '#fff',
                            colors: ['#fff'],
                            hover: {
                                size: 4
                            }
                        },
                        stroke: {
                            // xxxxxxxxxx
                            curve: 'straight',
                            colors: ['#FFA895'],
                            width: 2,
                        },
                        xaxis: {
                            categories: ['01/05/2023', '02/05/2023', '03/05/2023', '04/05/2023', '05/05/2023',
                                '06/05/2023', '07/05/2023', '08/05/2023', '09/05/2023', '10/05/2023', '11/05/2023',
                                '12/05/2023'
                            ],
                            labels: {
                                show: false,
                                style: {
                                    colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                        '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                    ],
                                },
                            },
                            tooltip: {
                                enabled: false,
                            },
                            axisBorder: {
                                // show: false
                                color: '#475266'
                            },
                            axisTicks: {
                                color: '#fff',
                                height: 5
                            },
                        },
                        yaxis: {
                            show: true,
                            labels: {
                                show: false,
                                style: {
                                    colors: ['#fff'],
                                },
                            },
                        },
                        grid: {
                            show: true,
                            borderColor: '#3E4759',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true,
                                    color: '#3E4759'
                                },
                            },
                            yaxis: {
                                lines: {
                                    show: true,
                                }
                            }
                        },
                        dataLabels: {
                            enabled: false, // tooltip visivel
                            // xxxxxxxxxx
                            offsetY: -7,
                            style: {
                                colors: [
                                    'rgba(0,0,0,.0)',
                                ],
                                fontSize: '11px',
                                borderColor: '#f00'
                            },
                            formatter: function (val) {
                                if (val >= 1000) {
                                    let numeroAbreviado = (val / 1000).toFixed(0);
                                    numeroAbreviado += "K";
                                    return numeroAbreviado;
                                } else {
                                    return val.toFixed(0);
                                }
                            },
                            background: {
                                enabled: true,
                                padding: 0,
                                borderRadius: 2,
                                borderWidth: 1,
                                borderColor: 'transparent',
                                opacity: 0.9,
                            },
                        },
                    },
                    chartSeries: [{
                        name: 'Vendas',
                        data: [3472, 3466, 2234, 1355, 6467, 2653, 3472, 3466, 2234, 1355, 6467, 2653],
                    }],
                },
                chart24Sales: {
                    chartOptions: {
                        show: true,
                        borderColor: '#fff',
                        chart: {
                            id: 'vuechart-example',
                            curve: 'smooth',
                            toolbar: {
                                show: false
                            },
                        },
                        tooltip: {
                            marker: {
                                fillColors: ['#ccc']
                            },
                            y: {
                                formatter: function (val) {
                                    return val.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    });
                                }
                            }
                        },
                        fill: {
                            colors: ['#FFFFFF', '#E91E63', '#9C27B0'],
                            opacity: 1,
                            gradient: {
                                shade: 'dark',
                                type: "vertical",
                                shadeIntensity: 0.5,
                                gradientToColors: undefined,
                                inverseColors: false,
                                opacityFrom: 1,
                                opacityTo: '0.8',
                                colorStops: []
                            },
                        },
                        markers: {
                            // xxxxxxxxxx line wave
                            size: 0,
                            strokeColors: '#fff',
                            colors: ['#fff'],
                            hover: {
                                size: 4
                            }
                        },
                        stroke: {
                            // xxxxxxxxxx
                            curve: 'straight',
                            colors: ['#FFFFFF'],
                            width: 2,
                        },
                        xaxis: {
                            categories: ['01/05/2023', '02/05/2023', '03/05/2023', '04/05/2023', '05/05/2023',
                                '06/05/2023', '07/05/2023', '08/05/2023', '09/05/2023', '10/05/2023', '11/05/2023',
                                '12/05/2023'
                            ],
                            labels: {
                                show: false,
                                style: {
                                    colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                        '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                    ],
                                },
                            },
                            tooltip: {
                                enabled: false,
                            },
                            axisBorder: {
                                // show: false
                                color: '#475266'
                            },
                            axisTicks: {
                                color: '#fff',
                                height: 5
                            },
                        },
                        yaxis: {
                            show: true,
                            labels: {
                                show: false,
                                style: {
                                    colors: ['#fff'],
                                },
                            },
                        },
                        grid: {
                            show: true,
                            borderColor: '#3E4759',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true,
                                    color: '#3E4759'
                                },
                            },
                            yaxis: {
                                lines: {
                                    show: true,
                                }
                            }
                        },
                        dataLabels: {
                            enabled: false, // tooltip visivel
                            // xxxxxxxxxx
                            offsetY: -7,
                            style: {
                                colors: [
                                    'rgba(0,0,0,.0)',
                                ],
                                fontSize: '11px',
                                borderColor: '#f00'
                            },
                            formatter: function (val) {
                                if (val >= 1000) {
                                    let numeroAbreviado = (val / 1000).toFixed(0);
                                    numeroAbreviado += "K";
                                    return numeroAbreviado;
                                } else {
                                    return val.toFixed(0);
                                }
                            },
                            background: {
                                enabled: true,
                                padding: 0,
                                borderRadius: 2,
                                borderWidth: 1,
                                borderColor: 'transparent',
                                opacity: 0.9,
                            },
                        },
                    },
                    chartSeries: [{
                        name: 'Vendas',
                        data: [3472, 3466, 2234, 1355, 6467, 2653, 3472, 3466, 2234, 1355, 6467, 2653],
                    }],
                }
            }
        },
        props: ['dataGraphics', 'showGraphicsFilters', 'textFilterDays'],
        watch: {
            dataGraphics(data) {
                if (data.mensal) {
                    this.setDataChart50Sales()
                    this.setDataChart24Sales()
                    this.showCharts = true
                }
            }
        },
        methods: {
            setDataChart50Sales() {
                this.chart50Sales.chartOptions.xaxis.categories = this.dataGraphics.mensal.dias
                this.chart50Sales.chartSeries[0].data = this.dataGraphics.mensal.cpf.dados
            },
            setDataChart24Sales() {
                this.chart24Sales.chartOptions.xaxis.categories = this.dataGraphics.mensal.dias
                this.chart24Sales.chartSeries[0].data = this.dataGraphics.mensal.cnpj.dados
            }
        },
    }
</script>
<style scoped>
    .text-muted {
        color: #7A8599 !important
    }
</style>