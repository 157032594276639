import Vue from 'vue'
import App from './App.vue'

/* Config */
import config from './config/config'
Vue.prototype.$config = config

/* VueRouter */
import router from './router/routes'

/* Bootstrap  v5.2.3 */
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

/* Fontawesome */
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

/* Vue Loading Overlay */
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(VueLoading, {
    color: "#1ca761",
    // backgroundColor: '#242933',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    loader: 'spinner',
    width: 50,
    height: 50,
});

/* Vue Meta */
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

/* vue the mask */
import TheMask from 'vue-the-mask'
Vue.use(TheMask)

import money from 'v-money'
Vue.use(money, { precision: 4 })

/* Filters */
Vue.filter('money', function (value) {
    return Number(value).toLocaleString('pt-br', { minimumFractionDigits: 2 });
})

Vue.filter('formatDate', function (value) {
    if (!value) return '???'
    const date = new Date(value)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear().toString().substr(-2)
    return `${day}/${month}/${year}`
})
Vue.filter('formatHour', function (value) {
    if (!value) return '??'
    const date = new Date(value)
    const hour = date.getHours().toString().padStart(2, '0')
    const min = (date.getMinutes()).toString().padStart(2, '0')
    return `${hour}:${min}`
})

Vue.filter('formatCnpj', function (value) {
    if (!value) return ''
    const cnpj = value.toString().padStart(14, '0')
    return `${cnpj.substr(0, 2)}.${cnpj.substr(2, 3)}.${cnpj.substr(5, 3)}/${cnpj.substr(8, 4)}-${cnpj.substr(12, 2)}`
})

Vue.filter('formatarCPF', function (value) {
    if (!value) return "";
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
})



Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
