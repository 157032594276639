// import api from './ApiService';
import apiAuth from './ApiAuthService';

export default {
    login(formData) {
        return apiAuth.post("/login", formData)
    },
    createNewPassword(formData) {
        return apiAuth.post('/user/recuperacao.php', formData)
    },
    forgotPassword(formData) {
        return apiAuth.post('user/recuperacao.php', formData)
    },
    createNewAdmin(formData) {
        return apiAuth.post('/usuario', formData)
    }
}
