<template>
    <div class="app-dashboard">
        <!-- Cabeçalho -->
        <HeaderDashboard />
        <!-- Conteúdo -->
        <div class="">
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
    </div>

</template>
<script>
    import HeaderDashboard from "./HeaderDashboard";
    export default {
        name: 'AppDashboard',
        components: {
            HeaderDashboard,
        }
    }
</script>
<style scoped>
    @import '@/assets/css/dashboard.css';
</style>