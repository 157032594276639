<template>
    <div>
        <div :class="`box-account-data box-account box-account-${index}-mei-${ml} `" :id="`${dataCnpj.id}-ml${ml}`"
            :style="`border-color: ${dataCnpj.cor}`">

            <div class="number-show d-flex align-items-center justify-content-center"
                :style="`border-color: ${dataCnpj.cor}`">
                {{index + 1}}
            </div>
            <div :class="`text-center w-100`" style="position: absolute; top: -50px;">
                <h2 class="text-uppercase h2-account d-inline-block p-1 px-2 h4 rounded-2" :id="`${dataCnpj.id}`">
                    {{dataCnpj.nome == '' ? dataCnpj.cnpj : dataCnpj.nome}}
                </h2>
            </div>

            <!-- deletar cnpj -->
            <div class="delete-cnpj">
                <span data-bs-toggle="modal" :data-bs-target="`#modal-delete-cnpj-${dataCnpj.id}`">
                    <button type="button" class="btn btn-none p-0" data-bs-toggle="tooltip" data-bs-placement="top"
                        title="Deletar">
                        <img src="@/assets/img/icons/x-circle-danger.svg" alt="" width="20">
                    </button>
                </span>
            </div>

            <div class="pt-2"></div>
            <div class="overflow-hidden p-2 pt-0 mt-4" style="border-top: 1px solid #475266;" v-if="showData">
                <!-- Início Entradas -->
                <div class="row g-0">
                    <!-- Gráfico entradas -->
                    <div class="col-9 text-dark  py-0">
                        <div class="d-flex w-100">
                            <div class="w-100" v-if="showChart.chartInputs">
                                <h3 class="h6 text-white px-2 pt-3 mb-0 pb-0">Entradas -
                                    {{ (new Date()).getFullYear() }}</h3>
                                <apexchart width="100%" height="140" type="area"
                                    :options="charts.chartInputs.chartOptions"
                                    :series="charts.chartInputs.chartSeries" />
                            </div>
                            <div class="me-2" style="border-right: 1px solid #475266; width:0px; height: 150px;"></div>
                        </div>
                    </div>
                    <!-- Gráfico total -->
                    <div class="col-3" v-if="showChart.chartAmountInputs">
                        <apexchart type="radialBar" :options="charts.chartAmountInputs.chartOptions"
                            :series="charts.chartAmountInputs.series" width="100%" height="100%" />
                    </div>
                </div>

                <!-- Início saídas -->
                <div class="row g-0 " style="border-top: 1px solid #475266">
                    <!-- Gráfico Saídas -->
                    <div class="col-9 text-dark  py-0">
                        <div class="d-flex w-100">
                            <div class="w-100" v-if="showChart.chartOutputs">
                                <h3 class="h6 text-white px-2 pt-2 mb-0 pb-0">Saídas - {{ (new Date()).getFullYear() }}
                                </h3>
                                <apexchart width="100%" height="140" type="area"
                                    :options="charts.chartOutputs.chartOptions"
                                    :series="charts.chartOutputs.chartSeries" />
                            </div>
                            <div class="me-2" style="border-right: 1px solid #475266; width:0px; height: 150px;"></div>
                        </div>
                    </div>
                    <!-- Gráfico total -->
                    <div class="col-3" v-if="showChart.chartAmountOutputs">
                        <apexchart type="radialBar" :options="charts.chartAmountOutputs.chartOptions"
                            :series="charts.chartAmountOutputs.series" width="100%" height="100%" />
                    </div>
                </div>

                <!-- Adicionar NFs -->
                <div class="row px-2 " style="border: 1px solid #475266">
                    <!-- Add NF Compra -->
                    <div class="col-12 col-lg-6 pb-2 pt-2 " style="border-right: 1px solid #475266">
                        <div class="row gx-2 h-100 ">
                            <div class="col-8 py-3 h-100">
                                <router-link :to="{name: 'home.createnewpurchase'}"
                                    class="btn btn-blue-light text-white p-2 text-center h-100 d-flex">
                                    <div class="fs-5 fw-bold m-auto">
                                        Adicionar NFs Compra
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-4 py-3 h-100">
                                <div
                                    class="rounded-2 text-white bg-dark-light-1 text-center p-2  d-flex align-items-center h-100 ">
                                    <div class="mx-auto">
                                        <div class="fs-11px fw-semibold">{{currentDate() | formatDate}}</div>
                                        <div class="fs-11px">{{currentDate() | formatHour}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Add NF Venda -->
                    <div class="col-12 col-lg-6 pb-2 pt-2">
                        <div class="row gx-2 h-100">
                            <div class="col-8 py-3 h-100">
                                <router-link :to="{name: 'home.generateinvoice'}"
                                    class="btn btn-green text-white p-2 text-center h-100 d-flex ">
                                    <div class="fs-5 fw-bold m-auto">
                                        Adicionar NFs Venda
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-4 py-3 ">
                                <!-- Dropdown Mei -->
                                <div class="dropdown open h-100">
                                    <router-link
                                        :class="`btn ${ml == 2 ? 'btn-danger-custome':'btn-yellow'} h-100 text-dark text-center p-2 h-100 d-flex align-items-center position-relative`"
                                        type="button" :id="`drop-${dataCnpj.id}`"
                                        :to="{ name: 'home.settings', query: { cnpj: dataCnpj.id } }">
                                        <div class="mx-auto overflow-hidden btn-mei">
                                            <img src="@/assets/img/icons/edit-3.svg" alt="" class="icon-edit">
                                            <div class="fs-11px fw-semibold">MEI {{ dataCnpj.id }}</div>
                                            <div class="fs-11px">{{ dataCnpj.cnpj | formatCnpj }}</div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Históricos -->
                <div class="row bg-dark-light-1 py-1">
                    <!-- Histórico Entradas -->
                    <div class="col-12 col-lg-6 px-3" style="border-right: 1px solid #596680">
                        <div class="d-flex gap-1  align-items-center">
                            <div class="fw-semibold fs-12px me-auto">Histórico Entradas</div>
                            <!-- filtrar -->
                            <div class="">
                                <div class="dropdown">
                                    <button type="button"
                                        class="btn btn-outline-light btn-sm rounded-pill text-uppercase  "
                                        style="font-size: 9px !important" id="triggerId" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span class="d-flex align-items-center gap-1">
                                            <img src="@/assets/img/icons/filter.svg" alt="" width="13">
                                            Filtrar
                                        </span>
                                    </button>
                                    <!-- Dropdown -->
                                    <div class="dropdown-menu shadow-lg text-white" aria-labelledby="triggerId"
                                        style="background: #1e222b; border: 1px solid rgba(255,255,255,.15); min-width: 300px;">
                                        <form action="#" method="post">
                                            <div class=" px-3">
                                                <div class="row gx-2">
                                                    <!-- Número -->
                                                    <div class="col-12 col-lg-6">
                                                        <label class="form-label fw-normal mb-0 fs-11px">Número:</label>
                                                        <input type="text"
                                                            class="form-control border-success  form-select-sm"
                                                            name="numero" placeholder="0" required
                                                            style="background-color: #282c36; color: white;"
                                                            v-model="filterHistoryInputsAttributes.number"
                                                            v-mask="`##############`" />
                                                    </div>
                                                    <!-- Data -->
                                                    <div class="col-12 col-lg-6">
                                                        <label
                                                            class="form-label fw-normal mb-0 fs-11px text-start">Data:</label>
                                                        <input type="date"
                                                            class="form-control border-success  form-control-sm"
                                                            name="data" placeholder="dd/mm/YY" required
                                                            style="background-color: #282c36; color: white;"
                                                            v-model="filterHistoryInputsAttributes.date"
                                                            ref="dataFilter" />
                                                    </div>
                                                    <!-- CNPJ -->
                                                    <div class="col-12 col-lg-12">
                                                        <label
                                                            class="form-label fw-normal mb-0 fs-11px">CPF/CNPJ:</label>
                                                        <input type="text"
                                                            class="form-control border-success  form-select-sm"
                                                            name="cnpj" placeholder="00000000000000" required
                                                            style="background-color: #282c36; color: white;"
                                                            v-model="filterHistoryInputsAttributes.cnpj"
                                                            v-mask="'##############'" />
                                                    </div>
                                                    <!-- Valor mínimo -->
                                                    <div class="col-12 col-lg-6">
                                                        <label class="form-label fw-normal mb-0 fs-11px">Valor
                                                            mínimo:</label>
                                                        <money type="text"
                                                            class="form-control border-success  form-select-sm"
                                                            name="valor_minimo" placeholder="0,00" required
                                                            style="background-color: #282c36; color: white;"
                                                            v-model="filterHistoryInputsAttributes.value_min"
                                                            v-bind="money"></money>
                                                    </div>
                                                    <!-- Valor máximo -->
                                                    <div class="col-12 col-lg-6">
                                                        <label class="form-label fw-normal mb-0 fs-11px">Valor
                                                            máximo:</label>
                                                        <money type="text"
                                                            class="form-control border-success  form-select-sm"
                                                            name="valor_minimo" placeholder="0,00" required
                                                            style="background-color: #282c36; color: white;"
                                                            v-model="filterHistoryInputsAttributes.value_max"
                                                            v-bind="money"></money>
                                                    </div>
                                                </div>
                                                <div class="mb-2 mt-3 d-flex">
                                                    <button type="button"
                                                        class="btn btn-blue-light text-white btn-sm rounded-0  w-100 h-100"
                                                        style="font-size: 12px !important" @click="filterHistoryInputs">
                                                        <i class="fa-solid fa-magnifying-glass fa-sm me-1"></i>
                                                        Filtrar
                                                    </button>
                                                    <button type="button"
                                                        class="btn btn-blue-light opacity-75 text-white btn-sm rounded-0 h-100"
                                                        style="font-size: 12px !important" title="Limpar campos"
                                                        @click="resetfilterHistoryInputsAttributes">
                                                        <i class="fa-regular fa-trash-can"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <button type="button" class="btn btn-blue-light btn-sm rounded-3 text-white"
                                    style="font-size: 10px !important" @click="downloadHistoric('entradas')">
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Histórico Saídas -->
                    <div class="col-12 col-lg-6 px-3">
                        <div class="d-flex gap-1  align-items-center">
                            <div class="fw-semibold fs-12px me-auto">Histórico Saídas</div>
                            <!-- filtrar -->
                            <div class="">
                                <div class="dropdown">
                                    <button type="button"
                                        class="btn btn-outline-light btn-sm rounded-pill text-uppercase  "
                                        style="font-size: 9px !important" id="triggerId" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span class="d-flex align-items-center gap-1">
                                            <img src="@/assets/img/icons/filter.svg" alt="" width="13">
                                            Filtrar
                                        </span>
                                    </button>
                                    <!-- Dropdown -->
                                    <div class="dropdown-menu shadow-lg text-white" aria-labelledby="triggerId"
                                        style="background: #1e222b; border: 1px solid rgba(255,255,255,.15); min-width: 300px;">
                                        <form action="#" method="post">
                                            <div class=" px-3">
                                                <div class="row gx-2">
                                                    <!-- Número -->
                                                    <div class="col-12 col-lg-6">
                                                        <label class="form-label fw-normal mb-0 fs-11px">Número:</label>
                                                        <input type="text"
                                                            class="form-control border-success  form-select-sm"
                                                            placeholder="0" required
                                                            style="background-color: #282c36; color: white;"
                                                            v-model="filterHistoryOutputsAttributes.number"
                                                            v-mask="`##############`" />
                                                    </div>
                                                    <!-- Data -->
                                                    <div class="col-12 col-lg-6">
                                                        <label
                                                            class="form-label fw-normal mb-0 fs-11px text-start">Data:</label>
                                                        <input type="date"
                                                            class="form-control border-success  form-control-sm"
                                                            placeholder="dd/mm/YY" required
                                                            style="background-color: #282c36; color: white;"
                                                            v-model="filterHistoryOutputsAttributes.date"
                                                            ref="dataFilter" />
                                                    </div>
                                                    <!-- cpf -->
                                                    <div class="col-12 col-lg-12">
                                                        <label
                                                            class="form-label fw-normal mb-0 fs-11px">CPF/CNPJ:</label>
                                                        <input type="text"
                                                            class="form-control border-success  form-select-sm"
                                                            placeholder="00000000000000" required
                                                            style="background-color: #282c36; color: white;"
                                                            v-model="filterHistoryOutputsAttributes.cpf"
                                                            v-mask="'##############'" />
                                                    </div>
                                                    <!-- Valor mínimo -->
                                                    <div class="col-12 col-lg-6">
                                                        <label class="form-label fw-normal mb-0 fs-11px">Valor
                                                            mínimo:</label>
                                                        <money type="text"
                                                            class="form-control border-success  form-select-sm"
                                                            placeholder="0,00" required
                                                            style="background-color: #282c36; color: white;"
                                                            v-model="filterHistoryOutputsAttributes.value_min"
                                                            v-bind="money"></money>
                                                    </div>
                                                    <!-- Valor máximo -->
                                                    <div class="col-12 col-lg-6">
                                                        <label class="form-label fw-normal mb-0 fs-11px">Valor
                                                            máximo:</label>
                                                        <money type="text"
                                                            class="form-control border-success  form-select-sm"
                                                            placeholder="0,00" required
                                                            style="background-color: #282c36; color: white;"
                                                            v-model="filterHistoryOutputsAttributes.value_max"
                                                            v-bind="money"></money>
                                                    </div>
                                                </div>
                                                <div class="mb-2 mt-3 d-flex">
                                                    <button type="button"
                                                        class="btn btn-green text-white btn-sm rounded-0  w-100 h-100"
                                                        style="font-size: 12px !important"
                                                        @click="filterHistoryOutputs">
                                                        <i class="fa-solid fa-magnifying-glass fa-sm me-1"></i>
                                                        Filtrar
                                                    </button>
                                                    <button type="button"
                                                        class="btn btn-green opacity-75 text-white btn-sm rounded-0 h-100"
                                                        style="font-size: 12px !important" title="Limpar campos"
                                                        @click="resetfilterHistoryOutputsAttributes">
                                                        <i class="fa-regular fa-trash-can"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <button type="button" class="btn btn-green btn-sm rounded-3 text-white"
                                    @click="downloadHistoric('saidas')" style="font-size: 10px !important">
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row ">
                    <!-- Tabela historica de entradas -->
                    <div class="col-12 col-lg-6 px-0 "
                        style="border-right: 1px solid #475266; padding-right: 2px !important">
                        <div class="table-over-scroll">
                            <table class="table table-hover table-custom table-bordered" style="border-color: #353D4D">
                                <thead>
                                    <tr>
                                        <th scope="col pt-1">
                                            <div class="ps-2">N°</div>
                                        </th>
                                        <th scope="col pt-1">Data</th>
                                        <th scope="col pt-1">CPF/CNPJ</th>
                                        <th scope="col pt-1">Valor</th>
                                    </tr>
                                </thead>

                                <tbody v-if="filterHistoryInputsShow" v-html="htmlFilterInputs">
                                </tbody>
                                <!-- Dados totais -->
                                <tbody v-else>
                                    <tr class="">
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- Tabela historica de saídas -->
                    <div class="col-12 col-lg-6 px-0 pe-2" style="">
                        <div class="table-over-scroll">
                            <table class="table table-hover table-custom table-bordered" style="border-color: #353D4D">
                                <thead>
                                    <tr>
                                        <th scope="col pt-1">
                                            <div class="ps-2">N°</div>
                                        </th>
                                        <th scope="col pt-1">Data</th>
                                        <th scope="col pt-1">CPF/CNPJ</th>
                                        <th scope="col pt-1">Valor</th>
                                    </tr>
                                </thead>

                                <tbody v-if="filterHistoryOutputsShow" v-html="htmlFilterOutputs">
                                </tbody>

                                <tbody v-else>
                                    <tr class="">
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Gráficos Limit -->
                <div class="px-3">
                    <div class="row">
                        <!-- Limit entradas -->
                        <div class="col-12 col-lg-6" style="border-right: 1px solid #475266;">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="w-100">
                                    <div class="fw-semibold fs-14px pt-3">
                                        {{ valueLimitInputChartBar }}
                                        <button type="button" @click="editLimiteInputsModal('entradas')"
                                            class="btn btn-none p-0 text-white text-decoration-none ms-2">
                                            <img src="@/assets/img/icons/edit-2.svg" alt="" width="14">
                                            Edit. Limite
                                        </button>
                                    </div>
                                    <div class="d-flex">
                                        <!-- Gráfico Bar -->
                                        <div class="position-relative" v-if="showChart.chartAmountLimitInputs">
                                            <div class="text-bar-value text-uppercase">valor</div>
                                            <apexchart type="bar" height="130" width="40"
                                                :options="charts.chartLimitBarInputs.chartOptions"
                                                :series="charts.chartLimitBarInputs.series">
                                            </apexchart>
                                        </div>
                                        <!-- Gráfico Area -->
                                        <div class="w-100 text-dark" v-if="showChart.chartLimitAreaInputs">
                                            <apexchart width="100%" height="144" type="area"
                                                :options="charts.chartLimitAreaInputs.chartOptions"
                                                :series="charts.chartLimitAreaInputs.chartSeries" />
                                        </div>
                                    </div>
                                    <div class="text-end text-white small text-uppercase" style="margin-top: -30px">
                                        {{currentMonth}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Limit saídas -->
                        <div class="col-12 col-lg-6 pe-0">
                            <div class="d-flex justify-content-start align-items-center ps-1">
                                <div class="w-100">
                                    <div class="fw-semibold fs-14px pt-3">
                                        {{ valueLimitOutputsChartBar }}
                                        <button type="button" @click="editLimiteInputsModal('saidas')"
                                            class="btn btn-none p-0 text-white text-decoration-none ms-2">
                                            <img src="@/assets/img/icons/edit-2.svg" alt="" width="14">
                                            Edit. Limite
                                        </button>
                                    </div>
                                    <div class="d-flex">
                                        <!-- Gráfico Bar -->
                                        <div class="position-relative" v-if="showChart.chartAmountLimitOutputs">
                                            <div class="text-bar-value text-uppercase">valor</div>
                                            <apexchart type="bar" height="130" width="40"
                                                :options="charts.chartLimitBarOutputs.chartOptions"
                                                :series="charts.chartLimitBarOutputs.series">
                                            </apexchart>
                                        </div>
                                        <!-- Gráfico Area -->
                                        <div class="w-100 text-dark" v-if="showChart.chartLimitAreaOutputs">
                                            <apexchart width="100%" height="144" type="area"
                                                :options="charts.chartLimitAreaOutputs.chartOptions"
                                                :series="charts.chartLimitAreaOutputs.chartSeries" />
                                        </div>
                                    </div>
                                    <div class="text-end text-white small text-uppercase" style="margin-top: -30px">
                                        {{currentMonth}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="py-5" v-else>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border text-secondary" style="width: 1rem; height: 1rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>

        <ModalEditLimiteCnpj :data-cnpj="dataCnpj" :id-box="`${dataCnpj.id}-${ml}`" ref="modalEditLimet" />
        <ModalDeleteCnpj :data-cnpj="dataCnpj" />
    </div>
</template>
<script>
    // import axios from 'axios'
    import apexchart from 'vue-apexcharts'
    import ModalEditLimiteCnpj from '@/components/ModalEditLimiteCnpj'
    import ModalDeleteCnpj from '@/components/ModalDeleteCnpj'
    import homeService from "@/services/HomeService";
    import {
        Money
    } from 'v-money'
    export default {
        name: 'PanelMei',
        components: {
            apexchart,
            ModalEditLimiteCnpj,
            ModalDeleteCnpj,
            Money
        },
        data() {
            return {
                filterHistoryInputsShow: false,
                filterHistoryInputsData: {},
                filterHistoryOutputsShow: false,
                filterHistoryOutputsData: {},
                filterHistoryInputsAttributes: {
                    number: 0,
                    date: '',
                    cnpj: '',
                    value_min: 0,
                    value_max: 0
                },
                filterHistoryOutputsAttributes: {
                    number: 0,
                    date: '',
                    cpf: '',
                    value_min: 0,
                    value_max: 0
                },
                htmlFilterInputs: ``,
                htmlFilterOutputs: ``,
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$ ',
                    suffix: '',
                    precision: 2,
                    masked: false
                },
                currentAccountData: {},
                valueLimitInputChartBar: 0,
                valueLimitOutputsChartBar: 0,
                currentMonth: '',
                showChart: {
                    chartInputs: false,
                    chartOutputs: false,
                    chartAmountInputs: false,
                    chartAmountOutputs: false,
                    chartAmountLimitInputs: false,
                    chartAmountLimitOutputs: false,
                    chartLimitAreaInputs: false,
                    chartLimitAreaOutputs: false,
                },
                showData: false,
                dataAccount: {},
                charts: {
                    chartInputs: {
                        chartOptions: {
                            show: true,
                            borderColor: '#fff',
                            chart: {
                                id: 'vuechart-example',
                                curve: 'smooth',
                                toolbar: {
                                    show: false
                                },
                            },
                            tooltip: {
                                marker: {
                                    fillColors: ['#5985EE']
                                },
                                y: {
                                    formatter: function (val) {
                                        if (val >= 1000) {
                                            let numeroAbreviado = (val / 1000).toFixed(0);
                                            numeroAbreviado += "K";
                                            return numeroAbreviado;
                                        } else {
                                            return val.toFixed(0);
                                        }
                                    }
                                }
                            },
                            fill: {
                                colors: ['#5985EE', '#E91E63', '#9C27B0'],
                                opacity: 1,
                                gradient: {
                                    shade: 'dark',
                                    type: "vertical",
                                    shadeIntensity: 0.5,
                                    gradientToColors: undefined,
                                    inverseColors: false,
                                    opacityFrom: 1,
                                    opacityTo: '0.8',
                                    colorStops: []
                                },
                            },
                            markers: {
                                // xxxxxxxxxx line wave
                                size: 2,
                                strokeColors: '#fff',
                                colors: ['#fff'],
                                hover: {
                                    size: 3
                                }
                            },
                            stroke: {
                                // xxxxxxxxxx
                                curve: 'straight',
                                colors: ['#5985EE'],
                                width: 2,
                            },
                            xaxis: {
                                categories: [],
                                labels: {
                                    style: {
                                        colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                            '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                        ],
                                    },
                                },
                                tooltip: {
                                    enabled: false,
                                },
                                axisBorder: {
                                    color: '#475266'
                                },
                                axisTicks: {
                                    color: '#475266',
                                    height: 5
                                },
                            },
                            yaxis: {
                                show: true,
                                labels: {
                                    show: false,
                                    style: {
                                        colors: ['#fff'],
                                    },
                                },
                            },
                            grid: {
                                show: true,
                                borderColor: '#1ca761',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: false,
                                        color: '#1ca761'
                                    },
                                },
                                yaxis: {
                                    lines: {
                                        show: false,
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: true, // tooltip visivel
                                // xxxxxxxxxx
                                offsetY: -5,
                                style: {
                                    colors: [
                                        'rgba(0,0,0,.0)',
                                    ],
                                    fontSize: '10px',
                                    borderColor: '#f00'
                                },
                                formatter: function (val) {
                                    if (val >= 1000) {
                                        let numeroAbreviado = (val / 1000).toFixed(0);
                                        numeroAbreviado += "K";
                                        return numeroAbreviado;
                                    } else {
                                        return val.toFixed(0);
                                    }
                                },
                                background: {
                                    enabled: true,
                                    padding: 0,
                                    borderRadius: 2,
                                    borderWidth: 1,
                                    borderColor: 'transparent',
                                    opacity: 0.9,
                                },
                            },
                        },
                        chartSeries: [{
                            name: 'Entradas',
                            data: [],
                        }],
                    },
                    chartOutputs: {
                        chartOptions: {
                            show: true,
                            borderColor: '#fff',
                            chart: {
                                id: 'vuechart-example',
                                curve: 'smooth',
                                toolbar: {
                                    show: false
                                },
                            },
                            tooltip: {
                                marker: {
                                    fillColors: ['#29CC7A']
                                },
                                y: {
                                    formatter: function (val) {
                                        if (val >= 1000) {
                                            let numeroAbreviado = (val / 1000).toFixed(0);
                                            numeroAbreviado += "K";
                                            return numeroAbreviado;
                                        } else {
                                            return val.toFixed(0);
                                        }
                                    }
                                }
                            },

                            fill: {
                                colors: ['#29CC7A', '#E91E63', '#9C27B0'],
                                opacity: 1,
                                gradient: {
                                    shade: 'dark',
                                    type: "vertical",
                                    shadeIntensity: 0.5,
                                    gradientToColors: undefined,
                                    inverseColors: false,
                                    opacityFrom: 1,
                                    opacityTo: '0.8',
                                    colorStops: []
                                },
                            },
                            markers: {
                                size: 2,
                                strokeColors: '#fff',
                                colors: ['#fff'],
                                hover: {
                                    size: 3,
                                },
                            },
                            stroke: {
                                curve: 'straight',
                                colors: ['#29CC7A'],
                                width: 2,
                            },
                            xaxis: {
                                categories: [],
                                labels: {
                                    style: {
                                        colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                            '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                        ],
                                    },
                                },
                                tooltip: {
                                    enabled: false,
                                },
                                axisBorder: {
                                    // show: false
                                    color: '#475266'
                                },
                                axisTicks: {
                                    color: '#475266',
                                    height: 5
                                },
                            },
                            yaxis: {
                                show: true,
                                labels: {
                                    show: false,
                                    style: {
                                        colors: ['#fff'],
                                    },
                                },
                            },
                            grid: {
                                show: true,
                                borderColor: '#1ca761',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: false,
                                        color: '#1ca761'
                                    },
                                },
                                yaxis: {
                                    lines: {
                                        show: false,
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: true, // tooltip visivel
                                offsetY: -5,
                                style: {
                                    colors: [
                                        'rgba(0,0,0,.0)',
                                    ],
                                    fontSize: '10px',
                                    borderColor: '#f00'
                                },
                                formatter: function (val) {
                                    if (val >= 1000) {
                                        let numeroAbreviado = (val / 1000).toFixed(0);
                                        numeroAbreviado += "K";
                                        return numeroAbreviado;
                                    } else {
                                        return val.toFixed(0);
                                    }
                                },
                                background: {
                                    enabled: true,
                                    padding: 0,
                                    borderRadius: 2,
                                    borderWidth: 1,
                                    borderColor: 'transparent',
                                    opacity: 0.9,
                                },
                            },
                        },
                        chartSeries: [{
                            name: 'Saídas',
                            data: [],
                        }],
                    },
                    chartAmountInputs: {
                        chartOptions: {
                            plotOptions: {
                                radialBar: {
                                    // Define o limite aqui da barra de fundo
                                    // startAngle: 0,
                                    // endAngle: 100,
                                    // range: [0, 1000],
                                    hollow: {
                                        margin: 5,
                                        size: '65%', // mudar largura da borda
                                        borderColor: 'red'
                                    },
                                    track: {
                                        background: '#475266', // cor do resto do preenchimento personalizada
                                    },
                                    dataLabels: {
                                        showOn: 'always',
                                        name: {
                                            offsetY: -5,
                                            show: true,
                                            color: '#fff',
                                            fontSize: '17px',
                                        },
                                        value: {
                                            color: '#fff',
                                            fontSize: '17px',
                                            show: true,
                                            offsetY: -2,
                                            xxx: 2222,
                                            // formatter: function (val, opts) {
                                            formatter: function (val) {
                                                return Number(val).toLocaleString('pt-br', {
                                                    minimumFractionDigits: 2
                                                });

                                            }
                                        },
                                    },
                                },
                            },
                            fill: {
                                colors: ['#5985EE'],
                                background: {
                                    colors: ['#242933'], // cor de preenchimento de fundo personalizada,
                                },
                            },
                            labels: ['R$'],
                        },
                        series: [100], // 75 é a porcentagem
                        chartWidth: '100%',
                        chartHeight: 300,
                    },
                    chartAmountOutputs: {
                        chartOptions: {
                            plotOptions: {
                                radialBar: {
                                    hollow: {
                                        margin: 5,
                                        size: '65%', // mudar largura da borda
                                        borderColor: 'red'
                                    },
                                    track: {
                                        background: '#475266', // cor do resto do preenchimento personalizada
                                    },
                                    dataLabels: {
                                        showOn: 'always',
                                        name: {
                                            offsetY: -5,
                                            show: true,
                                            color: '#fff',
                                            fontSize: '17px',
                                        },
                                        value: {
                                            color: '#fff',
                                            fontSize: '17px',
                                            show: true,
                                            offsetY: -2,
                                            formatter: function (val) {
                                                return Number(val).toLocaleString('pt-br', {
                                                    minimumFractionDigits: 2
                                                });
                                            }
                                        },
                                    },
                                },
                            },

                            fill: {
                                colors: ['#29CC7A'],
                                background: {
                                    colors: ['#242933'], // cor de preenchimento de fundo personalizada,
                                },
                            },
                            labels: ['R$'],
                        },
                        series: [75], // 75 é a porcentagem
                        chartWidth: '100%',
                        chartHeight: 300,
                    },
                    chartLimitAreaInputs: {
                        chartOptions: {
                            show: true,
                            borderColor: '#fff',
                            chart: {
                                id: 'vuechart-example',
                                curve: 'smooth',
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: false
                                },
                            },
                            tooltip: {
                                marker: {
                                    fillColors: ['#5985EE'] // cor do bola tooltipo
                                },
                                y: {
                                    formatter: function (val) {
                                        return val.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        });
                                    }
                                }
                            },
                            fill: {
                                colors: ['#5985EE', '#E91E63', '#9C27B0'], //cor da background
                                opacity: 1,
                                gradient: {
                                    shade: 'dark',
                                    type: "vertical",
                                    shadeIntensity: 0.5,
                                    gradientToColors: undefined,
                                    inverseColors: false,
                                    opacityFrom: 1,
                                    opacityTo: '0.8',
                                    // stops: [0, 50, 100],
                                    colorStops: []
                                },

                            },
                            markers: {
                                hover: {
                                    size: 0
                                }
                            },
                            stroke: {
                                curve: 'smooth',
                                colors: ['#5985EE'],
                                width: 2,
                            },
                            xaxis: {

                                categories: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
                                    '13', '14',
                                    '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26',
                                    '27',
                                    '28', '29', '30',
                                ],

                                labels: {
                                    show: false,
                                    style: {
                                        // cores do texto footer
                                        colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                            '#fff',
                                            '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                            '#fff',
                                            '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                            '#fff',
                                            '#fff', '#fff', '#fff', '#fff',
                                        ],
                                        fontSize: '7px'
                                    },
                                },

                                tooltip: {
                                    enabled: true,
                                },

                                axisBorder: {
                                    show: true,
                                    color: '#3d4759'
                                },
                                axisTicks: {
                                    show: true,
                                    color: '#3d4759'
                                },

                            },
                            yaxis: {
                                show: true,
                                labels: {
                                    show: false,
                                    style: {
                                        colors: ['#fff'],
                                    },
                                },
                            },
                            grid: {
                                padding: {
                                    top: -10,
                                    left: 0,
                                    right: 0,
                                    bottom: 10
                                },
                                show: true,
                                borderColor: '#1ca761',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: false,
                                        color: '#1ca761'
                                    },

                                },
                                yaxis: {
                                    lines: {
                                        show: false,
                                    },
                                }
                            },
                            dataLabels: {
                                enabled: false, // tooltip visivel
                                style: {
                                    colors: [
                                        'rgba(0,0,0,.0)',
                                    ],
                                    fontSize: '10px',
                                    borderColor: '#f00'
                                },
                                formatter: function (val) {
                                    return val + 'K'
                                },

                                background: {
                                    enabled: true,
                                    padding: 0,
                                    borderRadius: 2,
                                    borderWidth: 1,
                                    borderColor: 'transparent',
                                    opacity: 0.9,
                                },
                            },
                        },
                        chartSeries: [{
                            name: 'Entradas',
                            data: [30, 40, 45, 50, 49, 60, 40, 37, 35, 50, 30, 40, 45, 50, 49, 60, 40, 37,
                                35,
                                50, 30, 40, 45, 50, 49
                            ],
                            // color: '#FF0000',// mudar a cor do background
                        }],
                    },
                    chartLimitAreaOutputs: {
                        chartOptions: {
                            show: true,
                            borderColor: '#fff',
                            chart: {
                                id: 'vuechart-example',
                                curve: 'smooth',
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: false
                                },
                            },
                            tooltip: {
                                marker: {
                                    fillColors: ['#29CC7A'] // cor do bola tooltipo
                                },
                                y: {
                                    formatter: function (val) {
                                        return val.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        });
                                    }
                                }
                            },
                            fill: {
                                colors: ['#29CC7A', '#E91E63', '#9C27B0'], //cor da background
                                opacity: 1,
                                gradient: {
                                    shade: 'dark',
                                    type: "vertical",
                                    shadeIntensity: 0.5,
                                    gradientToColors: undefined,
                                    inverseColors: false,
                                    opacityFrom: 1,
                                    opacityTo: '0.8',
                                    colorStops: []
                                },

                            },
                            markers: {
                                hover: {
                                    size: 0
                                }
                            },
                            stroke: {
                                curve: 'smooth',
                                colors: ['#29CC7A'],
                                width: 2,
                            },
                            xaxis: {

                                categories: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
                                    '13', '14',
                                    '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26',
                                    '27',
                                    '28', '29', '30',
                                ],
                                labels: {
                                    show: false,
                                    style: {
                                        // cores do texto footer
                                        colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                            '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                            '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                            '#fff', '#fff', '#fff', '#fff',
                                        ],
                                        fontSize: '7px'
                                    },
                                },

                                tooltip: {
                                    enabled: true,
                                },
                                axisBorder: {
                                    show: true,
                                    color: '#3d4759'
                                },
                                axisTicks: {
                                    show: true,
                                    color: '#3d4759'
                                },
                            },
                            yaxis: {
                                show: true,
                                labels: {
                                    show: false,
                                    style: {
                                        colors: ['#fff'],
                                    },
                                },
                            },
                            grid: {
                                padding: {
                                    top: -10,
                                    left: 0,
                                    right: 0,
                                    bottom: 10
                                },
                                show: true,
                                borderColor: '#1ca761',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: false,
                                        color: '#1ca761'
                                    },
                                },
                                yaxis: {
                                    lines: {
                                        show: false,
                                    },
                                }
                            },
                            dataLabels: {
                                enabled: false, // tooltip visivel
                                style: {
                                    colors: [
                                        'rgba(0,0,0,.0)',
                                    ],
                                    fontSize: '10px',
                                    borderColor: '#f00'
                                },
                                formatter: function (val) {
                                    return val + 'K'
                                },

                                background: {
                                    enabled: true,
                                    padding: 0,
                                    borderRadius: 2,
                                    borderWidth: 1,
                                    borderColor: 'transparent',
                                    opacity: 0.9,
                                },
                            },
                        },
                        chartSeries: [{
                            name: 'Saídas',
                            data: [30, 40, 45, 50, 49, 60, 40, 37, 35, 50, 30, 40, 45, 50, 49, 60, 40, 37,
                                35,
                                50, 30, 40, 45, 50, 49
                            ],
                            // color: '#FF0000',// mudar a cor do background
                        }],
                    },
                    chartLimitBarInputs: {
                        series: [{
                            name: 'Item A',
                            data: []
                        }, {
                            name: 'Item B',
                            data: []
                        }],

                        chartOptions: {
                            chart: {
                                sparkline: {
                                    enabled: true
                                },
                                type: 'bar',
                                height: 200,
                                stacked: true,
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: false
                                },
                            },
                            dataLabels: {
                                enabled: false,
                            },
                            responsive: [{
                                breakpoint: 480,
                                options: {
                                    legend: {
                                        position: 'bottom',
                                        offsetX: -10,
                                        offsetY: 0
                                    }
                                }
                            }],
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    borderRadius: 10,
                                    columnWidth: '100%',
                                    dataLabels: {
                                        colors: ['#000'],
                                        total: {
                                            enabled: false,
                                            style: {
                                                fontSize: '13px',
                                                fontWeight: 900,
                                                color: '#ff0000',
                                            },
                                            formatter: function (val) {
                                                return val + 'K'
                                            },
                                        },
                                    },
                                },
                            },
                            xaxis: {
                                categories: ['Valor'],
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: false,
                                },
                                labels: {
                                    show: false, //exibir texto no rodapé
                                    // formatter: function (val) {
                                    //     return val + "kss";
                                    // }
                                }
                            },
                            yaxis: {
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false,
                                },
                                labels: {
                                    show: false,
                                    formatter: function (val) {
                                        return val + "k";
                                    }
                                }
                            },
                            grid: {
                                show: false,
                            },
                            legend: {
                                position: 'right',
                                offsetY: 40,
                                show: false
                            },
                            fill: {
                                opacity: 1,
                                colors: ['#5985EE', '#596680']
                            },
                            tooltip: {
                                enabled: false,
                            }
                        },
                    },
                    chartLimitBarOutputs: {
                        series: [{
                            name: 'PRODUCT A',
                            data: [433]
                        }, {
                            name: 'PRODUCT B',
                            data: [224]
                        }],
                        chartOptions: {
                            chart: {
                                sparkline: {
                                    enabled: true
                                },
                                type: 'bar',
                                height: 200,
                                stacked: true,
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: false
                                },
                            },
                            dataLabels: {
                                enabled: false,
                            },
                            responsive: [{
                                breakpoint: 480,
                                options: {
                                    legend: {
                                        position: 'bottom',
                                        offsetX: -10,
                                        offsetY: 0
                                    }
                                }
                            }],
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    borderRadius: 10,
                                    columnWidth: '100%',
                                    dataLabels: {
                                        colors: ['#000'],
                                        total: {
                                            enabled: false,
                                            style: {
                                                fontSize: '13px',
                                                fontWeight: 900,
                                                color: '#ff0000',
                                            },
                                            formatter: function (val) {
                                                return val + 'K'
                                            },
                                        },
                                    },
                                },
                            },
                            xaxis: {
                                categories: ['Valor'],
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: false,
                                },
                                labels: {
                                    show: false, //exibir texto no rodapé
                                    // formatter: function (val) {
                                    //     return val + "kss";
                                    // }
                                }
                            },
                            yaxis: {
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false,
                                },
                                labels: {
                                    show: false,
                                    formatter: function (val) {
                                        return val + "k";
                                    }
                                }
                            },
                            grid: {
                                show: false,
                            },
                            legend: {
                                position: 'right',
                                offsetY: 40,
                                show: false
                            },
                            fill: {
                                opacity: 1,
                                colors: ['#29CC7A', '#596680']
                            },
                            tooltip: {
                                enabled: false,
                            }
                        },
                    },
                },
            }
        },
        props: ['dataCnpj', 'index', 'ml'],
        methods: {

            editLimiteInputsModal(type) {
                this.$refs.modalEditLimet.showModal(type)
            },
            currentDate() {
                const date = new Date();
                const hours = date.toLocaleTimeString()
                const dateStr = date.toLocaleDateString().split('/').reverse().join('-')
                const fullDate = `${dateStr} ${hours}`
                return fullDate;
            },
            downloadHistoric(type) {
                let formData = []
                formData = type == 'entradas' ? this.filterHistoryInputsData : this.filterHistoryOutputsData;

                let load = this.$loading.show();
                homeService.getXlsToDownload(formData)
                    .then(res => {
                        const base64File = res.data.file
                        load.hide()
                        homeService.downloadFileXlsHist(base64File, type)
                    }).catch(err => {
                        load.hide()
                        console.log(err);
                    })
            },
            getNameMonth(numberMonth) {
                let names = ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
                return names[numberMonth]
            },
            setDataChartHistoricInputsForMonths(labelMonths, dataInputs) {
                let months = []
                labelMonths.forEach((item, index) => {
                    months[index] = this.getNameMonth(item)
                });

                this.charts.chartInputs.chartOptions.xaxis.categories = months
                this.charts.chartInputs.chartSeries[0].data = dataInputs
                this.showChart.chartInputs = true
            },
            setDataChartHistoricOutputsForMonths(labelMonths, dataOutputs) {
                let months = []
                labelMonths.forEach((item, index) => {
                    months[index] = this.getNameMonth(item)
                });

                this.charts.chartOutputs.chartOptions.xaxis.categories = months
                this.charts.chartOutputs.chartSeries[0].data = dataOutputs
                this.showChart.chartOutputs = true
            },
            setDataAmountInputs(data) {
                let valor = Number(data.totalEntrada);
                let total = Number(data.limiteEntrada);
                if (total == 0)
                    total = 1

                let porcentagem = (valor / total) * 100;

                // porcentagem
                this.charts.chartAmountInputs.series[0] = porcentagem

                // valor
                this.charts.chartAmountInputs.chartOptions.plotOptions.radialBar.dataLabels.value.formatter =
                    function () {
                        return Number(valor).toLocaleString('pt-br', {
                            minimumFractionDigits: 2
                        });
                    }

                this.showChart.chartAmountInputs = true

                // cor dos gráficos em vermelhor se ultrapassar o limite
                if (valor >= total) {
                    this.charts.chartInputs.chartOptions.fill.colors = ['#E54839']
                    this.charts.chartAmountInputs.chartOptions.fill.colors = ['#E54839']
                    this.charts.chartInputs.chartOptions.tooltip.marker.fillColors[0] = ['#E54839']
                    this.charts.chartInputs.chartOptions.stroke.colors = ['#E54839']
                }

            },
            setDataAmountOutputs(data) {
                let valor = data.totalSaida;
                let total = data.limiteSaida;
                if (total == 0)
                    total = 1
                let porcentagem = (valor / total) * 100;

                // porcentagem
                this.charts.chartAmountOutputs.series[0] = porcentagem

                // valor
                this.charts.chartAmountOutputs.chartOptions.plotOptions.radialBar.dataLabels.value.formatter =
                    function () {
                        return Number(valor).toLocaleString('pt-br', {
                            minimumFractionDigits: 2
                        });
                    }

                this.showChart.chartAmountOutputs = true

                // cor dos gráficos em vermelhor se ultrapassar o limite
                if (valor >= total) {
                    this.charts.chartOutputs.chartOptions.fill.colors = ['#E54839']
                    this.charts.chartAmountOutputs.chartOptions.fill.colors = ['#E54839']
                    this.charts.chartOutputs.chartOptions.tooltip.marker.fillColors[0] = ['#E54839']
                    this.charts.chartOutputs.chartOptions.stroke.colors = ['#E54839']
                }
            },
            setChartLimitBarInputs(data) {
                let valor = data.totalEntrada;
                let total = Number(data.limiteEntrada);

                if (total == 0)
                    total = 1
                let porcentagem = (valor / total) * 100;

                this.charts.chartLimitBarInputs.series[0].data = [porcentagem]
                this.charts.chartLimitBarInputs.series[1].data = [100 - porcentagem]

                if (total >= 1000) {
                    let numeroAbreviado = 0
                    numeroAbreviado = (total / 1000).toFixed(1);
                    if (numeroAbreviado.split('.')[1] == '0') {
                        numeroAbreviado = Number(numeroAbreviado).toFixed(0)
                    }
                    this.valueLimitInputChartBar = numeroAbreviado + "K"
                } else {
                    this.valueLimitInputChartBar = "R$ " + total
                }

                if (valor >= total) {
                    this.charts.chartLimitBarInputs.series[0].data = [100]
                    this.charts.chartLimitBarInputs.series[1].data = [0]

                    this.charts.chartLimitBarInputs.chartOptions.fill.colors = ['#E54839']
                    this.charts.chartLimitAreaInputs.chartOptions.fill.colors = ['#E54839']
                    this.charts.chartLimitAreaInputs.chartOptions.tooltip.marker.fillColors[0] = ['#E54839']
                    this.charts.chartLimitAreaInputs.chartOptions.stroke.colors = ['#E54839']
                }
                this.showChart.chartAmountLimitInputs = true
                this.setChartLimitAreaInputs(data)
            },
            setChartLimitBarOutputs(data) {
                let valor = data.totalSaida;
                let total = Number(data.limiteSaida);
                if (total == 0)
                    total = 1
                let porcentagem = (valor / total) * 100;

                this.charts.chartLimitBarOutputs.series[0].data = [porcentagem]
                this.charts.chartLimitBarOutputs.series[1].data = [100 - porcentagem]

                if (total >= 1000) {
                    let numeroAbreviado = 0
                    numeroAbreviado = (total / 1000).toFixed(1);
                    if (numeroAbreviado.split('.')[1] == '0') {
                        numeroAbreviado = Number(numeroAbreviado).toFixed(0)
                    }
                    this.valueLimitOutputsChartBar = numeroAbreviado + "K"
                } else {
                    this.valueLimitOutputsChartBar = "R$ " + total
                }

                if (valor >= total) {

                    this.charts.chartLimitBarOutputs.series[0].data = [100]
                    this.charts.chartLimitBarOutputs.series[1].data = [0]

                    this.charts.chartLimitBarOutputs.chartOptions.fill.colors = ['#E54839']
                    this.charts.chartLimitAreaOutputs.chartOptions.fill.colors = ['#E54839']
                    this.charts.chartLimitAreaOutputs.chartOptions.tooltip.marker.fillColors[0] = ['#E54839']
                    this.charts.chartLimitAreaOutputs.chartOptions.stroke.colors = ['#E54839']
                }

                this.showChart.chartAmountLimitOutputs = true
                this.setChartLimitAreaOutputs(data)
            },
            setChartLimitAreaInputs(data) {
                let numberCurrentMonth = (new Date()).getMonth() + 1
                this.charts.chartLimitAreaInputs.chartOptions.xaxis.categories = data.diario.dias
                this.charts.chartLimitAreaInputs.chartSeries[0].data = data.diario.entradas
                this.currentMonth = this.getNameMonth(numberCurrentMonth)
                this.showChart.chartLimitAreaInputs = true

            },
            setChartLimitAreaOutputs(data) {
                let numberCurrentMonth = (new Date()).getMonth() + 1
                this.charts.chartLimitAreaOutputs.chartOptions.xaxis.categories = data.diario.dias
                this.charts.chartLimitAreaOutputs.chartSeries[0].data = data.diario.saidas
                this.currentMonth = this.getNameMonth(numberCurrentMonth)
                this.showChart.chartLimitAreaOutputs = true
            },
            setDataCharts() {
                this.showData = true

                this.setDataChartHistoricInputsForMonths(this.dataCnpj.meses, this.dataCnpj.entradas);
                this.setDataChartHistoricOutputsForMonths(this.dataCnpj.meses, this.dataCnpj.saidas);
                this.setDataAmountInputs(this.dataCnpj)
                this.setDataAmountOutputs(this.dataCnpj)
                this.setChartLimitBarInputs(this.dataCnpj)
                this.setChartLimitBarOutputs(this.dataCnpj)
            },
            resetfilterHistoryInputsAttributes() {
                this.filterHistoryInputsAttributes.number = 0
                this.filterHistoryInputsAttributes.date = ''
                this.filterHistoryInputsAttributes.cnpj = ''
                this.filterHistoryInputsAttributes.value_min = 0
                this.filterHistoryInputsAttributes.value_max = 0
                this.filterHistoryInputs()
            },
            resetfilterHistoryOutputsAttributes() {
                this.filterHistoryOutputsAttributes.number = 0
                this.filterHistoryOutputsAttributes.date = ''
                this.filterHistoryOutputsAttributes.cpf = ''
                this.filterHistoryOutputsAttributes.value_min = 0
                this.filterHistoryOutputsAttributes.value_max = 0
                this.filterHistoryOutputs()
            },
            filterHistoryInputs() {
                this.filterHistoryInputsShow = true
                let filters = this.filterHistoryInputsAttributes

                this.filterHistoryInputsData = this.dataCnpj.historico.entradas.filter(value => {
                    let number = true;
                    let date = true;
                    let cnpj = true;
                    let value_min = true;
                    let value_max = true;

                    if (filters.number)
                        number = value.numero == filters.number
                    if (filters.cnpj)
                        cnpj = value.cnpj_cpf_emit.indexOf(filters.cnpj) != -1;
                    if (filters.value_min)
                        value_min = Number(value.valor) >= filters.value_min
                    if (filters.value_max)
                        value_max = Number(value.valor) <= filters.value_max
                    if (filters.date)
                        date = value.dt_emissao.indexOf(filters.date) != -1;

                    return number && date && cnpj && value_min && value_max;
                })

                /* Html para inserir na tabela de filtros */
                let htmlFilter = ''
                this.filterHistoryInputsData.forEach((item, index) => {

                    let cnpj_format = this.$options.filters.formatCnpj(item.cnpj_cpf_emit)
                    let cpf_format = this.$options.filters.formatarCPF(item.cnpj_cpf_emit)
                    let item_cpf_cnpj = item.cnpj_cpf_emit.length == 14 ? cnpj_format : cpf_format

                    htmlFilter += `
                        <tr>
                            <td> <div class="ps-2">${item.numero}</div> </td>
                            <td>${this.$options.filters.formatDate(item.dt_emissao)}</td>
                            <td>${item_cpf_cnpj}</td>
                            <td>R$ ${this.$options.filters.money(item.valor)}</td>
                        </tr>`;

                    if (index >= this.filterHistoryInputsData.length - 1) {
                        this.htmlFilterInputs = htmlFilter
                    }
                });

                if (this.filterHistoryInputsData.length == '')
                    this.htmlFilterInputs = ''

            },
            filterHistoryOutputs() {
                this.filterHistoryOutputsShow = true
                let filters = this.filterHistoryOutputsAttributes
                filters.cpf = filters.cpf.replace('.', '').replace('.', '').replace('.', '').replace('-', '')

                this.filterHistoryOutputsData = this.dataCnpj.historico.saidas.filter(value => {
                    let number = true;
                    let date = true;
                    let cpf = true;
                    let value_min = true;
                    let value_max = true;

                    if (filters.number)
                        number = value.numero == filters.number
                    if (filters.cpf)
                        cpf = value.cnpj_cpf_dest.indexOf(filters.cpf) != -1;
                    if (filters.value_min)
                        value_min = Number(value.valor) >= filters.value_min
                    if (filters.value_max)
                        value_max = Number(value.valor) <= filters.value_max
                    if (filters.date)
                        date = value.dt_emissao.indexOf(filters.date) != -1;

                    return number && date && cpf && value_min && value_max;
                })

                /* Html para inserir na tabela de filtros */
                let htmlFilter = ''
                this.filterHistoryOutputsData.forEach((item, index) => {

                    let cnpj_format = this.$options.filters.formatCnpj(item.cnpj_cpf_dest)
                    let cpf_format = this.$options.filters.formatarCPF(item.cnpj_cpf_dest)
                    let item_cpf_cnpj = item.cnpj_cpf_dest.length == 14 ? cnpj_format : cpf_format

                    htmlFilter += `
                        <tr>
                            <td> <div class="ps-2">${item.numero}</div> </td>
                            <td>${this.$options.filters.formatDate(item.dt_emissao)}</td>
                            <td>${item_cpf_cnpj}</td>
                            <td>R$ ${this.$options.filters.money(item.valor)}</td>
                        </tr>`;

                    if (index >= this.filterHistoryOutputsData.length - 1) {
                        this.htmlFilterOutputs = htmlFilter
                    }
                });

                if (this.filterHistoryOutputsData.length == '')
                    this.htmlFilterOutputs = ''

            },
            setDataHistoric() {
                this.filterHistoryInputs()
                this.filterHistoryOutputs()
            }
        },
        mounted() {
            this.setDataCharts();
            this.setDataHistoric();
        },
    }
</script>

<style scoped>
</style>