<template>
    <div class="box-coluna p-3 px-4 pt-2 pb-2">
        <div class="d-flex justify-content-start align-items-center pt-1">
            <div class="w-100">
                <div class="fw-semibold fs-14px pt-3">
                    {{ valueLimitOutputsChartBar }}
                    <button type="button" @click="editLimiteInputsModal('saidas')"
                        class="btn btn-none p-0 text-white text-decoration-none ms-2">
                        <img src="@/assets/img/icons/edit-2.svg" alt="" width="14">
                        Edit. Limite
                    </button>
                </div>
                <div class="d-flex gap-0">
                    <!-- Gráfico Bar -->
                    <div v-if="showChartLimitBarOutputs" class="position-relative" style="margin-right: -13px">
                        <div class="text-bar-value text-uppercase">valor</div>
                        <apexchart type="bar" height="143" width="40" :options="chartLimitBarOutputs.chartOptions"
                            :series="chartLimitBarOutputs.series">
                        </apexchart>
                    </div>
                    <!-- Gráfico Area -->
                    <div class="w-100 text-dark" v-if="showChartLimitAreaOutputs">
                        <apexchart width="100%" height="162" type="area" :options="chartLimitAreaOutputs.chartOptions"
                            :series="chartLimitAreaOutputs.chartSeries" />
                    </div>
                </div>
                <div class="text-end text-white small text-uppercase" style="margin-top: -40px">
                    {{currentMonth}}
                </div>
            </div>
        </div>

        <ModalEditLimiteCnpj :data-cnpj="dataMeiHome" :id-box="`mei-saidas-abc-${idMei}`" :data-cnpj2="dataMeiHome"
            ref="modalEditLimet" />
    </div>
</template>

<script>
    import apexchart from 'vue-apexcharts'
    import ModalEditLimiteCnpj from '@/components/ModalEditLimiteCnpj'
    // import graphicsService from '@/services/GraphicsService'

    export default {
        name: 'ChartsMeiAmount',
        components: {
            apexchart,
            ModalEditLimiteCnpj,
        },
        data() {
            return {
                valueLimitOutputsChartBar: 0,
                currentMonth: '',
                showChartLimitAreaOutputs: false,
                showChartLimitBarOutputs: false,
                chartLimitAreaOutputs: {
                    chartOptions: {
                        show: true,
                        borderColor: '#fff',
                        chart: {
                            id: 'vuechart-example',
                            curve: 'smooth',
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            },
                        },
                        tooltip: {
                            marker: {
                                fillColors: ['#29CC7A'] // cor do bola tooltipo
                            },
                            y: {
                                formatter: function (val) {
                                    return val.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    });
                                }
                            }
                        },
                        fill: {
                            colors: ['#29CC7A', '#E91E63', '#9C27B0'], //cor da background
                            opacity: 1,
                            gradient: {
                                shade: 'dark',
                                type: "vertical",
                                shadeIntensity: 0.5,
                                gradientToColors: undefined,
                                inverseColors: false,
                                opacityFrom: 1,
                                opacityTo: '0.8',
                                // stops: [0, 50, 100],
                                colorStops: []
                            },

                        },
                        markers: {
                            hover: {
                                size: 0
                            }
                        },
                        stroke: {
                            curve: 'smooth',
                            colors: ['#29CC7A'],
                            width: 2,
                        },
                        xaxis: {

                            categories: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
                                '13', '14',
                                '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26',
                                '27',
                                '28', '29', '30',
                            ],

                            labels: {
                                show: false,
                                style: {
                                    // cores do texto footer
                                    colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                        '#fff',
                                        '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                        '#fff',
                                        '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                        '#fff',
                                        '#fff', '#fff', '#fff', '#fff',
                                    ],
                                    fontSize: '7px'
                                },
                            },

                            tooltip: {
                                enabled: true,
                            },

                            axisBorder: {
                                show: true,
                                color: '#3d4759'
                            },
                            axisTicks: {
                                show: true,
                                color: '#3d4759'
                            },

                        },
                        yaxis: {
                            show: true,
                            labels: {
                                show: false,
                                style: {
                                    colors: ['#fff'],
                                },
                            },
                        },
                        grid: {
                            padding: {
                                top: -10,
                                left: 0,
                                right: 0,
                                bottom: 16
                            },
                            show: true,
                            borderColor: '#1ca761',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: false,
                                    color: '#1ca761'
                                },

                            },
                            yaxis: {
                                lines: {
                                    show: false,
                                },
                            }
                        },
                        dataLabels: {
                            enabled: false, // tooltip visivel
                            style: {
                                colors: [
                                    'rgba(0,0,0,.0)',
                                ],
                                fontSize: '10px',
                                borderColor: '#f00'
                            },
                            formatter: function (val) {
                                return val + 'K'
                            },

                            background: {
                                enabled: true,
                                padding: 0,
                                borderRadius: 2,
                                borderWidth: 1,
                                borderColor: 'transparent',
                                opacity: 0.9,
                            },
                        },
                    },
                    chartSeries: [{
                        name: 'Saídas',
                        data: [30, 40, 45, 50, 49, 60, 40, 37, 35, 50, 30, 40, 45, 50, 49, 60, 40, 37,
                            35,
                            50, 30, 40, 45, 50, 49
                        ],
                        // color: '#FF0000',// mudar a cor do background
                    }],
                },
                chartLimitBarOutputs: {
                    series: [{
                        name: 'Item A',
                        data: [23]
                    }, {
                        name: 'Item B',
                        data: [23]
                    }],

                    chartOptions: {
                        chart: {
                            sparkline: {
                                enabled: true
                            },
                            type: 'bar',
                            height: 200,
                            stacked: true,
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                legend: {
                                    position: 'bottom',
                                    offsetX: -10,
                                    offsetY: 0
                                }
                            }
                        }],
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                borderRadius: 10,
                                columnWidth: '100%',
                                dataLabels: {
                                    colors: ['#000'],
                                    total: {
                                        enabled: false,
                                        style: {
                                            fontSize: '13px',
                                            fontWeight: 900,
                                            color: '#ff0000',
                                        },
                                        formatter: function (val) {
                                            return val + 'K'
                                        },
                                    },
                                },
                            },
                        },
                        xaxis: {
                            categories: ['Valor'],
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                            tooltip: {
                                enabled: false,
                            },
                            labels: {
                                show: false, //exibir texto no rodapé
                                // formatter: function (val) {
                                //     return val + "kss";
                                // }
                            }
                        },
                        yaxis: {
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false,
                            },
                            labels: {
                                show: false,
                                formatter: function (val) {
                                    return val + "k";
                                }
                            }
                        },
                        grid: {
                            show: false,
                        },
                        legend: {
                            position: 'right',
                            offsetY: 40,
                            show: false
                        },
                        fill: {
                            opacity: 1,
                            colors: ['#29CC7A', '#596680']
                        },
                        tooltip: {
                            enabled: false,
                        }
                    },
                },
            }
        },
        props: ['dataMeiHome', 'idMei'],
        methods: {
            // corrigir quando na api tiver o 'totalSaida'
            setChartLimitBarOutputs() {
                // let valor = this.dataMeiHome.totalSaida;
                let valor = this.dataMeiHome.saidas.at(-1);
                let total = Number(this.dataMeiHome.limiteSaida);
                let porcentagem = (valor / total) * 100;

                this.chartLimitBarOutputs.series[0].data = [porcentagem]
                this.chartLimitBarOutputs.series[1].data = [100 - porcentagem]

                if (total >= 1000) {
                    let numeroAbreviado = 0
                    numeroAbreviado = (total / 1000).toFixed(1);
                    if (numeroAbreviado.split('.')[1] == '0') {
                        numeroAbreviado = Number(numeroAbreviado).toFixed(0)
                    }
                    this.valueLimitOutputsChartBar = numeroAbreviado + "K"
                } else {
                    this.valueLimitOutputsChartBar = "R$ " + total
                }

                if (valor >= total) {
                    this.chartLimitBarOutputs.series[0].data = [100]
                    this.chartLimitBarOutputs.series[1].data = [0]

                    this.chartLimitBarOutputs.chartOptions.fill.colors = ['#E54839']
                    this.chartLimitAreaOutputs.chartOptions.fill.colors = ['#E54839']
                    this.chartLimitAreaOutputs.chartOptions.tooltip.marker.fillColors[0] = ['#E54839']
                    this.chartLimitAreaOutputs.chartOptions.stroke.colors = ['#E54839']
                }
                this.showChartLimitBarOutputs = true
                this.setChartLimitAreaOutputs()
            },
            setChartLimitAreaOutputs() {
                this.chartLimitAreaOutputs.chartOptions.xaxis.categories = this.dataMeiHome.diario.dias
                this.chartLimitAreaOutputs.chartSeries[0].data = this.dataMeiHome.diario.saidas
                let numberCurrentMonth = (new Date()).getMonth() + 1
                this.currentMonth = this.getNameMonth(numberCurrentMonth)
                this.showChartLimitAreaOutputs = true
            },
            getNameMonth(numberMonth) {
                let names = ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
                return names[numberMonth]
            },
            editLimiteInputsModal(type) {
                this.$refs.modalEditLimet.showModal(type)
            },
        },
        mounted() {
            this.setChartLimitBarOutputs()
        },
    }
</script>
<style lang="">

</style>