<template lang="">

    <div>
        <!-- Modal Body -->
        <div class="modal fade" id="modal-generate-invaice-data" ref="modal" tabindex="-1" data-bs-backdrop="static"
            data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-body px-4 py-4">

                        <button type="button" class="btn btn-none btn-x-close" data-bs-dismiss="modal"
                            aria-label="Close">
                            <img src="@/assets/img/icons/x.svg" alt="" width="20">
                        </button>

                        <!-- novo -->
                        <div class="mt-3 pt-3 text-center pb-3" v-if="dataSuccess.zip">
                            <div class="text-center fw-500 mb-3 fs-18px">
                                NF Gerada com sucesso
                            </div>
                            <div class="">
                                <button type="button" class="btn btn-green  btn-sm px-4 fs-14px" @click="downloadZip()">
                                    <div class="px-3">
                                        <i class="fa-solid fa-download fa-sm"></i>
                                        Download
                                    </div>
                                </button>
                            </div>
                        </div>

                        <!-- Conteúdo ignorado -->
                        <!-- conteudo -->
                        <div class="" v-if="dataSuccess.message">
                            <div class="text-center fs-14px pt-4 mt-2">
                                <div class="fw-bold fs-15px">{{dataSuccess.message}}</div>
                            </div>
                            <div class="mt-3 pt-2 form-check-custom">

                                <div class="row">
                                    <!-- Planilha -->
                                    <div class="col-12 col-lg-6">
                                        <div class="fw-bold mb-1 fs-16px">Planilha</div>
                                        <div class="">Inseridos: {{dataSuccess.planilha.inseridos}}</div>
                                        <div class="">Erros: {{dataSuccess.planilha.erros}}</div>
                                    </div>
                                    <!-- Notas -->
                                    <div class="col-12 col-lg-6">
                                        <div class="fw-bold mb-1 fs-16px">Notas</div>
                                        <div class="">Atualizados: {{dataSuccess.notas.atualizados}}</div>
                                        <div class="">Erros: {{dataSuccess.notas.erros}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3 pt-3 text-center">
                                <div class="">
                                    <button type="button" class="btn btn-green  btn-sm px-4 fs-14px"
                                        @click="downloadZip()">
                                        <div class="px-3">
                                            <i class="fa-solid fa-download fa-sm"></i>
                                            Download
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        Modal
    } from "bootstrap";
    import JSZip from 'jszip';
    import {
        saveAs
    } from 'file-saver'

    export default {
        name: 'ModalGenerateInvoiceData',
        data() {
            return {
                datasssAbc: {
                    message: 'Registros processados com sucesso.',
                    planilha: {
                        inseridos: 12,
                        erros: 5
                    },
                    notas: {
                        atualizados: 20,
                        erros: 5,
                        notas_atualizadas_base64: {
                            nome: 'Lorem ipsum',
                            base64: ""
                        }
                    }
                }
            }
        },
        props: ['dataSuccess'],
        methods: {
            showModal() {
                const modal = new Modal(document.getElementById('modal-generate-invaice-data'))
                modal.show()
            },
            downloadZip() {

                // let fileName = this.dataSuccess.notas.notas_atualizadas_base64[0].nome
                let base64String = this.dataSuccess.zip
                let fileName = 'notas_' + ((new Date).toLocaleString())
                    .replace(/\//gi, '-').replace(',', '').replace(/:/gi, '.') + '.zip'

                // Converte a string base64 em um ArrayBuffer
                const base64ToArrayBuffer = base64 => {
                    const binaryString = window.atob(base64)
                    const len = binaryString.length
                    const bytes = new Uint8Array(len)
                    for (let i = 0; i < len; i++) {
                        bytes[i] = binaryString.charCodeAt(i)
                    }
                    return bytes.buffer
                }

                // Cria o arquivo ZIP e faz o download
                const uint8Array = new Uint8Array(base64ToArrayBuffer(base64String))
                const zip = new JSZip()
                zip.loadAsync(uint8Array).then(() => {
                    zip.generateAsync({
                        type: 'blob'
                    }).then(blob => {
                        saveAs(blob, fileName)
                    })
                })

            },
        },
    }
</script>
<style scoped>
    .modal .modal-dialog .modal-content {
        background-color: #363d4c;
        border-color: #475266;
        color: white
    }

    .btn-x-close {
        position: absolute;
        top: 8px;
        right: 0px
    }

    @media(min-width: 992px) {
        .modal-dialog {
            min-width: 350px;
        }
    }

    /* checkbox custom */
    .form-check-custom .form-check-input {
        background-color: #475266;
        border-color: #475266;
        border-radius: 0;
    }

    .form-check-custom .form-check-input:checked {
        background-color: #1ca761;
        border-color: #1ca761;
    }

    .form-check-custom .form-check-input:focus {
        border-color: #1ca761;
        box-shadow: none
    }

    .btn-cancelar {
        background: #475266;
        border-color: #475266
    }

    .btn-cancelar:hover {
        background: #54627c;
        border-color: #54627c
    }
</style>