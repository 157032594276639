import axios from 'axios';
import appConfig from '@/config/config';

// axios.defaults.baseURL = 'https://mapapun.com/Zinzer/api_dev';
axios.defaults.baseURL = appConfig.apiUrl;
const instance = axios.create();

instance.interceptors.request.use(
    (config) => {
        if (localStorage.getItem('token')) {
            const token = localStorage.getItem('token')
            config.headers.Authorization = `${token}`
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

instance.interceptors.response.use(
    (rs) => {
        return rs;
    },
    (err) => {
        // recarregar página se o token estiver expirado
        if (err.response.status == 401) {
            if (err.response.data.message == 'Token não encontrado!') {
                window.location.reload()
            }
        }
        if (err.response.status == 400) {
            // window.location.reload()
        }
        if (err.response.status == 500) {
            // window.location.reload()
        }

        return Promise.reject(err);
    }
);

export default instance;