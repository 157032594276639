<template>
    <div class="box-coluna py-1">

        <div class="d-flex  py-4 px-2" v-if="dataGraphics.hoje">
            <!-- Faturamento -->
            <div class="w-100 d-flex gap-3 divisor-right px-3 align-items-center">

                <!-- Porcentagem -->
                <div v-if="dataGraphics.hoje.mudancaF < 1"
                    class="info-danger text-center d-flex align-items-center justify-content-center flex-column ">
                    <div class="d-flex gap-0 fw-semibold">
                        {{dataGraphics.hoje.mudancaF}}% <img src="@/assets/img/icons/arrow-down-right-danger.svg" alt=""
                            width="18">
                    </div>
                    <div class="fs-11px">últimas 24h</div>
                </div>
                <div class="info-success text-center d-flex align-items-center justify-content-center flex-column "
                    v-else>
                    <div class="d-flex gap-0 fw-semibold">
                        {{dataGraphics.hoje.mudancaF}}% <img src="@/assets/img/icons/arrow-up-right-success.svg" alt=""
                            width="18">
                    </div>
                    <div class="fs-11px">últimas 24h</div>
                </div>
                <div class="">
                    <h2 class="fs-5">Faturamento hoje</h2>
                    <span class="h2 fw-bold">R$ {{dataGraphics.hoje.faturamento | money}}</span>
                </div>
            </div>

            <!-- Vendas -->
            <div class="w-100 d-flex gap-3 px-3 align-items-center ps-4">
                <div v-if="dataGraphics.hoje.mudancaV < 1"
                    class="info-danger text-center d-flex align-items-center justify-content-center flex-column ">
                    <div class="d-flex gap-0 fw-semibold">
                        {{dataGraphics.hoje.mudancaV}}% <img src="@/assets/img/icons/arrow-down-right-danger.svg" alt=""
                            width="18">
                    </div>
                    <div class="fs-11px">últimas 24h</div>
                </div>
                <div class="info-success text-center d-flex align-items-center justify-content-center flex-column "
                    v-else>
                    <div class="d-flex gap-0 fw-semibold">
                        {{dataGraphics.hoje.mudancaV}}% <img src="@/assets/img/icons/arrow-up-right-success.svg" alt=""
                            width="18">
                    </div>
                    <div class="fs-11px">últimas 24h</div>
                </div>


                <div class="">
                    <h2 class="fs-5">Vendas hoje</h2>
                    <span class="h2 fw-bold">{{dataGraphics.hoje.vendas}}</span>
                </div>
            </div>
        </div>

        <div class="py-5" v-else>
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-secondary" style="width: 1rem; height: 1rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'BillingAndSales',
        props: ['dataGraphics'],
    }
</script>
<style lang="">

</style>