<template>
    <div class="box-coluna overflow-hidden">
        <div class="" v-if="showLIst">
            <div class="p-3">
                <div class="d-flex flex-column flex-lg-row justify-content-lg-between align-items-center ">
                    <h2 class="h5 fs-5">
                        Top 10 produtos mais vendidos
                    </h2>
                    <div class="">
                        <div class="dropdown">
                            <button
                                :class="`btn btn-dark-zinzer text-white rounded-2   shadow  d-flex align-items-center gap-1 ${products.length == 0 ? 'disabled' : ''}`"
                                type="button" id="drop-mei-123" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">

                                <span v-if="top10.period == '30d'"> Últimos 30 dias </span>
                                <span v-else> Últimos 12 meses </span>
                                <img src="@/assets/img/icons/chevron-down-white.svg" alt="" width="20" class="">
                            </button>
                            <div class="dropdown-menu" aria-labelledby="drop-mei-123" style="">
                                <a :class="`dropdown-item dropdown-item-menu ${top10.period == '30d' ? 'active' : ''}`"
                                    type="button" @click="serPeriodTop10('30d')">
                                    Últimos 30 dias
                                </a>
                                <a :class="`dropdown-item dropdown-item-menu ${top10.period == '12m' ? 'active' : ''}`"
                                    type="button" @click="serPeriodTop10('12m')">
                                    Últimos 12 meses
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Lista -->
            <div class="py-3 position-relative ">
                <div class="text-center py-4 px-3" v-if="products.length == 0">
                    <i class="fa-solid fa-triangle-exclamation fa-sm"></i>
                    SEM REGISTROS
                </div>
                <div class="" v-else>
                    <div class=" top-height px-3">
                        <ul class="list-group list-group-flush ul-lista-top-10 ">
                            <li class="list-group-item lista-top-10 d-flex align-items-center gap-2"
                                v-for="(item, index) in products" :key="index">

                                <div class="d-flex align-items-center gap-2">
                                    <span
                                        :class="`numero-top-10 d-flex align-items-center justify-content-center fw-semibold rounded-2 ${index == 0 ? 'top-1': ''} ${index == 1 ? 'top-2': ''} ${index == 2 ? 'top-3': ''}`">
                                        {{ (index + 1).toString().padStart(2, '0') }}
                                    </span>
                                    {{item.product}}
                                </div>

                                <div class="d-flex ms-auto  gap-2">
                                    <!-- unidade -->
                                    <span
                                        class="p-1 top-uni d-flex align-items-center justify-content-center fw-semibold rounded-2">
                                        {{ item.amount }}un
                                    </span>
                                    <!-- valor -->
                                    <span
                                        class="p-1 top-valor d-flex align-items-center justify-content-center fw-semibold rounded-2">
                                        R$ {{item.value | money}}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="sombra-rodape-lista-top10"></div>
                </div>
            </div>
        </div>
        <!-- Load -->
        <div class="py-5" v-else>
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-secondary" style="width: 1rem; height: 1rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Top10Product',
        data() {
            return {
                showLIst: false,
                top10: {
                    period: '30d',
                },
                products: []
            }
        },
        props: ['dataGraphics'],
        watch: {
            dataGraphics(data) {
                if (data.top) {
                    this.setDataProducts()
                    this.showLIst = true
                }
            }
        },
        methods: {
            setDataProducts() {
                let products = []
                let period = this.top10.period
                if (this.dataGraphics.top[period].produtos)
                    for (let i in this.dataGraphics.top[period].produtos) {
                        let product = this.dataGraphics.top[period].produtos[i]

                        let amount = 0
                        if (this.dataGraphics.top[period].quantidades[i]) {
                            amount = this.dataGraphics.top[period].quantidades[i]
                        }
                        let value = 0
                        if (this.dataGraphics.top[period].valores[i]) {
                            value = this.dataGraphics.top[period].valores[i]
                        }

                        products.push({
                            product: product,
                            amount: amount,
                            value: Number(value),
                        })
                    }

                this.products = products
            },
            serPeriodTop10(period) {
                this.showLIst = false
                this.top10.period = period
                this.setDataProducts()

                setTimeout(() => {
                    this.showLIst = true

                }, 300);
            }
        },
    }
</script>
<style scoped>
</style>