<template>
    <div>
        <div class="container-dashboard">
            <div class="container-fluid pb-5">
                <div class="row justify-content-center align-items-center">
                    <div>
                        <form method="post" action="#" @submit.prevent="createPurchase">
                            <div class="container text-center">
                                <div class="container-sm ">
                                    <div class="row mt-6 justify-content-center align-items-center">
                                        <div class="input-group text-start justify-content-center align-items-center">
                                            <!--Qual CNPJ está comprando?-->
                                            <div
                                                class="mt-5 input-group text-start justify-content-center align-items-center">
                                                <!--Qual o CNPJ do vendedor?-->
                                                <div class="col-md-6">
                                                    <label for="email" class="form-label mb-1">Qual CNPJ está
                                                        comprando?</label>
                                                    <select
                                                        class="form-select-custom form-select form-select-lg form-custom-add-compra"
                                                        v-model="formData.mei" required>
                                                        <option v-for="(item, index) in accounts" :key="index"
                                                            :value="item.id">
                                                            <span class="" v-if="item.id > 2">MEI</span>
                                                            <span class="" v-else>LTDA</span>
                                                            {{item.id}}: {{item.cnpj}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="mt-5 input-group text-start justify-content-center align-items-center">
                                            <!--Qual o CNPJ do vendedor?-->
                                            <div class="col-md-6">
                                                <label for="email" class="form-label mb-1">Qual o CNPJ do
                                                    vendedor?</label>
                                                <input class="form-control   form-select-lg form-custom-add-compra"
                                                    name="cnpj" id="cnpj" placeholder="00.000.000/0001-00" required
                                                    v-model="formData.cnpj" v-mask="'##.###.###/####-##'" />
                                            </div>
                                        </div>

                                        <div class="input-group mb-3 m-1 mt-5 gap-3">
                                            <div class="m-1 col-md-4">
                                                <label for="email" class="form-label mb-1 w-100 text-start">Descrição do
                                                    produto
                                                    /
                                                    serviço</label>
                                                <input type="text" class="form-control  form-custom-add-compra"
                                                    placeholder="Descrição do produto / serviço"
                                                    aria-label="Descrição do produto / serviço"
                                                    v-model="formData.descricao" required>
                                            </div>
                                            <div class="m-1 col-md-2">
                                                <label for="email"
                                                    class="form-label mb-1 w-100 text-start">NCM/SH</label>
                                                <input type="text" class="form-control  form-custom-add-compra"
                                                    placeholder="12345678" aria-label="12345678" v-model="formData.ncm"
                                                    v-mask="`####################`" required>
                                            </div>
                                            <div class="m-1 col-md-2">
                                                <label for="email"
                                                    class="form-label mb-1 w-100 text-start">CSOSN</label>
                                                <input type="text" class="form-control  form-custom-add-compra"
                                                    placeholder="1234" aria-label="1234" v-model="formData.csosn"
                                                    v-mask="`####################`" required>
                                            </div>
                                            <div class="m-1 col-md-1">
                                                <label for="email" class="form-label mb-1 w-100 text-start">CFOP</label>
                                                <input type="text" class="form-control  form-custom-add-compra"
                                                    placeholder="1234" aria-label="1234" v-model="formData.cfop"
                                                    v-mask="`####################`" required>
                                            </div>
                                            <div class="m-1 col-md-1">
                                                <label for="email" class="form-label mb-1 w-100 text-start">UN</label>
                                                <input type="text" class="form-control  form-custom-add-compra"
                                                    placeholder="UN" aria-label="UN" v-model="formData.un" required>
                                            </div>
                                        </div>


                                        <div class="input-group mb-3 m-1 gap-3">
                                            <div class="m-1 col-md-2">
                                                <label for="email"
                                                    class="form-label mb-1 w-100 text-start">Quantidade</label>
                                                <input type="text" class="form-control  form-custom-add-compra"
                                                    placeholder="1000" aria-label="1000" v-model="formData.quantidade"
                                                    v-mask="`####################`" required>
                                            </div>
                                            <div class="m-1 col-md-2">
                                                <label for="email" class="form-label mb-1 w-100 text-start">
                                                    Valor unitário
                                                </label>
                                                <money class="form-control  form-custom-add-compra"
                                                    placeholder="R$ 0,00" v-model="formData.unitario" v-bind="money"
                                                    required></money>
                                            </div>
                                            <div class="m-1 col-md-2">
                                                <label for="email" class="form-label mb-1 w-100 text-start">
                                                    Valor desconto
                                                </label>
                                                <money class="form-control  form-custom-add-compra"
                                                    placeholder="R$ 0,00" v-model="formData.valDesconto" v-bind="money"
                                                    required></money>
                                            </div>
                                            <div class="m-1 col-md-1">
                                                <label for="email" class="form-label mb-1 w-100 text-start">
                                                    Valor líquido
                                                </label>
                                                <money class="form-control  form-custom-add-compra"
                                                    placeholder="R$ 0,00" v-model="formData.valLiquido" v-bind="money"
                                                    required></money>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="input-group mb-3 m-1 gap-3">
                                        <div class="m-1 col-md-2">
                                            <label for="email" class="form-label mb-1 w-100 text-start">Base Cálc.
                                                ICMS</label>
                                            <money class="form-control  form-custom-add-compra" placeholder="R$ 0,00"
                                                v-model="formData.baseICMS" v-bind="money" required>
                                            </money>
                                        </div>
                                        <div class="m-1 col-md-2">
                                            <label for="email" class="form-label mb-1 w-100 text-start">ICMS</label>
                                            <money class="form-control  form-custom-add-compra" placeholder="R$ 0,00"
                                                v-model="formData.icms" v-bind="money" required>
                                            </money>
                                        </div>
                                        <div class="m-1 col-md-1">
                                            <label for="email" class="form-label mb-1 w-100 text-start">
                                                Valor IPI
                                            </label>
                                            <money class="form-control  form-custom-add-compra" placeholder="R$ 0,00"
                                                v-model="formData.ipi" v-bind="money" required>
                                            </money>
                                        </div>
                                        <div class="m-1 col-md-2">
                                            <label for="email" class="form-label mb-1 w-100 text-start">
                                                Alíquotas ICMS
                                            </label>
                                            <money class="form-control  form-custom-add-compra" placeholder="R$ 0,00"
                                                v-model="formData.aliqIcms" v-bind="money" required></money>
                                        </div>
                                        <div class="m-1 col-md-1">
                                            <label for="email" class="form-label mb-1 w-100 text-start">
                                                Alíquotas IPI
                                            </label>
                                            <money type="text" class="form-control  form-custom-add-compra"
                                                placeholder="R$ 0,00" v-bind="money" v-model="formData.aliqIpi"
                                                required></money>
                                        </div>
                                    </div>
                                    <div
                                        class="mt-5 mb-3 input-group text-start justify-content-center align-items-center">
                                        <!--Valor da compra (NF de entrada)-->
                                        <div class="col-md-6">
                                            <label for="valor" class="form-label mb-1 w-100 text-start">
                                                Valor da compra (NF de entrada)
                                            </label>
                                            <money class="form-control  form-select-lg form-custom-add-compra"
                                                name="valor" id="valor" placeholder="R$ 0,00" v-model="formData.valor"
                                                v-bind="money" required />
                                        </div>
                                    </div>
                                </div>
                                <!--Gerar nota fiscal-->
                                <button type="submit" class="mt-4  btn btn-green btn-lg btn-primary w-50">
                                    Adicionar compra </button>
                                <div class="mt-3">
                                    <p class="card-text mt-2 fw-normal .text-light" style="color: #6C7380;">Você pode
                                        Você pode encontrar as informações detalhadas no histórico.
                                    </p>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        Money
    } from 'v-money'
    import createNewPurchaseService from '@/services/CreateNewPurchaseService';
    import cnpjService from '@/services/CnpjService';
    import alertService from '@/services/AlertService';

    export default {
        name: "GenerateInvoice",
        data() {
            return {
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$ ',
                    suffix: '',
                    precision: 2,
                    masked: false
                },
                formData: {
                    cnpj: '',
                    valor: '',
                    mei: '',
                    descricao: "",
                    ncm: "",
                    csosn: "",
                    cfop: "",
                    un: "",
                    quantidade: "",
                    unitario: "",
                    valDesconto: "",
                    valLiquido: "",
                    baseICMS: "",
                    icms: "",
                    ipi: "",
                    aliqIcms: "",
                    aliqIpi: ""
                },
                accounts: {},
            };
        },
        components: {
            Money
        },
        methods: {
            async getAccounts() {
                let load = this.$loading.show();
                await cnpjService.getAllCnpj()
                    .then(res => {
                        this.accounts = res.data.cnpjs
                        load.hide()
                    }).catch(err => {
                        console.log(err.response);
                        load.hide()
                    })
            },
            createPurchase() {

                let bool = createNewPurchaseService.validate(this.formData)
                if (bool) return;

                let load = this.$loading.show();
                createNewPurchaseService.createPurchase(this.formData)
                    // eslint-disable-next-line
                    .then(res => {
                        load.hide()
                        alertService.success('Cadastrado com sucesso')
                    }).catch(err => {
                        load.hide()
                        if (err.response.data.message)
                            alertService.error(err.response.data.message)
                        else
                            alertService.error(`Ouve um erro na solicitação. ${err}`)
                        console.log(err);
                    })
            }
        },
        mounted() {
            this.getAccounts()
        },
        metaInfo() {
            return {
                title: `Adicionar Compra - ${this.$config.appName}`
            }
        },
    };
</script>
<style scoped>
</style>