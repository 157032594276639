<template>
    <div class="box-coluna">
        <div class="p-3" v-if="showChart">
            <h2 class="fs-5 divisor-bottom pb-2">
                Impostos a pagar LTDA 1
                <!-- - 12,22% -->
            </h2>

            <!-- gráfico -->
            <div class="d-flex justify-content-center py-4">
                <div class="div-impostos d-flex gap-3 align-items-center justify-content-center">

                    <div class="py-4 ps-4">
                        <div class="ps-2">
                            <div class="text-muted fs-12px">Faturamento real</div>
                            <div class="fs-5">R$ {{dataGraphics.imposto.ml1.real | money}}</div>
                            <div class="text-muted fs-12px mt-3">Faturamento editado</div>
                            <div class="fs-5">R$ {{dataGraphics.imposto.ml1.editado | money}}</div>
                        </div>
                    </div>
                    <div class="position-relative" style="margin-right: -40px; margin-bottom: -50px;margin-top: -50px">
                        <div class="imposto-text-dia">DIA</div>
                        <apexchart type="radialBar" :options="chartAmount.chartOptions" :series="chartAmount.series"
                            width="270" height="270" />
                    </div>

                </div>
            </div>
        </div>
        <!-- Load -->
        <div class="py-5" v-else>
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-secondary" style="width: 1rem; height: 1rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import apexchart from 'vue-apexcharts'
    export default {
        name: 'TaxesPayLtda1',
        components: {
            apexchart
        },
        data() {
            return {
                showChart: false,
                chartAmount: {
                    chartOptions: {
                        plotOptions: {
                            radialBar: {
                                hollow: {
                                    margin: 5,
                                    size: '65%', // mudar largura da borda
                                    borderColor: 'red'
                                },
                                track: {
                                    background: '#475266', // cor do resto do preenchimento personalizada
                                },
                                dataLabels: {
                                    showOn: 'always',
                                    name: {
                                        offsetY: -5,
                                        show: true,
                                        color: '#7A8599',
                                        fontSize: '14px',
                                        fontWeight: 'normal'
                                    },
                                    value: {
                                        color: '#fff',
                                        fontSize: '17px',
                                        fontWeight: 600,
                                        show: true,
                                        offsetY: -2,
                                        formatter: function (val) {
                                            console.log(val);
                                            return 'R$ 1.000,99'
                                            // return Number(val).toLocaleString('pt-br', {
                                            //     style: 'currency',
                                            //     currency: 'BRL'
                                            // });
                                        }
                                    },
                                },
                            },
                        },
                        fill: {
                            colors: ['#5985EE'],
                            background: {
                                colors: ['#242933'], // cor de preenchimento de fundo personalizada,
                            },
                        },
                        labels: ['Impostos a pagar'],
                    },
                    series: [70], // 75 é a porcentagem
                    chartWidth: '100%',
                    chartHeight: 400,
                }
            }
        },
        props: ['dataGraphics'],
        watch: {
            dataGraphics(data) {
                if (data.imposto) {
                    this.setDataChartAmount()
                }
            }
        },
        methods: {
            setDataChartAmount() {
                const impostoLtda1 = this.dataGraphics.imposto.ml1

                if (impostoLtda1.imposto == null)
                    impostoLtda1.imposto = 0

                // const data = this.dataGraphics
                let valor = Number(Number(impostoLtda1.imposto).toFixed(2))
                let total = Number(Number(impostoLtda1.mensal).toFixed(2));
                let porcentagem = (valor / total) * 100;

                if (isNaN(porcentagem)) {
                    porcentagem = 0
                }

                // porcentagem
                this.chartAmount.series[0] = 70
                if (porcentagem == 0)
                    this.chartAmount.series[0] = 0

                // valor
                this.chartAmount.chartOptions.plotOptions.radialBar.dataLabels.value.formatter =
                    function () {
                        return Number(valor).toLocaleString('pt-br', {
                            minimumFractionDigits: 2
                        });
                    }

                this.showChart = true
            }
        },
    }
</script>
<style scoped>
    .text-muted {
        color: #7A8599 !important
    }
</style>