<template lang="">
    <div>
        <div class="container-fluid  text-center min-vh-100 bg-dark">
            <div class="row min-vh-100">
                <div class="col-12 m-auto py-5">
                    <h1 class="fw-semibold h2">Erro 404 - Página não encontrada</h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'PageNotFound',
        metaInfo: {
            titleTemplate: 'Erro 404 - Página não encontrada'
        },
    }
</script>
<style scopde>
h1 {
    color: rgb(104, 113, 144)
}
</style>