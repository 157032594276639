import Vue from 'vue'
import VueRouter from 'vue-router'
import verifyToken from '@/services/VerifyToken'

Vue.use(VueRouter)

/* Views */
import AppDashboard from '@/views/Layouts/Dashboard/AppDashboard'
import HomeDashboard from '@/views/Dashboard/HomeDashboard'
import GenerateInvoice from '@/views/Dashboard/GenerateInvoice'
import CreateNewPurchase from '@/views/Dashboard/CreateNewPurchase'
import HistoricNfe from '@/views/Dashboard/HistoricNfe'
import Settings from '@/views/Dashboard/SettingsValue'
import LoginUser from '@/views/Auth/LoginUser'
import ForgotPassword from '@/views/Auth/ForgotPassword'
import CreateNewPassword from '@/views/Auth/CreateNewPassword'
import CreateNewAministrator from '@/views/Auth/CreateNewAdministrator'
import PageNotFound from '@/views/PageNotFound'
import GraphicsPage from '@/views/Dashboard/GraphicsPage'
import UserProfile from '@/views/Dashboard/UserProfile'

const routes = [
    {
        path: '/',
        redirect: {
            name: 'login'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginUser,
        beforeEnter: redirectLoggedIn
    },
    {
        path: '/recuperar-senha',
        name: 'forgotpassword',
        component: ForgotPassword,
        beforeEnter: redirectLoggedIn
    },
    {
        path: '/criar-nova-senha',
        name: 'createnewpassword',
        component: CreateNewPassword,
        beforeEnter: redirectLoggedIn
    },
    {
        path: '/adicionar-novo-administrador',
        name: 'home.createnewadministrator',
        component: CreateNewAministrator,
        beforeEnter: validateAcess,
    },
    {
        path: '*',
        name: 'notfound',
        component: PageNotFound
    },
    {
        path: '/painel',
        component: AppDashboard,
        children: [
            {
                path: '/',
                name: 'home.dashboard',
                component: HomeDashboard,
                beforeEnter: validateAcess
            },
            {
                path: '/graficos',
                name: 'graficos',
                component: GraphicsPage,
                beforeEnter: validateAcess
            },
            {
                path: '/gerar-nf',
                name: 'home.generateinvoice',
                component: GenerateInvoice,
                beforeEnter: validateAcess
            },
            {
                path: '/adicionar-compra',
                name: 'home.createnewpurchase',
                component: CreateNewPurchase,
                beforeEnter: validateAcess
            },
            {
                path: '/historico',
                name: 'home.historic',
                component: HistoricNfe,
                beforeEnter: validateAcess
            },
            {
                path: '/ajustes',
                name: 'home.settings',
                component: Settings,
                beforeEnter: validateAcess,
            },
            {
                path: '/perfil',
                name: 'perfil',
                component: UserProfile,
                beforeEnter: validateAcess,
            },
        ],
    },
];

function validateAcess(to, from, next) {
    let loader = Vue.$loading.show();

    verifyToken.verify()
        // eslint-disable-next-line
        .then(res => {
            loader.hide();
            next()
        })
        // eslint-disable-next-line
        .catch(err => {
            loader.hide();
            next('/login?erro=true')
        })
}

function redirectLoggedIn(to, from, next) {
    let loader = Vue.$loading.show();

    verifyToken.verify()
        // eslint-disable-next-line
        .then(res => {
            loader.hide();
            next('/painel')
        })
        // eslint-disable-next-line
        .catch(err => {
            loader.hide();
            next()
        })
}

const router = new VueRouter({
    // mode: 'history',
    linkExactActiveClass: 'active',
    routes
})

export default router