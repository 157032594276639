<template lang="">

    <div>
        <!-- Modal Body -->
        <div class="modal fade" id="modal-edit-senha" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog"
            aria-labelledby="modalTitleId" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-body px-4 py-4">

                        <button type="button" class="btn btn-none btn-x-close" data-bs-dismiss="modal"
                            aria-label="Close">
                            <img src="@/assets/img/icons/x.svg" alt="" width="20">
                        </button>

                        <!-- Nome -->
                        <div class="">
                            <div class="mt-3 pt-2 form-check-custom">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label class="fw-400 mb-1 fs-16px">Nova Senha</label>
                                            <input type="password" name="senha_editar"
                                                class="form-control text-white bg-dark  rounded-0" ref="newPassword"
                                                style="border-color: #1ca761">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="">
                                            <label class="fw-400 mb-1 fs-16px">Repetir Nova Senha</label>
                                            <input type="password" name="repetir_senha_editar" ref="repetPassword"
                                                class="form-control text-white bg-dark  rounded-0"
                                                style="border-color: #1ca761">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3 pt-3 text-center">
                                <div class="">
                                    <button type="button" class="btn btn-green  btn-sm px-4 fs-14px"
                                        @click="updatePassword">
                                        <div class="px-3">
                                            Atualizar
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import swal from 'sweetalert';
    import userService from '@/services/UserService'
    export default {
        name: 'ModalEditPassword',
        props: ['user'],
        methods: {
            updatePassword() {

                let senha = this.$refs.newPassword.value
                let repetirSenha = this.$refs.repetPassword.value

                if (senha == '') {
                    swal({
                        title: "Digite uma nova senha",
                        icon: "warning",
                        button: "Fechar",
                        dangerMode: true,
                    });
                    return;
                }

                if (senha != repetirSenha) {
                    swal({
                        title: "Repetir a senha não corresponde",
                        icon: "warning",
                        button: "Fechar",
                        dangerMode: true,
                    });
                    return;
                }

                let load = this.$loading.show();
                let formData = {
                    id: this.user.id,
                    senha: senha
                }

                userService
                    .updateUser(this.user.id, formData)
                    .then(res => {
                        console.log(res.data);
                        load.hide()
                        swal({
                            title: "Senha Atualizada",
                            icon: "success",
                            button: "OK",
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    })
                    .catch(err => {
                        console.log(err.response);
                        load.hide()
                        swal({
                            title: "Ouve um erro na solicitação",
                            icon: "warning",
                            button: "Fechar",
                            dangerMode: true,
                        });
                    })
            }
        },
    }
</script>
<style scoped>
    .modal .modal-dialog .modal-content {
        background-color: #363d4c;
        border-color: #475266;
        color: white
    }

    .btn-x-close {
        position: absolute;
        top: 8px;
        right: 0px
    }

    @media(min-width: 992px) {
        .modal-dialog {
            min-width: 350px;
        }
    }

    /* checkbox custom */
    .form-check-custom .form-check-input {
        background-color: #475266;
        border-color: #475266;
        border-radius: 0;
    }

    .form-check-custom .form-check-input:checked {
        background-color: #1ca761;
        border-color: #1ca761;
    }

    .form-check-custom .form-check-input:focus {
        border-color: #1ca761;
        box-shadow: none
    }

    .btn-cancelar {
        background: #475266;
        border-color: #475266
    }

    .btn-cancelar:hover {
        background: #54627c;
        border-color: #54627c
    }
</style>