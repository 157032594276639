<template lang="">
    <div>
        <div class="container py-5">
            <div class="row pt-5">
                <div class="col-12 col-md-7 col-lg-6 col-xl-4 mx-auto pt-2">
                    <div class="card border-0">
                        <div class="card-body p-lg-4">
                            <div class="px-lg-3">
                                <div class="text-center mb-4">
                                    <img src="@/assets/logo.png" alt="Zinzer" />
                                </div>
                                <!--Defina sua nova senha-->
                                <div>
                                    <p class="text-center fw-semibold fs-5 mt-2 color .text-light">
                                        Defina sua nova senha
                                    </p>
                                    <p class="text-center fw-normal fs-6 mt-0 color .text-light">
                                        {{email}}
                                    </p>
                                </div>
                                <!-- msg success -->
                                <div class="" v-if="success">
                                    <div class="alert alert-success" role="alert">
                                        Senha alterada com sucesso. <router-link :to="{name: 'login'}"> Clique aqui
                                        </router-link>
                                        para fazer login
                                    </div>
                                </div>
                                <!-- msg erro -->
                                <div class="" v-if="msgErro">
                                    <div class="alert alert-danger" role="alert">
                                        {{msgErro}}
                                    </div>
                                </div>

                                <!-- Formulário -->
                                <form action="#" method="post" @submit.prevent="sendNewPassword">
                                    <!-- E-mail -->
                                    <div class="mb-3">
                                        <label for="email" class="form-label mb-2">Nova senha</label>
                                        <input type="password" class="form-control" name="email" id="email"
                                            placeholder="****" required v-model="password" />
                                    </div>

                                    <div class="mt-3">
                                        <button type="submit" class="btn btn-green w-100">
                                            Alterar senha
                                        </button>
                                    </div>
                                    <!--Ir para o login-->
                                    <router-link :to="{name: 'login'}"
                                        class="d-block link-dark text-decoration-none text-center fw-normal fs-6 mt-4 color .text-light">
                                        Ir para o login
                                    </router-link>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import authService from '@/services/AuthService';
    export default {
        name: "CreateNewPassword",
        data() {
            return {
                showPasswordBool: false,
                email: '',
                hash: '',
                password: '',
                msgErro: false,
                success: false,
            };
        },
        methods: {
            showPassword: function () {
                const elPassword = this.$refs.password;

                if (elPassword.type == "password") {
                    this.showPasswordBool = true;
                    elPassword.type = "text";
                } else {
                    elPassword.type = "password";
                    this.showPasswordBool = false;
                }
            },
            login: function () {
                let load = this.$loading.show();
                setTimeout(() => {
                    this.$router.push({
                        name: "home.dashboard",
                    });

                    load.hide();
                }, 1000);
            },
            sendNewPassword() {
                let load = this.$loading.show();
                let formData = {
                    email: this.email,
                    hash: this.hash,
                    senha: this.password,
                }
                authService
                    .createNewPassword(formData)
                    .then(res => {
                        console.log(res.data);
                        load.hide()
                        this.msgErro = ''
                        this.success = true
                    }).catch(err => {
                        load.hide()
                        this.success = false
                        if (err.response.data.message)
                            this.msgErro = err.response.data.message
                        else
                            this.msgErro = 'Ouve um erro ao enviar o link de confirmação.'
                    })
            }
        },
        mounted() {
            this.email = this.$route.query.email
            this.hash = this.$route.query.hash
        },
        metaInfo: {
            titleTemplate: "Recuperar Senha - Zinzer",
        },
    };
</script>
<style scoped>
    @import "@/assets/css/auth.css";

    .exibir-senha {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
    }

    .input-senha {
        padding-right: 50px;
    }
</style>