<template lang="">
    <div>
        <!-- Modal Body -->
        <div class="modal fade" :id="`modal-delete-cnpj-${dataCnpj.id}`" tabindex="-1" data-bs-backdrop="static"
            data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-body px-4 py-4">

                        <button type="button" class="btn btn-none btn-x-close" data-bs-dismiss="modal"
                            aria-label="Close">
                            <img src="@/assets/img/icons/x.svg" alt="" width="20">
                        </button>

                        <div class="text-center fs-14px pt-3 mt-2">
                            <div class="text-danger fw-bold fs-5 mb-3">{{dataCnpj.cnpj | formatCnpj}}</div>
                            Tem certeza que deseja deletar esse registro?
                        </div>

                        <div class="mt-3 text-center">
                            <button type="button" class="btn btn-danger btn-sm px-4 fs-14px" data-bs-dismiss="modal"
                                @click="deleteCnpj()">
                                <div class="px-3">
                                    Sim
                                </div>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import swal from 'sweetalert';
    import cnpjService from '@/services/CnpjService'
    export default {
        name: 'ModalDeleteCnpj',
        props: ['dataCnpj'],
        methods: {
            deleteCnpj() {
                let load = this.$loading.show();
                cnpjService
                    .deleteCnpj(this.dataCnpj.id)
                    // eslint-disable-next-line
                    .then(res => {
                        load.hide()
                        swal({
                            title: "Registro deletado",
                            icon: "success",
                            button: "OK",
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 200);

                    }).catch(err => {
                        console.log(err.response);
                        load.hide()
                        swal({
                            title: "Ouve um erro na solicitação",
                            icon: "warning",
                            button: "Fechar",
                            dangerMode: true,
                        });
                    })
            }
        },
    }
</script>
<style scoped>
    .modal .modal-dialog .modal-content {
        background-color: #363d4c;
        border-color: #475266;
        color: white
    }

    .btn-x-close {
        position: absolute;
        top: 8px;
        right: 0px
    }

    @media(min-width: 992px) {
        .modal-dialog {
            min-width: 350px;
        }
    }
</style>