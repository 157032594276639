<template>
  <div class="row justify-content-md-center mt-4 historico">
    <div class="col-md-11 mt-1">
      <div class="input-group align-items-center">
        <div class="col-md-12 mt-1">
          <div class="input-group mb-3 align-items-center">
            <div
              class="col-md-2 m-1"
              style="min-width: 270px !important; width: 260px !important"
            >
              <select
                class="select-entradas card border-success form-select form-select-lg btn-outline-light shadow-lg"
                @change="setFiscalNoteType($event.target.index)"
              >
                <option selected @click="getAllCnpjsExits()">
                  Listando: Saídas
                </option>
                <option selected @click="getAllCnpjsEntries()">
                  Listando: Entradas
                </option>
              </select>
            </div>
            <div id="app">
              <div class="container" style="margin-left: 4px">
                <!-- Selecionar conta Mercado Livre -->
                <div class="form-check-custom form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="ml"
                    value="1"
                    id="checkbox1"
                    @click="getAllPfData()"
                  />

                  <label
                    class="form-check-label"
                    for="checkbox1"
                    style="margin-left: 4px"
                    >Pessoa física</label
                  >
                </div>
                <div class="form-check-custom form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="ml"
                    value="2"
                    id="checkbox2"
                    @click="getAllPjData()"
                  />
                  <label
                    class="form-check-label"
                    for="checkbox2"
                    style="margin-left: 4px"
                    >Pessoa jurídica</label
                  >
                </div>
                <div class="form-check-custom form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="ml"
                    value="1"
                    id="checkbox1"
                    checked
                    @click="getTableData()"
                  />

                  <label
                    class="form-check-label"
                    for="checkbox1"
                    style="margin-left: 4px"
                    >Todos</label
                  >
                </div>
              </div>
            </div>

            <form class="d-flex ms-auto w-50 col-md-2 align-items-center">
              <div class="input-group">
                <input
                  v-model="search"
                  class="form-control input-pesquisa"
                  placeholder="Pesquise..."
                  style="background-color: #363d4c; border: 1px; color: #ffff"
                />
              </div>
            </form>
            <div class="input-group">
              <div
                class="col-md-3 mt-2 mb-1"
                style="min-width: 450px; margin-right: 8px"
              >
                <select
                  class="form-select-custom card border-success form-select form-select-lg"
                  style="background-color: #363d4c; color: white"
                  v-model="formData.cnpj"
                  required
                >
                  <option
                    v-for="(item, index) in cnpjs"
                    :key="index"
                    :value="
                      item.cnpj
                        .replace(/-/gi, '')
                        .replace(/\./gi, '')
                        .replace(/\//gi, '')
                    "
                    @click="getWithCnpj()"
                  >
                    <span v-if="item.id == 1 || item.id == 2"> LTDA </span>
                    <span v-else> MEI </span>
                    {{ item.id }}: {{ item.cnpj }}
                  </option>
                </select>
              </div>
              <div style="margin-top: 8px; margin-left: 4px; margin-right: 4px">
                <input
                  type="date"
                  class="form-control form-control-lg rounded"
                  id="dateInput"
                  v-model="initialDate"
                />
              </div>
              <div style="margin-top: 8px; margin-left: 4px; margin-right: 4px">
                <input
                  type="date"
                  class="form-control form-control-lg rounded"
                  id="dateInput1"
                  v-model="finalDate"
                />
              </div>
              <div class="navbar-nav ms-auto mt-lg-0 gap-0">
                <button
                  type="button"
                  class="btn btn-lg btn-green mt-1 align-items-center shadow-lg px-4"
                  @click="handleDownload()"
                >
                  <i class="bi bi-person"></i> Baixar resultado
                  <img
                    src="@/assets/img/icons/download.svg"
                    width="23"
                    class="ms-1"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive rounded" style="background-color: #2d3340">
        <table
          class="table table-bordered table-dados"
          style="background-color: #2d3340; white-space: nowrap"
        >
          <thead
            class="align-middle sticky-top"
            style="color: #ffff; font-size: 12px; font-weight: normal"
          >
            <tr>
              <th scope="col">ID</th>
              <th scope="col">CNPJ certificado</th>
              <th scope="col">Número</th>
              <th scope="col">Série</th>
              <th scope="col">Finalidade</th>
              <th scope="col">Data de Emissão</th>
              <th scope="col">CNPJ/CPF Emitente</th>
              <th scope="col">Razão Social Emitente</th>
              <th scope="col">Inscrição Estadual Emitente</th>
              <th scope="col">UF Emitente</th>
              <th scope="col">CNPJ/CPF Destinatário</th>
              <th scope="col">Razão Social Destinatário</th>
              <th scope="col">Inscrição Estadual Destinatário</th>
              <th scope="col">UF Destinatário</th>
              <th scope="col">Valor Total</th>
              <th scope="col">Produto</th>
              <th scope="col">Quantidade</th>
              <th scope="col">Unidade Comercial</th>
              <th scope="col">Valor Unitário</th>
              <th scope="col">Valor do Produto</th>
              <th scope="col">Desconto</th>
              <th scope="col">PIS CST</th>
              <th scope="col">PIS Base de Cálculo</th>
              <th scope="col">PIS Percentual</th>
              <th scope="col">PIS Valor</th>
              <th scope="col">COFINS CST</th>
              <th scope="col">COFINS Base de Cálculo</th>
              <th scope="col">COFINS Percentual</th>
              <th scope="col">COFINS Valor</th>
              <th scope="col">CEST</th>
              <th scope="col">NCM</th>
              <th scope="col">CFOP</th>
              <th scope="col">Origem</th>
              <th scope="col">ICMS CST</th>
              <th scope="col">ICMS Modalidade Base de Cálculo</th>
              <th scope="col">ICMS Base de Cálculo</th>
              <th scope="col">ICMS Percentual</th>
              <th scope="col">Valor do ICMS</th>
              <th scope="col">Modalidade Base de Cálculo do ICMS ST</th>
              <th scope="col">ICMS ST Percentual</th>
              <th scope="col">ICMS ST Valor</th>
              <th scope="col">MVA ST Percentual</th>
              <th scope="col">IPI CST</th>
              <th scope="col">IPI CST Não Tributado</th>
              <th scope="col">IPI Base de Cálculo</th>
              <th scope="col">IPI Base de Cálculo Não Tributado</th>
              <th scope="col">IPI Alíquota Percentual</th>
              <th scope="col">IPI Alíquota Percentual Não Tributado</th>
              <th scope="col">Valor do IPI</th>
              <th scope="col">CSOSN</th>
              <th scope="col">Chave</th>
              <th scope="col">Status</th>
              <th scope="col">Criado em</th>
              <th scope="col">Atualizado em</th>
            </tr>
          </thead>
          <tbody class="align-middle" style="color: #b8becc">
            <tr v-for="user in paginatedData" :key="user.id">
              <td>{{ user.id }}</td>
              <td>{{ user.cnpjCertificado }}</td>
              <td>{{ user.numero }}</td>
              <td>{{ user.serie }}</td>
              <td>{{ user.finalidade }}</td>
              <td>{{ user.dt_emissao }}</td>
              <td>{{ user.cnpj_cpf_emit }}</td>
              <td>{{ user.rz_emit }}</td>
              <td>{{ user.ie_emit }}</td>
              <td>{{ user.uf_emit }}</td>
              <td>{{ user.cnpj_cpf_dest }}</td>
              <td>{{ user.rz_dest }}</td>
              <td>{{ user.ie_dest }}</td>
              <td>{{ user.uf_dest }}</td>
              <td>{{ user.valor }}</td>
              <td>{{ user.produto }}</td>
              <td>{{ user.quantidade }}</td>
              <td>{{ user.unidade_comercial }}</td>
              <td>{{ user.valor_unitario }}</td>
              <td>{{ user.valor_produto }}</td>
              <td>{{ user.desconto }}</td>
              <td>{{ user.pis_cst }}</td>
              <td>{{ user.pis_base_calculo }}</td>
              <td>{{ user.pis_percentual }}</td>
              <td>{{ user.pis_valor }}</td>
              <td>{{ user.cofins_cst }}</td>
              <td>{{ user.cofins_base_calculo }}</td>
              <td>{{ user.cofins_percentual }}</td>
              <td>{{ user.cofins_valor }}</td>
              <td>{{ user.cest }}</td>
              <td>{{ user.ncm }}</td>
              <td>{{ user.cfop }}</td>
              <td>{{ user.origem }}</td>
              <td>{{ user.icms_cst }}</td>
              <td>{{ user.icms_mod_b_calculo }}</td>
              <td>{{ user.icms_base_calculo }}</td>
              <td>{{ user.icms_percentual }}</td>
              <td>{{ user.valor_icms }}</td>
              <td>{{ user.mod_base_calculo_st }}</td>
              <td>{{ user.icms_st_percentual }}</td>
              <td>{{ user.icms_st_valor }}</td>
              <td>{{ user.mva_st_percentual }}</td>
              <td>{{ user.ipi_cst }}</td>
              <td>{{ user.ipi_cst_nao_trib }}</td>
              <td>{{ user.ipi_base_calculo }}</td>
              <td>{{ user.ipi_base_calculo_nao_trib }}</td>
              <td>{{ user.ipi_aliquota_percentual }}</td>
              <td>{{ user.ipi_aliquota_percentual_nao_trib }}</td>
              <td>{{ user.valor_ipi }}</td>
              <td>{{ user.csosn }}</td>
              <td>{{ user.chave }}</td>
              <td>{{ user.status }}</td>
              <td>{{ user.createdAt }}</td>
              <td>{{ user.updatedAt }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="m-3">
        <paginate
          :click-handler="goToPage"
          :prev-text="'' + 'Voltar'"
          :next-text="'' + 'Avançar'"
          :page-class="'page-item m-2'"
          first-last-button
          :container-class="'pagination'"
        ></paginate>
      </div>
    </div>
  </div>
</template>

<script>
import HistoricNfeService from "@/services/HistoricNfeService";
import Paginate from "vuejs-paginate";
import cnpjService from "@/services/CnpjService";
import swal from "sweetalert";
import axios from "axios";

export default {
  data() {
    return {
      tableData: [],
      search: "",
      initialDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .toISOString()
        .substr(0, 10),
      finalDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      )
        .toISOString()
        .substr(0, 10),
      pageSize: 12, // Number of items to show per page
      currentPage: 1, // Current page number
      cnpjs: [],
      xls: "",
      dataSuccess: {},
      selectedBlingNotes: false,
      selectedMlNotes: false,
      accounts: {},
      formData: {
        // mei: '',
        cnpj: "",
        ml: 0,
        planilha: "",
        // notas: []
        xmls: [],
      },
    };
  },
  components: {
    Paginate,
  },

  methods: {
    async getTableData() {
      let load = this.$loading.show();
      await HistoricNfeService.getTableData().then((response) => {
        this.tableData = response.data;
        load.hide();
      });
    },
    async getAccounts() {
      let load = this.$loading.show();
      await cnpjService
        .getAllCnpj()
        .then((res) => {
          this.cnpjs = res.data.cnpjs;
          load.hide();
        })
        .catch((err) => {
          console.log(err.response);
          load.hide();
        });
    },
    async getAllCnpjsEntries() {
      let load = this.$loading.show();
      await HistoricNfeService.getAllCnpjsEntries().then((response) => {
        this.tableData = response.data;
        load.hide();
      });
    },
    async getAllCnpjsExits() {
      let load = this.$loading.show();
      await HistoricNfeService.getAllCnpjsExits().then((response) => {
        this.tableData = response.data;
        load.hide();
      });
    },
    async getXlsFile() {
      let load = this.$loading.show();
      await HistoricNfeService.getXlsFile(
        "https://mapapun.com/Zinzer/api/info/xls.php",
        this.tableData,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      )
        .then((res) => {
          this.xls = res.data.file;
          load.hide();
        })
        .catch(() => {
          swal({
            title: "Não foi possivel gerar o arquivo.",
            icon: "warning",
            button: "Fechar",
            dangerMode: true,
          });
          load.hide();
        });
    },
    downloadFile(base64Content, fileName) {
      this.getXlsFile();

      const link = document.createElement("a");
      link.href = "data:application/octet-stream;base64," + base64Content;
      link.download = fileName;
      link.click();
    },
    handleDownload() {
      const base64Content = this.xls; // conteúdo do arquivo em formato Base64
      const fileName = "resultados.xls"; // nome do arquivo
      this.downloadFile(base64Content, fileName);
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    async getAllPfData() {
      let load = this.$loading.show();
      await HistoricNfeService.getCpfData().then((response) => {
        this.tableData = response.data;
        load.hide();
      });
    },
    async getAllPjData() {
      let load = this.$loading.show();
      await HistoricNfeService.getCnpjData().then((response) => {
        this.tableData = response.data;
        load.hide();
      });
    },
    async getWithCnpj() {
      let load = this.$loading.show();
      await axios
        .get(`https://mapapun.com:3500/notas/${this.formData.cnpj}`)
        .then((response) => {
          this.tableData = response.data;
          load.hide();
        });
    },
  },
  computed: {
    filteredData() {
      return this.tableData.filter((data) => {
        return (
          data.produto.toLowerCase().includes(this.search.toLowerCase()) ||
          data.quantidade.toString().includes(this.search) ||
          data.cnpjCertificado.toString().includes(this.search) ||
          data.cnpjCertificado.toString().includes(this.search) ||
          data.rz_emit.toString().includes(this.search) ||
          data.rz_dest.toString().includes(this.search)
        );
      });
    },
    // Calculate the total number of pages based on the pageSize
    pageCount() {
      return Math.ceil(this.filteredData.length / this.pageSize);
    },
    // Get the data for the current page
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredData.slice(startIndex, endIndex);
    },
  },
  created() {
    this.getAccounts();
    this.getTableData();
  },
};
</script>

<style>
.pagination {
  color: #29cc7a;
}

.page-item {
  display: none;
  margin: 5;
}

input[type="date"] {
  background-color: #363d4c;
  color: white;
  border: 1px solid #28a745;
  /* green border */
  border-radius: 4px;
  /* rounded corners */
  padding: 8px;
  /* add some padding */
}

input[type="date"]:focus {
  outline: none;
  /* remove default focus outline */
  border-color: #17a2b8;
  /* change border color on focus */
}

.table-responsive {
  max-height: 90vh;
  margin-bottom: 40px;
}

.select-entradas {
  background-color: #29cc7a !important;
  color: white !important;
  background-image: url(@/assets/img/icons/arrow-down-white-2.svg) !important;
  background-size: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  background-position: center right 20px !important;
}

.input-pesquisa {
  padding: 10px 15px !important;
}

.input-pesquisa::placeholder {
  color: #b8becc !important;
}

.select-meis {
  background-color: #363d4c !important;
  color: white !important;

  background-image: url(@/assets/img/icons/arrow-down.svg) !important;
  background-size: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  background-position: center right 20px !important;
}

table.table-dados thead tr {
  background-color: #363d4c;
  border-color: #363d4c #363d4c #475266 #363d4c;
  font-weight: 400 !important;
}

table.table-dados thead tr th {
  font-weight: 500 !important;
  font-size: 1.3em;
  padding-left: 20px;
  padding-right: 20px;
}

table.table-dados tbody tr td {
  padding-left: 20px;
  padding-right: 20px;
}

table.table-dados tbody tr {
  border-color: #475266;
}

table.table-dados tbody tr td a {
  color: #b8becc;
}

/* checkbox custom */
.form-check-custom .form-check-input {
  background-color: #475266;
  border-color: #475266;
  border-radius: 50% !important;
}

.form-check-custom .form-check-input:checked {
  background-color: #1ca761;
  border-color: #1ca761;
}

.form-check-custom .form-check-input:focus {
  border-color: #1ca761;
  box-shadow: none;
}

.label-select {
  transition: all 0.2s;
  position: relative;
}

.label-select:hover {
  cursor: pointer;
  opacity: 0.8;
}

.icon-check {
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: #1ca761;
  font-size: 1.3em;
}
</style>
