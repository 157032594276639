<template lang="">
    <div>
        <!-- Modal Body -->
        <div class="modal fade" id="modal-delete-all" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
            role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-body px-4 py-4">

                        <button type="button" class="btn btn-none btn-x-close" data-bs-dismiss="modal"
                            aria-label="Close">
                            <img src="@/assets/img/icons/x.svg" alt="" width="20">
                        </button>

                        <div class="text-center fs-14px pt-4 mt-2">
                            Tem certeza que deseja <span class="text-danger">deletar todos os dados</span>
                            disponíveis nessa plataforma?
                        </div>

                        <div class="mt-3 text-center">
                            <button type="button" class="btn btn-danger btn-sm px-4 fs-14px" data-bs-toggle="modal"
                                data-bs-target="#modal-verify-security">
                                <div class="px-3">
                                    Sim
                                </div>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
    export default {
        name: 'ModalDeleteAll'
    }
</script>
<style scoped>
    .modal .modal-dialog .modal-content {
        background-color: #363d4c;
        border-color: #475266;
        color: white
    }

    .btn-x-close {
        position: absolute;
        top: 8px;
        right: 0px
    }

    @media(min-width: 992px) {
        .modal-dialog {
            min-width: 350px;
        }
    }
</style>