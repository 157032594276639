<template>
    <div class="container-dashboard">
        <div class="  mt-4 px-4">
            <div class="pb-5">
                <div class="rounded-4 p-4 " style="background-color: #2D3340;">
                    <div class="p-2 ">

                        <!-- Se for LTDA -->
                        <div v-show="!isNotLtda()">
                            <h1 class="text-white h4 pb-0">Conta LTDA {{ this.$route.query.cnpj }}</h1>
                        </div>

                        <!--Abas-->
                        <ul class="nav nav-tabs tab-bar border-0" id="myTab">
                            <li class="nav-item px-2" v-show="isNotLtda()">
                                <p class="active tab-item p-0 pb-1 fs-4 fw-bold" id="home-tab" data-bs-toggle="tab"
                                    data-bs-target="#home" type="button" role="tab" style="color: #ffff;">
                                    CNPJs
                                </p>
                            </li>

                            <ul class="navbar-nav ms-auto mt-2 mt-lg-0 gap-3" v-show="isVisible">
                                <li class="nav-item">
                                    <button class="btn btn-red" style="color: #b8becc; text-decoration-line: none;"
                                        @click="reloadOrBackPage">
                                        <i class="fa-solid fa-arrow-left me-1 fa-sm"></i>
                                        <span>Voltar</span>
                                    </button>
                                </li>

                            </ul>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <!--CNPJs-->
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <!--Adicionar um novo CNPJ-->
                                <div v-if="isVisible">
                                    
                                    <div class="form-check-custom">
                                        <label for="email" class="form-label pb-0 w-100 mt-2 text-start px-2">
                                            Para qual conta vai este CNPJ?
                                        </label>
                                        <!-- Selecionar conta Mercado Livre -->
                                        <div class="form-check form-check-inline mt-2 m-2">
                                            <input class="form-check-input rounded-0" type="checkbox" value="1"
                                                id="checkbox-ml-1"
                                                :checked="formData.ml == 1 || formData.ml == '1,2' ? true : false">
                                            <label class="form-check-label" for="checkbox-ml-1">ML conta 1</label>
                                        </div>
                                        <div class="form-check form-check-inline mt-2 m-2">
                                            <input class="form-check-input rounded-0" type="checkbox" value="2"
                                                id="checkbox-ml-2"
                                                :checked="formData.ml == 2 || formData.ml == '1,2' ? true : false">
                                            <label class="form-check-label" for="checkbox-ml-2">ML conta 2</label>
                                        </div>

                                        <!-- Tipo de cnpj -->
                                        <label for="email" class="form-label pb-0 w-100 mt-2 text-start px-2 mt-4 pt-2">
                                            Este CNPJ é:
                                        </label>
                                        <!-- Selecionar conta Mercado Livre -->
                                        <div class="form-check form-check-inline mt-2 m-2">
                                            <input class="form-check-input rounded-0" type="radio" value="1"
                                                name="tipo_cnpj" id="tipo-mei"
                                                :checked="(dataCnpjEdit.tipo == 1 ) ? true : false">
                                            <label class="form-check-label" for="tipo-mei">MEI</label>
                                        </div>
                                        <div class="form-check form-check-inline mt-2 m-2">
                                            <input class="form-check-input rounded-0" type="radio" value="2"
                                                name="tipo_cnpj" id="tipo-ltda"
                                                :checked="(dataCnpjEdit.tipo == 2) ? true : false">
                                            <label class="form-check-label" for="tipo-ltda">LTDA </label>
                                        </div>

                                        <div class="py-2"></div>

                                        <div class="input-group mb-3 m-1 mt-3 gap-3">
                                            <div class="m-1 col-md-4">
                                                <label for="name" name
                                                    class="form-label pb-1 mb-1 w-100 text-start">Nome</label>
                                                <input type="text" name="name" id="name" v-model="name"
                                                    class="form-control form-control-lg rounded-3"
                                                    placeholder="Ex: CNPJ Principal" aria-label="Ex: CNPJ Principal"
                                                    style="background-color: transparent; color: white; border-radius: 8px !important; border: 1px solid #B8BECC;">
                                            </div>
                                            <div v-if="showPicker" class="color-picker border-0 mx-2 mt-1">
                                                <label class="form-label pb-1 mb-1 w-100 text-start">
                                                    Escolher cor do CNPJ
                                                </label>
                                                <div class="input-group">
                                                    <input class="rounded-3 p-0  no-border" type="color" id="cor-cnpj"
                                                        v-model="selectedColor" @input="updateColor"
                                                        style="width: 95px; height: 47px; border: 1px solid #B8BECC !important; cursor: pointer; overflow: hidden;">
                                                    <div class="text-center m-2">
                                                        <label for="cor-cnpj" style="cursor: pointer;" class="p-2 pt-1">
                                                            <img src="@/assets/img/icons/arrow-icon.svg" width="25px"
                                                                style="" alt="Zinzer" class="" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="input-group mb-3 m-1 mt-1 gap-3">
                                            <div class="m-1 col-md-4 mt-3">
                                                <label for="cnpj"
                                                    class="form-label pb-1 mb-2 mt-2 w-100 text-start">Número
                                                    do
                                                    CNPJ</label>
                                                <input type="text" name="cnpj" id="cnpj" v-model="cnpj"
                                                    class="form-control form-control-lg"
                                                    placeholder="01.234.567/0001-89" aria-label="01.234.567/0001-89"
                                                    style="background-color: transparent; color: white; border-radius: 8px !important; border: 1px solid #B8BECC;"
                                                    v-mask="'##.###.###/####-##'">
                                            </div>
                                            <div class="col-md-2 m-1 mt-3">
                                                <label for="stateRegistration"
                                                    class="form-label pb-1 mb-2 mt-2 w-100 text-start">Inscrição
                                                    estadual</label>
                                                <input type="text" name="stateRegistration" id="stateRegistration"
                                                    v-model="stateRegistration" class="form-control form-control-lg"
                                                    placeholder="01.234.567-8" aria-label="12345678"
                                                    style="background-color: transparent; color: white; border-radius: 8px !important; border: 1px solid #B8BECC;"
                                                    v-mask="'##.###.###-#'">
                                            </div>

                                        </div>
                                        <div class="m-1 col-md-4 mt-5 mb-5">
                                            <label for="address"
                                                class="form-label pb-1 mb-2 mt-2 w-100 text-start">Endereço</label>
                                            <input type="text" name="address" id="address" v-model="address"
                                                class="form-control form-control-lg" placeholder="Av. Brasil, 123"
                                                aria-label="Av. Brasil, 123"
                                                style="background-color: transparent; color: white; border-radius: 8px !important; border: 1px solid #B8BECC;">
                                        </div>
                                        <div class="m-1 col-md-4 mt-5 mb-5">
                                            <label for="email" class="form-label pb-1 mb-2 mt-2 w-100 text-start">E-mail
                                                de
                                                contato</label>
                                            <input type="text" name="email" id="email" v-model="email"
                                                class="form-control form-control-lg" placeholder="oi@exemplo.com"
                                                aria-label="oi@exemplo.com"
                                                style="background-color: transparent; color: white; border-radius: 8px !important; border: 1px solid #B8BECC;">
                                        </div>
                                        <div class="m-1 col-md-4 mt-5 mb-5 d-none">
                                            <label for="transactionsLimited"
                                                class="form-label pb-1 mb-2 mt-2 w-100 text-start">
                                                Limite total para transações
                                            </label>

                                            <money v-model="transactionsLimited" v-bind="money"
                                                name="transactionsLimited" id="transactionsLimited"
                                                class="form-control form-control-lg" placeholder="R$ 30.000"
                                                style="background-color: transparent; color: white; border-radius: 8px !important; border: 1px solid #B8BECC;">
                                            </money>

                                        </div>
                                        <div class="m-1 col-md-4 mt-5 mb-5">
                                            <label for="limitEntries"
                                                class="form-label pb-1 mb-2 mt-2 w-100 text-start">Limite de
                                                entradas</label>

                                            <money v-model="limitEntries" v-bind="money" name="limitEntries"
                                                id="limitEntries" class="form-control form-control-lg"
                                                placeholder="R$ 15.000" aria-label="R$ 15.000"
                                                style="background-color: transparent; color: white; border-radius: 8px !important; border: 1px solid #B8BECC;">
                                            </money>

                                        </div>
                                        <div class="m-1 col-md-4 mt-5 mb-5">
                                            <label for="limitExits"
                                                class="form-label pb-1 mb-2 mt-2 w-100 text-start">Limite de
                                                saídas</label>

                                            <money v-model="limitExits" v-bind="money" name="limitExits" id="limitExits"
                                                class="form-control form-control-lg" placeholder="R$ 15.000"
                                                aria-label="Descrição do produto / serviço"
                                                style="background-color: transparent; color: white; border-radius: 8px !important; border: 1px solid #B8BECC;">
                                            </money>

                                        </div>
                                        <div class="alert alert-danger alert-dismissible fade show" role="alert"
                                            v-if="msgError">
                                            {{msgError}}
                                        </div>
                                        <div class="alert alert-warning" role="alert" v-if="this.$route.query.erro">
                                            Você precisa fazer login primeiro.
                                        </div>
                                        <div class="mt-4 w-50 mt-5">
                                            <a @click="handleCnpj">
                                                <button class="btn btn-green p-3 py-2 w-50 fw-600">
                                                    <div class="py-2">
                                                        {{  editCnpj? 'Atualizar cadastro': 'Adicionar novo CNPJ'  }}
                                                    </div>
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!--Lista de CNPJs-->
                                <div id="tab1" v-else>
                                    <div v-for="(data, index) in data" :key="index" class="d-flex mt-3">
                                        <div class="mt-1 mb-3">
                                            <h5 class="fw-normal">
                                                {{ data.nome == '' ? data.cnpj : data.nome }}
                                                <a @click="handleEditCnpj({ id: data.id })" style="cursor: pointer;"
                                                    title="Editar">
                                                    <img src="@/assets/img/icons/edit-icon.svg" width="20px"
                                                        height="20px" alt="Zinzer" />
                                                </a>
                                            </h5>
                                            <small class="text-gray-cnpj">{{ data.cnpj }}</small>
                                        </div>
                                    </div>
                                    <div class="mt-4 w-50 mt-3 pt-2">
                                        <a @click="showHide">
                                            <button class="btn btn-green p-3 py-1 w-50 fw-600 ">
                                                <div class="py-2">
                                                    Cadastrar novo CNPJ
                                                </div>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!--Minha conta-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import cnpjService from '@/services/CnpjService'
    import swal from 'sweetalert';
    import {
        Money
    } from 'v-money'

    export default {
        name: "SettingsValue",
        data() {
            return {
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$ ',
                    suffix: '',
                    precision: 2,
                    masked: false
                },
                idCnpjEdit: null,
                typeCnpj: null,
                name: '',
                cnpj: '',
                address: '',
                email: '',
                transactionsLimited: 0,
                limitEntries: null,
                limitExits: null,
                stateRegistration: '',
                selectedColor: '#8F00FF',
                isVisible: false,
                showPicker: true,
                data: [],
                msgError: false,
                editCnpj: false,
                editUser: false,
                editCnpjId: null,
                editUserId: null,
                cnpjExists: false,
                formData: {
                    ml: 0,
                },
                dataCnpjEdit: {}
            };
        },
        components: {
            Money
        },
        methods: {
            setMlSelected(event) {
                if (event.target.checked) {
                    this.formData.ml = event.target.value
                } else {
                    this.formData.ml = ''
                }
            },
            showHide() {
                this.resetFormCnpj()
                this.editCnpj = false
                this.isVisible = !this.isVisible
            },
            goBack() {
                this.$router.go(-1)
            },
            updateColor(event) {
                this.selectedColor = event.target.value;
            },
            // Edita o CNPJ
            handleEditCnpj(event) {
                this.editCnpjId = event.id

                let load = this.$loading.show();
                cnpjService.getCnpj(this.editCnpjId)
                    .then((res) => {
                        let cnpj = res.data.cnpj
                        this.name = cnpj.nome;
                        this.selectedColor = cnpj.cor;
                        this.cnpj = cnpj.cnpj;
                        this.stateRegistration = cnpj.nire;
                        this.address = cnpj.endereco;
                        this.email = cnpj.email;
                        this.transactionsLimited = 1;
                        this.limitEntries = Number(cnpj.limite2);
                        this.limitExits = Number(cnpj.limite3);
                        this.formData.ml = cnpj.ml
                        this.idCnpjEdit = cnpj.id
                        this.dataCnpjEdit = cnpj
                        this.typeCnpj = cnpj.tipo

                        this.editCnpj = true
                        this.isVisible = !this.isVisible
                        load.hide()

                    }).catch(res => {
                        load.hide()
                        swal({
                            title: res.response.data.message,
                            icon: "warning",
                            button: "Fechar",
                            dangerMode: true,
                        });
                    })
            },
            // Busca os CNPJs cadastrados
            async getCnpjs() {
                let load = this.$loading.show();
                await cnpjService.getAllCnpj()
                    .then(res => {
                        this.data = res.data.cnpjs
                        load.hide()
                    }).catch(err => {
                        console.log(err.response);
                        load.hide()
                    })
            },
            handleCnpj() {

                // se cnpj foi preenchido
                if (this.cnpj == '') {
                    swal({
                        title: "O número do CNPJ é obrigatório",
                        icon: "warning",
                        button: "Fechar",
                        dangerMode: true,
                    });
                    return;
                }

                // Percorrendo o array de objetos
                for (let i = 0; i < this.data.length; i++) {
                    // Comparando a chave 'nome' com o valor
                    if (this.data[i].cnpj === this.cnpj && this.data[i].id != this.editCnpjId) {
                        swal({
                            title: `O CNPJ ${this.data[i].cnpj} já existe no banco dados `,
                            icon: "warning",
                            buttons: {
                                adicionar: {
                                    text: "Adicionar mesmo assim",
                                    value: "adicionar",
                                },
                                fechar: {
                                    text: "Fechar",
                                    value: "fechar",
                                },
                            },
                            dangerMode: true,
                        }).then((value) => {
                            if (value === "adicionar") {
                                this.createCnpj()
                            }
                        })
                        this.cnpjExists = true
                        break; // Se encontrado, interrompe o loop
                    } else {
                        this.cnpjExists = false
                    }
                }

                // se cnpj é um formato valido
                let cnpjValid = this.cnpj.replace(/[^\d]+/g, '');
                if (cnpjValid.length !== 14) {
                    swal({
                        title: "O número do CNPJ está incompleto",
                        icon: "warning",
                        button: "Fechar",
                        dangerMode: true,
                    });
                    return;
                }

                /* se o tipo foi selecionado */
                if (!document.getElementById('tipo-ltda').checked && !document.getElementById('tipo-mei').checked) {
                    swal({
                        title: "Selecione o tipo de CNPJ",
                        icon: "warning",
                        button: "Fechar",
                        dangerMode: true,
                    });
                    return;
                }

                // obrigatório selecionar qual ML
                if (
                    !document.getElementById('checkbox-ml-1').checked &&
                    !document.getElementById('checkbox-ml-2').checked
                ) {
                    swal({
                        title: "Seleciona uma conta ML",
                        icon: "warning",
                        button: "Fechar",
                        dangerMode: true,
                    });
                    return
                }

                // obrigatório o limite de entrada
                if (this.limitEntries == 0) {
                    swal({
                        title: "Adicione um limite de entradas",
                        icon: "warning",
                        button: "Fechar",
                        dangerMode: true,
                    });
                    return;
                }

                // obrigatório o limite de saída
                if (this.limitExits == 0) {
                    swal({
                        title: "Adicione um limite de saídas",
                        icon: "warning",
                        button: "Fechar",
                        dangerMode: true,
                    });
                    return;
                }

                // limite de entradas não pode ser maior q o limtie de saídas
                if (this.limitEntries > this.limitExits) {
                    swal({
                        title: "O limite de entradas não pode ser maior que o limite de saídas",
                        icon: "warning",
                        button: "Fechar",
                        dangerMode: true,
                    });
                    return;
                }

                // Se for editando cnpj
                if (this.editCnpj)
                    this.updateCnpj()
                else
                if (this.cnpjExists) {
                    return
                } else {
                    this.createCnpj()
                }
            },
            updateCnpj() {

                let mlSelecionada = [];
                if (document.getElementById('checkbox-ml-1').checked) {
                    mlSelecionada.push(1)
                }
                if (document.getElementById('checkbox-ml-2').checked) {
                    mlSelecionada.push(2)
                }

                let typeCnpj = document.getElementById('tipo-ltda').checked ? 2 : 1;

                let load = this.$loading.show();
                let formData = {
                    ml: mlSelecionada.join(','),
                    nome: this.name,
                    cor: this.selectedColor,
                    cnpj: this.cnpj,
                    nire: this.stateRegistration,
                    endereco: this.address,
                    email: this.email,
                    limite1: this.transactionsLimited,
                    limite2: this.limitEntries,
                    limite3: this.limitExits,
                    id: this.idCnpjEdit,
                    tipo: typeCnpj
                };

                cnpjService
                    .updateCnpj(this.idCnpjEdit, formData)
                    .then(res => {
                        if (this.$route.query.cnpj != null || this.$route.query.addCnpj != null) {
                            setTimeout(() => {
                                load.hide()
                                /* redirecionar  para o painel */
                                this.$router.push({
                                    name: "home.dashboard",
                                });
                                return;
                            }, 800);
                        } else {
                            load.hide()
                            swal({
                                title: res.data.message,
                                icon: "success",
                                button: "OK",
                            });
                        }

                    }).catch(err => {
                        load.hide()
                        let msgErro = 'Ouve um erro na solicitação'
                        if (err.response.data.message)
                            msgErro = err.response.data.message
                        swal({
                            title: msgErro,
                            icon: "warning",
                            button: "Fechar",
                            dangerMode: true,
                        });
                    })
            },
            createCnpj() {
                let load = this.$loading.show();
                let mlSelecionada = [];
                if (document.getElementById('checkbox-ml-1').checked) {
                    mlSelecionada.push(1)
                }
                if (document.getElementById('checkbox-ml-2').checked) {
                    mlSelecionada.push(2)
                }

                let typeCnpj = document.getElementById('tipo-ltda').checked ? 2 : 1;

                let formData = {
                    ml: mlSelecionada.join(','),
                    nome: this.name,
                    tipo: typeCnpj,
                    cor: this.selectedColor,
                    cnpj: this.cnpj,
                    nire: this.stateRegistration,
                    endereco: this.address,
                    email: this.email,
                    limite1: 1,
                    limite2: this.limitEntries,
                    limite3: this.limitExits
                }

                cnpjService
                    .createCnpj(formData)
                    .then(() => {
                        if (this.$route.query.cnpj != null || this.$route.query.addCnpj != null) {
                            setTimeout(() => {
                                load.hide()
                                /* redirecionar  para o painel */
                                this.$router.push({
                                    name: "home.dashboard",
                                });
                                return;
                            }, 800);
                        } else {
                            this.getCnpjs()
                            load.hide()
                            this.isVisible = false
                            swal({
                                title: "CNPJ cadastrado com sucesso",
                                icon: "success",
                                button: "OK",
                            });
                        }

                    }).catch((error) => {
                        swal({
                            title: `Ouve um erro na solicitação ${error}`,
                            icon: "warning",
                            button: "Fechar",
                            dangerMode: true,
                        });
                        load.hide()
                    })

            },
            isNotLtda() {
                let id = this.$route.query.cnpj
                if (id != 1 && id != 2)
                    return true;

                return false
            },
            resetFormCnpj() {
                this.name = ''
                this.selectedColor = '#8f00ff'
                this.cnpj = ''
                this.stateRegistration = ''
                this.address = ''
                this.email = ''
                this.transactionsLimited = 0
                this.limitEntries = 0
                this.limitExits = 0
                this.formData.ml = 0
                this.idCnpjEdit = null
                this.typeCnpj = null
                this.dataCnpjEdit = {}
            },
            reloadOrBackPage() {

                /* redirecionar  para o painel */
                if (this.$route.query.cnpj != null || this.$route.query.addCnpj != null) {
                    this.$router.push({
                        name: "home.dashboard",
                    });
                    return;
                }

                this.resetFormCnpj()
                this.editCnpj = false
                this.isVisible = !this.isVisible
                this.getCnpjs();
            }
        },

        mounted() {
            this.getCnpjs();

            // Editar cnpjs se for passado o parametro
            if (this.$route.query.cnpj) {
                this.handleEditCnpj({
                    id: this.$route.query.cnpj
                })
            }

            // Abrir compos para cadastrar novo cnpj
            if (this.$route.query.addCnpj) {
                this.showHide()
                this.formData.ml = this.$route.query.ml
            }
        },
        metaInfo() {
            return {
                title: `Ajustes - ${this.$config.appName}`
            }
        },
    };
</script>

<style>
    .tab-bar {
        border: none;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .tab-item {
        position: relative;
        padding: 10px;
        cursor: pointer;
        color: #333;
    }

    .tab-item.active {
        color: #29CC7A;
    }

    .tab-item:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 2px;
        background-color: #29CC7A;
        transition: all 0.3s ease;
    }

    .tab-item:hover:before,
    .tab-item.active:before {
        width: 100%;
    }

    /* checkbox custom */
    .form-check-custom .form-check-input {
        background-color: #475266 !important;
        border-color: #475266 !important;
        border-radius: 0;
    }

    .form-check-custom .form-check-input:checked {
        background-color: #1ca761 !important;
        border-color: #1ca761 !important;
    }

    .form-check-custom .form-check-input:focus {
        border-color: #1ca761 !important;
        box-shadow: none
    }

    .form-check-custom .form-check-input[type=radio] {
        border-radius: 50% !important;
    }

    .label-select {
        transition: all .2s;
        position: relative;
    }

    .label-select:hover {
        cursor: pointer;
        opacity: .8;
    }

    .icon-check {
        position: absolute;
        bottom: 8px;
        right: 8px;
        color: #1ca761;
        font-size: 1.3em;
    }

    html,
    body {
        overflow-x: hidden;
    }

    .text-gray-cnpj {
        color: #B8BECC;
    }
</style>