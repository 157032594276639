<template>
    <div>
        <div class="container-dashboard">
            <div class="container-fluid pb-5">
                <div class="row justify-content-center align-items-center">
                    <div class="mt-5">
                        <form method="post" action="#" @submit.prevent="sendForm">
                            <div class="container text-center">
                                <div class="container-sm mt-5">
                                    <div class="row mt-4 justify-content-center align-items-center">
                                        <div class="input-group justify-content-center align-items-center">
                                            <!--Selecionar CNPJ-->
                                            <div class="col-md-5 form-check-custom">
                                                <select
                                                    class=" form-select-custom card border-success form-select form-select-lg"
                                                    style="background-color: #363D4C; color: white;"
                                                    v-model="formData.cnpj" required>
                                                    <option v-for="(item, index) in accounts" :key="index"
                                                        :value="item.cnpj.replace(/-/gi, '').replace(/\./gi, '').replace(/\//gi, '')">
                                                        <span v-if="item.id == 1 || item.id == 2"> LTDA </span>
                                                        <span v-else> MEI </span>
                                                        {{item.id}}: {{item.cnpj}}
                                                    </option>
                                                </select>
                                                <!-- Selecionar conta Mercado Livre -->
                                                <div class="form-check form-check-inline mt-4">
                                                    <input class="form-check-input" type="radio" name="ml" value="1"
                                                        id="checkbox1" @change="setMlSelected($event)"
                                                        :checked="formData.ml == 1 ? true : false">

                                                    <label class="form-check-label" for="checkbox1">Conta ML 1</label>
                                                </div>
                                                <div class="form-check form-check-inline mt-4">
                                                    <input class="form-check-input" type="radio" name="ml" value="2"
                                                        id="checkbox2" @change="setMlSelected($event)"
                                                        :checked="formData.ml == 2 ? true : false">
                                                    <label class="form-check-label" for="checkbox2">Conta ML 2</label>

                                                </div>


                                            </div>
                                        </div>
                                        <!-- Adicionar planilha ml -->
                                        <div class="col-md-3 mt-5 m-1" style="width: 18rem;">
                                            <label for="planilha-ml" class="label-select">
                                                <div class="card border-success card-sm"
                                                    style=" background-color: #363D4D; border-style: dashed; border-width: 2px">
                                                    <div class="card-body">
                                                        <div class="text-center mt-3 mb-4">
                                                            <img src="@/assets/img/icons/nfe-icon.svg" alt="Zinzer" />
                                                        </div>
                                                        <p class="card-text mt-2 mb-4 fw-normal .text-light">
                                                            Adicionar planilha do Mercado Livre
                                                        </p>
                                                    </div>
                                                </div>
                                                <span v-show="selectedMlNotes">
                                                    <i class="fa-regular fa-circle-check icon-check"></i>
                                                </span>
                                            </label>
                                            <input type="file" id="planilha-ml" class="visually-hidden"
                                                accept=".xlsx, .xls, .csv, .xml"
                                                @change="selectedMlNotes = true; convertToBase64($event)" required />
                                        </div>

                                        <!-- Adicionar notas blink -->
                                        <div class="col-md-3 mt-5 m-1" style="width: 18rem;">
                                            <label for="notas-bling" class="label-select">
                                                <div class="card border-success card-sm"
                                                    style=" background-color: #363D4D; border-style: dashed; border-width: 2px">
                                                    <div class="card-body">
                                                        <div class="text-center mt-3 mb-4">
                                                            <img src="@/assets/img/icons/nfe-icon.svg" alt="Zinzer" />
                                                        </div>
                                                        <p class="card-text mt-2 mb-4 fw-normal .text-light">
                                                            Adicionar notas fiscais do Bling
                                                        </p>
                                                    </div>
                                                </div>

                                                <span v-show="selectedBlingNotes">
                                                    <span
                                                        class="d-flex gap-1 justify-content-end align-items-center icon-check">
                                                        <i class="fa-regular fa-circle-check "></i>
                                                        <span>{{ formData.xmls.length }}</span>
                                                    </span>
                                                </span>

                                            </label>
                                            <input type="file" id="notas-bling" class="visually-hidden"
                                                accept=".xlsx, .xls, .csv, .xml" multiple="multiple"
                                                @change="selectedBlingNotes = true; convertToBase64Mult($event)"
                                                required>
                                        </div>

                                    </div>
                                    <div class="text-center">
                                        <img src="@/assets/img/icons/indicator.svg" alt="Zinzer" />
                                    </div>
                                    <!--Gerar nota fiscal-->
                                    <button type="submit" class="mt-4  btn btn-green btn-lg btn-primary w-50">
                                        Gerar NFs
                                    </button>
                                    <div class="mt-3">

                                        <p class="card-text mt-2 fw-normal .text-light" style="color: #6C7380;">Você
                                            pode
                                            encontrar todas as
                                            notas geradas no histórico.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <ModalGenerateInvoiceData :data-success="dataSuccess" ref="modalgenerate" />
    </div>
</template>
<script>
    import ModalGenerateInvoiceData from '@/components/ModalGenerateInvoiceData';
    import alertService from '@/services/AlertService.js';
    import cnpjService from '@/services/CnpjService';
    import generateInvoiceService from '@/services/GenerateInvoiceService';

    export default {
        name: "GenerateInvoice",
        components: {
            ModalGenerateInvoiceData
        },
        data() {
            return {
                dataSuccess: {},
                selectedBlingNotes: false,
                selectedMlNotes: false,
                accounts: {},
                formData: {
                    // mei: '',
                    cnpj: '',
                    ml: 0,
                    planilha: '',
                    // notas: []
                    xmls: []
                }
            }
        },
        methods: {
            async getAccounts() {
                let load = this.$loading.show();
                await cnpjService.getAllCnpj()
                    .then(res => {
                        this.accounts = res.data.cnpjs
                        load.hide()
                    }).catch(err => {
                        console.log(err.response);
                        load.hide()
                    })
            },

            setMlSelected(event) {
                if (event.target.checked) {
                    this.formData.ml = event.target.value
                } else {
                    this.formData.ml = ''
                }
            },

            convertToBase64(event) {
                // Verifica se algum arquivo foi selecionado
                if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    const reader = new FileReader();

                    reader.onload = () => {
                        const base64 = reader.result.split(',')[1];
                        this.formData.planilha = base64
                    };

                    reader.onerror = (error) => {
                        console.error("Erro ao converter o arquivo para Base64:", error);
                    };

                    reader.readAsDataURL(file);
                }
            },

            convertToBase64Mult(event) {
                // Verifica se algum arquivo foi selecionado
                if (event.target.files.length > 0) {
                    const files = event.target.files;
                    this.formData.xmls = []

                    // Itera sobre cada arquivo selecionado e converte em Base64
                    for (let i = 0; i < files.length; i++) {
                        const reader = new FileReader();

                        reader.onload = () => {
                            const base64 = reader.result.split(',')[1];
                            // Adiciona a string Base64 ao array
                            this.formData.xmls.push(base64);
                        };

                        reader.onerror = (error) => {
                            console.error("Erro ao converter o arquivo para Base64:", error);
                        };

                        reader.readAsDataURL(files[i]);
                    }
                }
            },

            sendForm() {

                // se ml não foi selecionado
                if (this.formData.ml == 0) {
                    alertService.error('Seleciona uma conta ML')
                    return
                }

                let load = this.$loading.show();

                generateInvoiceService.sendNote(this.formData)
                    .then(res => {
                        load.hide()
                        this.dataSuccess = res.data

                        console.log(res.data);

                        if (typeof res.data !== 'object' && res.data !== null) {
                            let msg = `Ação realizada com sucesso, mas ouve um erro. ${res.data.substr(0, 100)}...`
                            alertService.error(msg)
                        }

                        // show modal
                        this.$refs.modalgenerate.showModal()
                    }).catch(err => {
                        load.hide()
                        alertService.error(`Ouve um erro na solicitação. ${err}`)
                    })
            },
        },
        mounted() {
            this.getAccounts()
        },
        metaInfo() {
            return {
                title: `Gerar NF - ${this.$config.appName}`
            }
        },
    };
</script>

<style scoped>
    /* checkbox custom */
    .form-check-custom .form-check-input {
        background-color: #475266;
        border-color: #475266;
        border-radius: 50% !important;
    }

    .form-check-custom .form-check-input:checked {
        background-color: #1ca761;
        border-color: #1ca761;
    }

    .form-check-custom .form-check-input:focus {
        border-color: #1ca761;
        box-shadow: none
    }

    .label-select {
        transition: all .2s;
        position: relative;
    }

    .label-select:hover {
        cursor: pointer;
        opacity: .8;
    }

    .icon-check {
        position: absolute;
        bottom: 8px;
        right: 8px;
        color: #1ca761;
        font-size: 1.3em;
    }
</style>