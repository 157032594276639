<template>
    <div class="container-dashboard">
        <div class="graficos">
            <div class="container-fluid text-white py-4  px-lg-4">

                <!-- Topo -->
                <div class="d-flex flex-column flex-lg-row gap-3">
                    <!-- Mostar -->
                    <div class="">
                        <div class="dropdown">
                            <button class="btn btn-dark-zinzer rounded-2 px-3 shadow" type="button" id="drop-mostrar"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                <span v-if="filter.cnpj == null"> Mostrando: Todos </span>
                                <span v-else>MEI {{ filter.mei }}: {{filter.cnpj}} </span>
                                <img src="@/assets/img/icons/arrow-down.svg" alt="" width="14" class="ms-1">
                            </button>
                            <div class="dropdown-menu" aria-labelledby="drop-mostrar text-white" style="">
                                <a :class="`dropdown-item dropdown-item-menu ${filter.mei == null ? 'active' : ''} `"
                                    type="button" @click="setFilterMei(null)">
                                    Mostrando: Todos
                                </a>
                                <a :class="`dropdown-item dropdown-item-menu ${filter.mei == item.id ? 'active' : ''} `"
                                    type="button" v-for="(item, index) in dataMeis" :key="index"
                                    @click="setFilterMei(item)">
                                    <span v-if="item.id < 3">LTDA</span>
                                    <span v-else>MEI</span>
                                    {{ item.id }}: {{item.cnpj}}
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- Datas -->
                    <div class="ms-lg-auto d-flex flex-column flex-lg-row gap-3">

                        <!-- Data início -->
                        <div class="">
                            <div class="dropdown">
                                <button type="button"
                                    class="btn btn-primary btn-dark-zinzer-2 btn-shadow d-flex align-items-center gap-1"
                                    id="data-inicio" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <img src="@/assets/img/icons/calendar.svg" alt="" width="18" class="">
                                    Início
                                    <span class="text-green">
                                        {{filter.dateStartText}}
                                    </span>
                                </button>
                                <div class="dropdown-menu shadow-lg text-white " aria-labelledby="data-inicio"
                                    style=" background: #242933; border: 1px solid rgba(255, 255, 255, 0.2); min-width: 230px; ">
                                    <div class="px-3 py-3">
                                        <!-- Data -->
                                        <div class="">
                                            <input type="date"
                                                class="form-control form-control-sm border-success form-select-sm"
                                                name="data" placeholder="dd/mm/YY" required
                                                style="background-color: #363d4c; color: white"
                                                v-model="filter.dateStart" />
                                            <button class="btn btn-green btn-sm col-md-12 mt-3" @click="setDateStart">
                                                Aplicar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Data Fim -->
                        <div class="">
                            <div class="dropdown">
                                <button type="button"
                                    class="btn btn-primary btn-dark-zinzer-2 btn-shadow d-flex align-items-center gap-1"
                                    id="data-fim" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="@/assets/img/icons/calendar.svg" alt="" width="18" class="">
                                    Fim
                                    <span class="text-green">
                                        {{filter.dateEndText}}
                                    </span>
                                </button>
                                <div class="dropdown-menu shadow-lg text-white " aria-labelledby="data-fim"
                                    style=" background: #242933; border: 1px solid rgba(255, 255, 255, 0.2); min-width: 230px; ">
                                    <div class="px-3 py-3">
                                        <!-- Data -->
                                        <div class="">
                                            <input type="date"
                                                class="form-control form-control-sm border-success form-select-sm"
                                                name="data" placeholder="dd/mm/YY" required
                                                style="background-color: #363d4c; color: white"
                                                v-model="filter.dateEnd" />
                                            <button class="btn btn-green btn-sm col-md-12 mt-3" @click="setDateEnd">
                                                Aplicar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Conteúdo -->
                <div class="row mt-3 gy-4 pb-5">
                    <!-- faturamento e vandas -->
                    <div class="col-12 col-lg-8">
                        <BillingAndSales :data-graphics="dataGraphics" />
                    </div>
                    <!-- útlimos 7 dias -->
                    <div class="col-12 col-lg-4">
                        <Chart7Days :weekly-data="dataGraphics.semanal" />
                    </div>
                    <!-- gráficos totais de entradas e saídas -->
                    <div class="col-12 col-lg-7">
                        <div class="row gy-4">
                            <div class="col-12">
                                <TotalEntries :data-graphics="dataGraphics" />
                            </div>
                            <div class="col-12">
                                <TotalOutputs :data-graphics="dataGraphics" />
                            </div>
                        </div>
                    </div>
                    <!-- 50 vendas pessoas físicas e 24 jurídicas -->
                    <div class="col-12 col-lg-5">
                        <SalesPeople :text-filter-days="textFilterDays" :show-graphics-filters="showGraphicsFilters"
                            :data-graphics="dataGraphics" />
                    </div>
                    <div class="col-12 col-lg-7">
                        <div class="row gy-4">
                            <!-- Top 10 de produtos -->
                            <div class="col-12">
                                <Top10Product :show-graphics-filters="showGraphicsFilters"
                                    :data-graphics="dataGraphics" />
                            </div>
                            <!-- estados que mais compram -->
                            <div class="col-12">
                                <StatesBuyMost :data-graphics="dataGraphics" />
                            </div>
                        </div>
                    </div>
                    <!-- Impostos a pagar  -->
                    <div class="col-12 col-lg-5">
                        <div class="row gy-4">
                            <div class="col-12">
                                <TaxesPayLtda1 :data-graphics="dataGraphics" />
                            </div>
                            <div class="col-12">
                                <TaxesPayLtda2 :data-graphics="dataGraphics" />
                            </div>
                        </div>
                    </div>
                    <!-- Gráficos mei -->
                    <div class="col-12" v-if="showDataMeis">
                        <div class="" v-for="(item, index) in dataGraphicsMei" :key="index">
                            <div class="py-2 mb-1" v-if="index > 0"></div>
                            <h2 class="fs-5 pt-2 pb-2">
                                Gráficos MEI {{ item.id }} • <span class="fw-normal">{{item.cnpj}}</span>
                            </h2>
                            <div class="row gy-4">
                                <!-- Entradas totais -->
                                <div class="col-12 col-lg-7">
                                    <ChartMeiTotalEntries :data-mei-home="item" />
                                </div>
                                <div class="col-12 col-lg-5">
                                    <ChartsMeiAmount :data-mei-home="item" :id-mei="index" />
                                </div>
                                <!-- Saíds totais -->
                                <div class="col-12 col-lg-7">
                                    <ChartMeiTotalOutputs :data-mei-home="item" />
                                </div>
                                <div class="col-12 col-lg-5">
                                    <ChartsMeiAmountOutputs :data-mei-home="item" :id-mei="index" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Load -->
                    <div class="col-12" v-else>
                        <div class="py-5">
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border text-secondary" style="width: 1rem; height: 1rem;"
                                    role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import BillingAndSales from "@/components/Graphics/BillingAndSales";
    import Chart7Days from "@/components/Graphics/Chart7Days";
    import TotalEntries from "@/components/Graphics/TotalEntries";
    import TotalOutputs from "@/components/Graphics/TotalOutputs";
    import SalesPeople from "@/components/Graphics/SalesPeople";
    import Top10Product from "@/components/Graphics/Top10Product";
    import TaxesPayLtda1 from "@/components/Graphics/TaxesPayLtda1";
    import TaxesPayLtda2 from "@/components/Graphics/TaxesPayLtda2";
    import StatesBuyMost from "@/components/Graphics/StatesBuyMost";
    import ChartMeiTotalEntries from "@/components/Graphics/ChartMeiTotalEntries";
    import ChartsMeiAmount from "@/components/Graphics/ChartsMeiAmount";
    import ChartMeiTotalOutputs from "@/components/Graphics/ChartMeiTotalOutputs";
    import ChartsMeiAmountOutputs from "@/components/Graphics/ChartsMeiAmountOutputs";
    import graphicsService from '@/services/GraphicsService'

    export default {
        name: 'GraphicsPage',
        data() {
            return {
                textFilterDays: 'Nos últimos 30 dias',
                showGraphicsFilters: true,
                dataMeis: [],
                dataGraphics: {},
                dataGraphicsMei: [],
                showDataMeis: false,
                filter: {
                    dateStartText: '???',
                    dateEndText: '???',
                    dateStart: '',
                    dateEnd: '',
                    mei: null,
                    cnpj: null
                }
            }
        },
        components: {
            BillingAndSales,
            Chart7Days,
            TotalEntries,
            TotalOutputs,
            SalesPeople,
            Top10Product,
            TaxesPayLtda1,
            TaxesPayLtda2,
            StatesBuyMost,
            ChartMeiTotalEntries,
            ChartsMeiAmount,
            ChartMeiTotalOutputs,
            ChartsMeiAmountOutputs,
        },
        methods: {
            currentDate() {
                const date = new Date();
                const dateStr = date.toLocaleDateString().split('/').reverse().join('-')
                const fullDate = `${dateStr}`
                return fullDate;
            },
            setDateStart() {
                let dateStart = this.filter.dateStart + ' 00:00'

                if (!this.filter.dateStart) {
                    this.filter.dateStartText = '???'
                    return
                }

                const date = new Date(dateStart)
                const day = date.getDate().toString().padStart(2, '0')
                let month = (date.getMonth() + 1)
                if (month < 10)
                    month = '0' + month
                const year = date.getFullYear().toString()
                this.filter.dateStartText = `${day}/${month}/${year}`

                this.filterData()
            },
            setDateEnd() {
                let dateEnd = this.filter.dateEnd + ' 00:00'

                if (!this.filter.dateEnd) {
                    this.filter.dateEndText = '???'
                    return
                }

                let date = new Date(dateEnd)
                let day = date.getDate().toString().padEnd(2, '0')
                let month = (date.getMonth() + 1)
                if (month < 10)
                    month = '0' + month
                let year = date.getFullYear().toString()
                this.filter.dateEndText = `${day}/${month}/${year}`

                this.filterData()
            },
            async getData() {

                let date = new Date()
                let fim = date.toLocaleDateString().split('/').reverse().join('-')

                let inicio = date.setMonth(date.getMonth() - 1)
                inicio = (new Date(inicio)).toLocaleDateString().split('/').reverse().join('-')

                let formData = {
                    'inicio': inicio,
                    'fim': fim,
                }
                await graphicsService.getData(formData)
                    .then(res => {
                        this.dataGraphics = res.data
                        this.getDataMeis()
                        this.setDataGraphicsMei()
                    }).catch(err => {
                        console.log(err.response);
                    })

            },
            setDataGraphicsMei() {
                this.dataGraphicsMei = this.dataGraphics.meis
                this.showDataMeis = true
            },
            getDataMeis() {
                this.dataMeis = this.dataGraphics.meis
            },
            setFilterMei(item) {

                if (item == null) {
                    this.filter.mei = null
                    this.filter.cnpj = null
                } else {
                    this.filter.mei = item.id
                    this.filter.cnpj = item.cnpj
                }

                this.showGraphicsFilters = false
                this.filterData()
            },
            filterData() {
                this.showGraphicsFilters = false

                let dateStart = this.filter.dateStart
                if (dateStart == '') {
                    let date = new Date();
                    date.setDate(date.getDate() - 30);
                    dateStart = date.toLocaleDateString().split('/').reverse().join('-')
                    this.filter.dateStart = dateStart
                }
                let dateEnd = this.filter.dateEnd
                if (dateEnd == '') {
                    let date = new Date();
                    dateEnd = date.toLocaleDateString().split('/').reverse().join('-')
                    this.filter.dateEnd = dateEnd
                }

                let formDataFilter = {}
                if (this.filter.cnpj == null) {
                    formDataFilter = {
                        inicio: dateStart,
                        fim: dateEnd,
                    }
                } else {
                    formDataFilter = {
                        inicio: dateStart,
                        fim: dateEnd,
                        cnpj: this.filter.cnpj.replace(/-/gi, '').replace(/\//gi, '').replace(/\./gi, '')
                    }
                }

                this.setTextFilterDays()

                graphicsService.filterDataGraphics(formDataFilter)
                    .then(res => {
                        this.dataGraphics = res.data
                        this.showGraphicsFilters = true
                    }).catch(err => {
                        console.log(err.response);
                    })
            },
            setTextFilterDays() {
                this.textFilterDays =
                    `De ${this.filter.dateStart.split('-').reverse().join('/')} a ${this.filter.dateEnd.split('-').reverse().join('/')}`
            },
            dataSub30days() {
                let date = new Date();
                date.setMonth(date.getMonth() - 1)
                let dateStr = date.toLocaleDateString().split('/').reverse().join('-')
                let fullDate = `${dateStr}`
                return fullDate;
            }
        },
        mounted() {
            this.filter.dateStart = this.dataSub30days()
            this.getData()

            this.filter.dateStartText = this.dataSub30days().split('-').reverse().join('/')
            this.filter.dateEndText = this.currentDate().split('-').reverse().join('/')
        },
        metaInfo() {
            return {
                title: `Gráficos - ${this.$config.appName}`
            }
        },
    }
</script>
<style scoped>
    .dropdown-item-menu.dropdown-item {
        background: #1e222b;
        color: white;
        font-size: 14px;
    }

    .dropdown-item-menu.dropdown-item:hover,
    .dropdown-item-menu.dropdown-item.active {
        background: #333a48;
    }

    .dropdown-menu {
        background: #1e222b;
        border: 1px solid rgba(255, 255, 255, .15);
    }
</style>