import api from './ApiService';

export default {
    getCnpj(id) {
        return api.get(`/cnpj/${id}`)
    },
    getAllCnpj() {
        return api.get('/cnpj')
    },
    createCnpj(formData) {
        return api.post('/cnpj', formData)
    },
    updateCnpj(id, formData) {
        return api.put(`/cnpj/${id}`, formData)
    },
    deleteCnpj(id) {
        return api.delete(`cnpj/${id}`)
    }
}
