import swal from 'sweetalert';

export default {
    success(msg) {
        swal({
            title: msg,
            icon: "success",
            button: "OK",
        });
    },
    error(msg) {
        swal({
            title: msg,
            icon: "warning",
            button: "Fechar",
            dangerMode: true,
        });
    },
}