<template>
    <div class="container-dashboard">
        <div class="px-4 py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-5 mx-auto">
                        <div class="rounded-2 p-4 " style="background-color: #2D3340;">
                            <div class="d-flex pt-3 gap-4 ">
                                <div class="">
                                    <!-- Nome -->
                                    <div class="mb-4 pb-1">
                                        <div class="opacity-75 fw-400 mb-1 text-uppercase fs-12px">Nome</div>
                                        <div class="h4" v-if="user.nome">
                                            {{this.limitStr(user.nome, 30)}}
                                            <a href="#" class="" data-bs-toggle="modal"
                                                data-bs-target="#modal-edit-name">
                                                <img src="@/assets/img/icons/edit-icon.svg" alt="" width="20"
                                                    class="ms-2">
                                            </a>
                                        </div>
                                    </div>
                                    <!-- E-mail/Usuário -->
                                    <div class="mb-4 pb-1">
                                        <div class="opacity-75 fw-400 mb-1 text-uppercase fs-12px">E-mail/Usuário</div>
                                        <div class="h4 " v-if="user.email">
                                            {{ this.limitStr(user.email, 30) }}
                                            <a href="#" class="" data-bs-toggle="modal"
                                                data-bs-target="#modal-edit-email">
                                                <img src="@/assets/img/icons/edit-icon.svg" alt="" width="20"
                                                    class="ms-2">
                                            </a>
                                        </div>
                                    </div>
                                    <!-- Senha -->
                                    <div class="">
                                        <div class="opacity-75 fw-400 mb-1 text-uppercase fs-12px">Senha</div>
                                        <div class="h4">
                                            **********
                                            <a href="#" class="" data-bs-toggle="modal"
                                                data-bs-target="#modal-edit-senha">
                                                <img src="@/assets/img/icons/edit-icon.svg" alt="" width="20"
                                                    class="ms-2">
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-12 pt-2 pb-2 text-end ">
                                <button type="button" class="btn btn-danger rounded-1" data-bs-toggle="modal"
                                    data-bs-target="#modal-delete-user">Deletar Conta</button>
                            </div>
                        </div>

                        <div class="mt-5 text-end ">
                            <router-link :to="{name: 'home.createnewadministrator'}" class="text-light rounded-1">
                                Adicionar Usuário
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalEditName :user="user" />
        <ModalEditEmail :user="user" />
        <ModalEditPassword :user="user" />
        <ModalDeleteUser :user="user" />
    </div>
</template>
<script>
    import ModalEditName from '@/components/profile/ModalEditName'
    import ModalEditEmail from '@/components/profile/ModalEditEmail'
    import ModalEditPassword from '@/components/profile/ModalEditPassword'
    import ModalDeleteUser from '@/components/profile/ModalDeleteUser'
    import userService from '@/services/UserService'
    export default {
        name: 'UserProfile',
        data() {
            return {
                user: {}
            }
        },
        components: {
            ModalEditName,
            ModalEditEmail,
            ModalEditPassword,
            ModalDeleteUser
        },
        methods: {
            limitStr(text, totalStr = 1) {
                if (text != '' && text.length > totalStr)
                    text = text.substr(0, totalStr) + '...'
                return text;
            },
        },
        mounted() {
            userService
                .getLoggedUser(localStorage.getItem('id'))
                .then(res => {
                    this.user = res.data.user
                })
        },
        metaInfo() {
            return {
                title: `Perfil - ${this.$config.appName}`
            }
        },
    }
</script>
<style scoped>
    .btn-mudar-foto {
        position: absolute;
        top: 5px;
        right: 5px;
        opacity: .7;
    }
</style>