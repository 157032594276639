<template lang="">

    <div>
        <!-- Modal Body -->
        <div class="modal fade" :id="`modal-ltda-${idBox}`" tabindex="-1" data-bs-backdrop="static"
            data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-body px-4 py-4">

                        <button type="button" class="btn btn-none btn-x-close" data-bs-dismiss="modal"
                            aria-label="Close">
                            <img src="@/assets/img/icons/x.svg" alt="" width="20">
                        </button>

                        <!-- Entradas -->
                        <div class="" v-if="type == 'entradas'">
                            <div class="text-center fs-14px pt-4 mt-2">
                                <div class="fw-bold fs-16px">Editar Limite de Entrada</div>
                            </div>
                            <div class="mt-3 pt-2 form-check-custom">

                                <div class="row">
                                    <div class="col-12">

                                        <div class="fw-normal text-center fs-16px mb-3">
                                            LTDA: {{dataLtda.cnpj | formatCnpj}}
                                        </div>

                                        <div class="">
                                            <label class="fw-bold mb-1 fs-16px">Limite</label>
                                            <money v-model="limite2" v-bind="money"
                                                class="form-control text-white bg-dark  rounded-0"
                                                style="border-color: #1ca761" ref="limite2"></money>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3 pt-3 text-center">
                                <div class="">
                                    <button type="button" class="btn btn-green  btn-sm px-4 fs-14px" @click="update">
                                        <div class="px-3">
                                            Atualizar
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Saídas -->
                        <div class="" v-if="type == 'saidas'">
                            <div class="text-center fs-14px pt-4 mt-2">
                                <div class="fw-bold fs-16px">Editar Limite de Saída</div>
                            </div>
                            <div class="mt-3 pt-2 form-check-custom">

                                <div class="row">
                                    <div class="col-12">

                                        <div class="fw-normal text-center fs-16px mb-3">
                                            LTDA: {{dataLtda.cnpj | formatCnpj}}
                                        </div>

                                        <div class="">
                                            <label class="fw-bold mb-1 fs-16px">Limite</label>
                                            <money v-model="limite3" v-bind="money"
                                                class="form-control text-white bg-dark  rounded-0"
                                                style="border-color: #1ca761" ref="limite3"></money>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3 pt-3 text-center">
                                <div class="">
                                    <button type="button" class="btn btn-green  btn-sm px-4 fs-14px" @click="update">
                                        <div class="px-3">
                                            Atualizar
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import swal from 'sweetalert';
    import cnpjService from "@/services/CnpjService";
    import {
        Modal
    } from "bootstrap";
    import {
        Money
    } from 'v-money'

    export default {
        name: 'ModalEditLimiteLtda',
        data() {
            return {
                mod: true,
                mod2: true,
                type: '',
                limite2: 0,
                limite3: 0,
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$ ',
                    suffix: '',
                    precision: 2,
                    masked: false
                }
            }
        },
        components: {
            Money
        },
        props: ['dataLtda', 'idBox'],
        methods: {
            showModal(type) {
                this.type = type
                const modal = new Modal(document.getElementById(`modal-ltda-${this.idBox}`))
                modal.show()
            },
            update() {
                if (this.type == 'entradas') {
                    this.updateInput()
                } else {
                    this.updateOutput()
                }
            },

            updateInput() {
                let data = {
                    limite2: this.limite2
                }
                let load = this.$loading.show();
                cnpjService
                    .updateCnpj(this.dataLtda.id, data)
                    // eslint-disable-next-line
                    .then(res => {
                        swal({
                            title: "Limite editado com sucesso",
                            icon: "success",
                            button: "OK",
                        });
                        load.hide()
                        setTimeout(() => {
                            window.location.reload();
                        }, 800);
                    }).catch(err => {
                        load.hide()
                        swal({
                            title: err.response.data.message,
                            icon: "warning",
                            button: "Fechar",
                            dangerMode: true,
                        });
                    })
            },

            updateOutput() {
                let data = {
                    limite3: this.limite3
                }

                let load = this.$loading.show();
                cnpjService
                    .updateCnpj(this.dataLtda.id, data)
                    // eslint-disable-next-line
                    .then(res => {
                        swal({
                            title: "Limite editado com sucesso",
                            icon: "success",
                            button: "OK",
                        });
                        load.hide()
                        setTimeout(() => {
                            window.location.reload();
                        }, 800);
                    }).catch(err => {
                        load.hide()
                        swal({
                            title: err.response.data.message,
                            icon: "warning",
                            button: "Fechar",
                            dangerMode: true,
                        });
                    })
            },
        },
        beforeUpdate() {
            if (this.limite2 == 0 && this.dataLtda.limiteEntrada && this.mod) {
                this.limite2 = Number(this.dataLtda.limiteEntrada).toFixed(2)
                this.mod = false
            }
            if (this.limite3 == 0 && this.dataLtda.limiteSaida && this.mod2) {
                this.limite3 = Number(this.dataLtda.limiteSaida).toFixed(2)
                this.mod2 = false
            }
        },
    }
</script>
<style scoped>
    .modal .modal-dialog .modal-content {
        background-color: #363d4c;
        border-color: #475266;
        color: white
    }

    .btn-x-close {
        position: absolute;
        top: 8px;
        right: 0px
    }

    @media(min-width: 992px) {
        .modal-dialog {
            min-width: 350px;
        }
    }

    /* checkbox custom */
    .form-check-custom .form-check-input {
        background-color: #475266;
        border-color: #475266;
        border-radius: 0;
    }

    .form-check-custom .form-check-input:checked {
        background-color: #1ca761;
        border-color: #1ca761;
    }

    .form-check-custom .form-check-input:focus {
        border-color: #1ca761;
        box-shadow: none
    }

    .btn-cancelar {
        background: #475266;
        border-color: #475266
    }

    .btn-cancelar:hover {
        background: #54627c;
        border-color: #54627c
    }
</style>