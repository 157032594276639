<template>
    <div class="box-coluna">
        <div class="row p-3 gap-0">

            <div class="col-12">
                <div class="d-flex flex-column flex-lg-row justify-content-lg-between ">
                    <h2 class="h5 fs-5">Entrados totais</h2>
                </div>
            </div>

            <!-- Gráfico entradas -->
            <div class="col-9 text-dark  py-0 divisor-top ">
                <div v-if="showChartMonths" class="w-100 divisor-right pt-2"
                    style="margin-left: -10px; margin-bottom: -20px;">
                    <apexchart width="100%" height="140" type="area" :options="chartMonths.chartOptions"
                        :series="chartMonths.chartSeries" />
                </div>
            </div>
            <!-- Gráfico total -->
            <div class="col-3 divisor-top p-0 d-flex justify-content-center">
                <div v-if="showChartAmount" class="" style="margin-left: -20px; margin-bottom: -20px;margin-top: -10px">
                    <apexchart type="radialBar" :options="chartAmount.chartOptions" :series="chartAmount.series"
                        width="190" height="190" />
                    <div class="text-center fs-13px fw-semibold text-uppercase pb-3" style="margin-top: -20px">
                        Total
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import apexchart from 'vue-apexcharts'
    export default {
        name: 'ChartMeiTotalEntries',
        components: {
            apexchart
        },
        data() {
            return {
                showChartMonths: false,
                showChartAmount: false,
                chartMonths: {
                    chartOptions: {
                        show: true,
                        borderColor: '#fff',
                        chart: {
                            id: 'vuechart-example',
                            curve: 'smooth',
                            toolbar: {
                                show: false
                            },
                        },
                        tooltip: {
                            marker: {
                                fillColors: ['#5985EE']
                            },
                            y: {
                                formatter: function (val) {
                                    if (val >= 1000) {
                                        let numeroAbreviado = (val / 1000).toFixed(0);
                                        numeroAbreviado += "K";
                                        return numeroAbreviado;
                                    } else {
                                        return val.toFixed(0);
                                    }
                                }
                            }
                        },
                        fill: {
                            colors: ['#5985EE', '#E91E63', '#9C27B0'],
                            opacity: 1,
                            gradient: {
                                shade: 'dark',
                                type: "vertical",
                                shadeIntensity: 0.5,
                                gradientToColors: undefined,
                                inverseColors: false,
                                opacityFrom: 1,
                                opacityTo: '0.8',
                                colorStops: []
                            },
                        },
                        markers: {
                            // xxxxxxxxxx line wave
                            size: 3,
                            strokeColors: '#fff',
                            colors: ['#fff'],
                            hover: {
                                size: 3
                            }
                        },
                        stroke: {
                            // xxxxxxxxxx
                            curve: 'straight',
                            colors: ['#5985EE'],
                            width: 2,
                        },
                        xaxis: {
                            categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov',
                                'Dez'
                            ],
                            labels: {
                                style: {
                                    colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                        '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                    ],
                                },
                            },
                            tooltip: {
                                enabled: false,
                            },
                            axisBorder: {
                                // show: false
                                color: '#475266'
                            },
                            axisTicks: {
                                color: '#475266',
                                height: 5
                            },
                        },
                        yaxis: {
                            show: true,
                            labels: {
                                show: false,
                                style: {
                                    colors: ['#fff'],
                                },
                            },
                        },
                        grid: {
                            show: true,
                            borderColor: '#1ca761',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: false,
                                    color: '#1ca761'
                                },
                            },
                            yaxis: {
                                lines: {
                                    show: false,
                                }
                            }
                        },
                        dataLabels: {
                            enabled: true, // tooltip visivel
                            // xxxxxxxxxx
                            offsetY: -7,
                            style: {
                                colors: [
                                    'rgba(0,0,0,.0)',
                                ],
                                fontSize: '11px',
                                borderColor: '#f00'
                            },
                            formatter: function (val) {
                                if (val >= 1000) {
                                    let numeroAbreviado = (val / 1000).toFixed(0);
                                    numeroAbreviado += "K";
                                    return numeroAbreviado;
                                } else {
                                    return val.toFixed(0);
                                }
                            },
                            background: {
                                enabled: true,
                                padding: 0,
                                borderRadius: 2,
                                borderWidth: 1,
                                borderColor: 'transparent',
                                opacity: 0.9,
                            },
                        },
                    },
                    chartSeries: [{
                        name: 'Entradas',
                        data: [3472, 3466, 2234, 1355, 6467, 2653, 3472, 3466, 2234, 1355, 6467, 2653],
                    }],
                },
                chartAmount: {
                    chartOptions: {
                        plotOptions: {
                            radialBar: {
                                hollow: {
                                    margin: 5,
                                    size: '65%', // mudar largura da borda
                                    borderColor: 'red'
                                },
                                track: {
                                    background: '#475266', // cor do resto do preenchimento personalizada
                                },
                                dataLabels: {
                                    showOn: 'always',
                                    name: {
                                        offsetY: -5,
                                        show: true,
                                        color: '#fff',
                                        fontSize: '17px',
                                    },
                                    value: {
                                        color: '#fff',
                                        fontSize: '17px',
                                        show: true,
                                        offsetY: -2,
                                        xxx: 2222,
                                        formatter: function (val) {
                                            return Number(val).toLocaleString('pt-br', {
                                                minimumFractionDigits: 2
                                            });
                                        }
                                    },
                                },
                            },
                        },
                        fill: {
                            colors: ['#5985EE'],
                            background: {
                                colors: ['#242933'], // cor de preenchimento de fundo personalizada,
                            },
                        },
                        labels: ['R$'],
                    },
                    series: [100], // 75 é a porcentagem
                    chartWidth: '100%',
                    chartHeight: 400,
                }
            }
        },
        props: ['dataMeiHome'],
        methods: {
            setDataChartMonths() {
                this.chartMonths.chartOptions.xaxis.categories = this.dataMeiHome.meses
                this.chartMonths.chartSeries[0].data = this.dataMeiHome.entradas
                this.showChartMonths = true
            },
            // corrigir os totais, 'totalEntrada' e 'totalSaida' depois na api
            setDataChartAmount() {
                
                // let valor = this.dataMeiHome.totalEntrada;
                let valor = this.dataMeiHome.entradas.at(-1);
                let total = this.dataMeiHome.limiteEntrada;
                let porcentagem = (valor / total) * 100;

                // porcentagem
                this.chartAmount.series[0] = porcentagem

                // valor
                this.chartAmount.chartOptions.plotOptions.radialBar.dataLabels.value.formatter =
                    function () {
                        return Number(valor).toLocaleString('pt-br', {
                            minimumFractionDigits: 2
                        });
                    }

                // cor dos gráficos em vermelhor se ultrapassar o limite
                if (valor >= total) {
                    this.chartMonths.chartOptions.fill.colors = ['#E54839']
                    this.chartAmount.chartOptions.fill.colors = ['#E54839']
                    this.chartMonths.chartOptions.tooltip.marker.fillColors[0] = ['#E54839']
                    this.chartMonths.chartOptions.stroke.colors = ['#E54839']
                }

                this.showChartAmount = true
            }
        },
        mounted() {
            this.setDataChartMonths();
            this.setDataChartAmount()
        },
    }
</script>
<style lang="">

</style>