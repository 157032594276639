import axios from 'axios';
import appConfig from '@/config/config';

axios.defaults.baseURL = appConfig.apiUrl;
const instance = axios.create();

instance.interceptors.request.use(
    (config) => {
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

instance.interceptors.response.use(
    (rs) => {
        return rs;
    },
    (err) => {
        return Promise.reject(err);
    }
);

export default instance;