<template lang="pt-br">
    <div>
        <header>
            <div class="container-navbar ">
                <div class="container-dashboard ">
                    <nav class="navbar navbar-expand-sm navbar-dark navbar-custom">
                        <div class="container-fluid px-lg-4 py-1">
                            <button class="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapsibleNavId" aria-controls="collapsibleNavId"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="collapsibleNavId">
                                <ul class="navbar-nav me-auto mt-2 mt-lg-0 gap-3">
                                    <li class="nav-item">
                                        <a class="nav-link nav-link-danger px-2 text-uppercase py-1 align-items-center d-flex gap-1"
                                            href="#" data-bs-toggle="modal" data-bs-target="#modal-delete-all">
                                            <!-- <img src="@/assets/img/icons/close-circle.svg" class="" width="20" />
                                            Deletar Tudo -->
                                            <img src="@/assets/img/icons/skull-icon-1.svg" class="" width="30" />
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'home.dashboard' }" class="nav-link px-3">
                                            <img src="@/assets/img/icons/box.svg" class="navbar-link-icon" />
                                            Painel
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{name: 'graficos'}" class="nav-link px-3">
                                            <img src="@/assets/img/icons/bar-chart-2.svg" class="navbar-link-icon" />
                                            Gráficos
                                        </router-link>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <router-link :to="{ name: 'home.createnewpurchase' }" class="nav-link px-3">
                                            <img src="@/assets/img/icons/plus-circle.svg" class="navbar-link-icon" />
                                            Adicionar compra
                                        </router-link>
                                    </li> -->
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'home.generateinvoice' }" class="nav-link px-3">
                                            <img src="@/assets/img/icons/money-nf.svg" class="navbar-link-icon" />
                                            Gerar NF
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'home.historic' }" class="nav-link px-3">
                                            <img src="@/assets/img/icons/transaction.svg" class="navbar-link-icon" />
                                            Histórico
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'home.settings' }" class="nav-link px-3">
                                            <img src="@/assets/img/icons/settings.svg" class="navbar-link-icon" />
                                            Ajustes
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'perfil' }" class="nav-link px-3">
                                            <img src="@/assets/img/icons/user.svg" class="navbar-link-icon" />
                                            Perfil
                                        </router-link>
                                    </li>
                                </ul>
                                <ul class="navbar-nav ms-auto mt-2 mt-lg-0 gap-3">
                                    <li class="nav-item">
                                        <a class="nav-link bg-transparent px-0 d-flex align-items-center gap-1" href="#" style="color: #b8becc"
                                            @click.prevent="logout">
                                            <!-- <img src="@/assets/img/icons/close-circle-gray.svg" class="navbar-link-icon" /> -->
                                            <i class="fa-solid fa-xmark fa-lg"></i>
                                            Sair
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>

        <ModalDeleteAll />
        <ModalVerifySecurity />
    </div>
</template>
<script>
    import ModalDeleteAll from "@/components/ModalDeleteAll";
    import ModalVerifySecurity from "@/components/ModalVerifySecurity";
    export default {
        name: "HeaderDashboard",
        components: {
            ModalDeleteAll,
            ModalVerifySecurity
        },
        methods: {
            logout() {
                localStorage.setItem('token', null);
                this.$router.push({
                    name: 'login'
                });
            }
        },
    };
</script>
<style scoped></style>