<template lang="">
    <div>
        <div class="container py-5">
            <div class="row pt-5">
                <div class="col-12 col-md-7 col-lg-6 col-xl-4 mx-auto pt-2">
                    <div class="card border-0">
                        <div class="card-body p-lg-4">
                            <div class="px-lg-3">
                                <div class="text-center mb-4">
                                    <img src="@/assets/logo.png" alt="Zinzer" />
                                </div>
                                <div class="alert alert-danger alert-dismissible fade show" role="alert"
                                    v-if="msgError">
                                    {{msgError}}
                                </div>

                                <div class="alert alert-warning" role="alert" v-if="this.$route.query.erro">
                                    Você precisa fazer login primeiro.
                                </div>
                                <!-- Formulário -->
                                <form action="#" method="post">
                                    <div>
                                        <p class="text-center fw-semibold fs-5 mt-0 color .text-light">
                                            Adicionar um novo <br />
                                            administrador
                                        </p>
                                    </div>
                                    <!--Nome-->
                                    <div class="mb-3">
                                        <label for="email" class="form-label mb-1">Nome de usuário</label>
                                        <input type="name" class="form-control" name="email" id="email" v-model="email"
                                            placeholder="nickname" onkeyup="this.value = this.value.replace(' ', '')" required />
                                    </div>
                                    <!-- Senha -->
                                    <div class="mb-3 pt-2">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label for="password" class="form-label mb-1">Senha</label>
                                        </div>
                                        <div class="position-relative">
                                            <input type="password" class="form-control fst-normal" name="password"
                                                id="password" ref="password" v-model="password" placeholder=""
                                                required />

                                            <button type="button"
                                                class="btn exibir-senha d-flex align-items-center h-100 pe-3"
                                                title="Exibir Senha" @click="showPassword">
                                                <span v-show="showPasswordBool">
                                                    <i class="fa-regular fa-eye-slash fa-sm"></i>
                                                </span>
                                                <span v-show="!showPasswordBool">
                                                    <i class="fa-regular fa-eye fa-sm"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <!-- Cotinuar conectado -->
                                    <div class="form-check form-check-custom">
                                        <input class="form-check-input rounded-0" type="checkbox" value=""
                                            id="continuar-cone" v-model="isChecked" />
                                        <label class="form-check-label fs-12px text-nowrap" for="continuar-cone">
                                            Concordo que essa pessoa tenha acesso ao Zinzer.
                                        </label>
                                    </div>

                                    <div class="mt-3">
                                        <button type="button" @click="crateNewUserAdmin" class="btn btn-green w-100">
                                            Adicionar administrador
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import authService from '@/services/AuthService';
    import swal from 'sweetalert';

    export default {
        name: "CreateNewAdministrator",
        data() {
            return {
                name: '',
                email: '',
                password: '',
                msgError: false,
                showPasswordBool: false,
                isChecked: false
            };
        },
        methods: {
            showPassword: function () {
                const elPassword = this.$refs.password;

                if (elPassword.type == "password") {
                    this.showPasswordBool = true;
                    elPassword.type = "text";
                } else {
                    elPassword.type = "password";
                    this.showPasswordBool = false;
                }
            },
            crateNewUserAdmin: async function () {
                if (this.isChecked && this.email != '' && this.password != '') {
                    let load = this.$loading.show();
                    let formData = {
                        // nome: 'ghost',
                        nome: this.email,
                        senha: this.password
                    }
                    authService.createNewAdmin(formData)
                        .then((res) => {
                            swal({
                                title: res.data.message,
                                icon: "success",
                                button: "OK",
                            })
                            load.hide();
                            this.msgError = false
                            setTimeout(() => {
                                this.$router.push({
                                    name: "perfil",
                                });
                            }, 2000);

                        })
                        .catch(error => {
                            this.msgError = error.response.data.message
                            alert(error.response.data.message)
                            this.$router.replace({
                                erro: ''
                            });
                            load.hide();
                        });
                    load.hide();
                } else if (!this.isChecked || this.email === '' || this.password === '') {
                    this.msgError = 'Preencha todos os dados para continuar.'
                }
            },

        },
        metaInfo() {
            return {
                title: `Adicionar Usuário - ${this.$config.appName}`
            }
        },
    };
</script>
<style scoped>
    @import "@/assets/css/auth.css";

    .exibir-senha {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
    }

    .input-senha {
        padding-right: 50px;
    }
</style>