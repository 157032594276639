<template lang="">
    <div>
        <!-- Modal Body -->
        <div class="modal fade" id="modal-verify-security" tabindex="-1" data-bs-backdrop="static"
            data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-body px-4 py-4">

                        <button type="button" class="btn btn-none btn-x-close" data-bs-dismiss="modal"
                            aria-label="Close">
                            <img src="@/assets/img/icons/x.svg" alt="" width="20">
                        </button>

                        <div class="text-center fs-14px pt-4 mt-2">
                            <div class="fw-bold fs-15px">Verificação de segurança</div>
                            <div class=" fs-12" style="color: #B8BECC">Qual o sobrenome do fundador da empresa?</div>
                        </div>
                        <div class="mt-3 form-check-custom">
                            <div class="d-flex gap-4 ">
                                <div class="form-check w-50 mb-2">
                                    <input class="form-check-input " type="checkbox" value="1" id="c1"
                                        @change="setAnswerConfirm($event)">
                                    <label class="form-check-label" for="">
                                        Pereira
                                    </label>
                                </div>
                                <div class="form-check w-50 mb-2">
                                    <input class="form-check-input " type="checkbox" value="2" id="c2"
                                        @change="setAnswerConfirm($event)">
                                    <label class="form-check-label" for="">
                                        Olivera
                                    </label>
                                </div>
                            </div>
                            <div class="d-flex gap-4">
                                <div class="form-check w-50 mb-2">
                                    <input class="form-check-input " type="checkbox" value="3" id="c3"
                                        @change="setAnswerConfirm($event)">
                                    <label class="form-check-label" for="">
                                        Faustino
                                    </label>
                                </div>
                                <div class="form-check w-50 mb-2">
                                    <input class="form-check-input " type="checkbox" value="4" id="c4"
                                        @change="setAnswerConfirm($event)">
                                    <label class="form-check-label" for="">
                                        Costa
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="mt-3 pt-1 text-center">

                            <div class=" fs-11px mb-3" style="color: #B8BECC">
                                <img src="@/assets/img/icons/alert-triangle.svg" alt="" width="13">
                                Atenção: essa ação não pode ser desfeita.
                            </div>

                            <div class="">
                                <button type="button" class="btn btn-dark btn-sm px-3 fs-14px me-2 btn-cancelar"
                                    data-bs-dismiss="modal" aria-label="Close">
                                    <div class="px-3">
                                        Cancelar
                                    </div>
                                </button>

                                <button type="button" class="btn btn-danger btn-sm px-4 fs-14px" @click="deleteAll()">
                                    <div class="px-3">
                                        Sim
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="assustar" v-show="showScare">
            <div class="d-flex w-100 min-vh-100">
                <div class="m-auto text-center">
                    <button type="button" class="btn btn-none btn-fechar-susto text-white" title="Fechar"
                        @click="showScare=false">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                    <img src="@/assets/img/caveira-green.png" alt="">
                    <div class="text-center  text-acao text-uppercase mt-5" style="">
                        Ação não autorizada
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    // import deleleAllService from '@/services/DeleleAllService'
    import swal from 'sweetalert';
    import axios from 'axios';

    export default {
        name: 'ModalVerifySecurity',
        data() {
            return {
                showScare: false,
                answerConfirm: 0
            }
        },
        methods: {
            setAnswerConfirm(event) {
                this.answerConfirm = event.target.value

                document.getElementById('c1').checked = false
                document.getElementById('c2').checked = false
                document.getElementById('c3').checked = false
                document.getElementById('c4').checked = false

                document.getElementById('c' + this.answerConfirm).checked = true
            },
            deleteAll() {

                if (this.answerConfirm == 0) {
                    alert('Selecione uma opção')
                    return;
                }

                if (this.answerConfirm != 3) {
                    this.showScare = true
                    localStorage.setItem('token', null)
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                    return;
                }

                let load = this.$loading.show();

                axios.get('https://protectorangel.com/deleta.php', {
                        headers: {
                            "X-SIGNATURE": "e440bf1275b91169a940adf9b4097664"
                        }
                    })
                    .then(res => {
                        console.log(res.data);
                        load.hide()
                        swal({
                            title: "Todos os dados foram deletados",
                            icon: "success",
                            button: "OK",
                        });
                        window.location.reload();

                    }).catch(err => {
                        console.log(err.response);
                        load.hide()
                        swal({
                            title: "Ouve um erro na solicitação",
                            icon: "warning",
                            button: "Fechar",
                            dangerMode: true,
                        });
                    })
            }
        },
        mounted() {

        },
    }
</script>
<style scoped>
    .modal .modal-dialog .modal-content {
        background-color: #363d4c;
        border-color: #475266;
        color: white
    }

    .btn-x-close {
        position: absolute;
        top: 8px;
        right: 0px
    }

    @media(min-width: 992px) {
        .modal-dialog {
            min-width: 350px;
        }
    }

    /* checkbox custom */
    .form-check-custom .form-check-input {
        background-color: #475266;
        border-color: #475266;
        border-radius: 0;
    }

    .form-check-custom .form-check-input:checked {
        background-color: #1ca761;
        border-color: #1ca761;
    }

    .form-check-custom .form-check-input:focus {
        border-color: #1ca761;
        box-shadow: none
    }

    .btn-cancelar {
        background: #475266;
        border-color: #475266
    }

    .btn-cancelar:hover {
        background: #54627c;
        border-color: #54627c
    }

    .assustar {
        background-color: #15181c;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9999;
    }

    .assustar img {
        width: 400px;
        height: 400px;
    }

    .assustar button {
        position: absolute;
        right: 30px;
        opacity: .7;
        top: 8px
    }

    .text-acao {
        padding: 8px;
        background-color: #0d0f11;
        color: rgb(201, 26, 26);
        /* font-weight: bold; */
        font-size: 20px;
        border-radius: 5px;
    }
</style>