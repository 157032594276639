import api from './ApiService';

export default {
    getData(formData) {
        return api.post('/graficos', formData)
    },
    getDataHome() {
        return api.get('/info/home.php')
    },
    getDataMeis() {
        return api.get('/cnpj/index.php')
    },
    filterDataGraphics(formDataFilter) {
        return api.post('/graficos', formDataFilter)
    },
    getDataHist(idMei){
        return api.get(`/cnpj/hist.php?id=${idMei}`)
    }
}
