import api from './ApiService';

export default {
    getLoggedUser(id) {
        return api.get(`/usuario/${id}`)
    },
    getUser(user_id) {
        return api.get(`/usuario/${user_id}`)
    },
    updateUser(user_id, formData) {
        return api.put(`/usuario/${user_id}`, formData)
    },
    deleteUser(user_id) {
        return api.delete(`/usuario/${user_id}`)
    },
}
