import api from './ApiService';

export default {
    getDataHome() {
        return api.get('/home')
    },
    // adicionar api nova
    getXlsToDownload(formData) {
        return api.post('/info/xls.php', formData)
    },
    getDataMeisAgruped(allData) {
        let data = allData
        let dataMl1 = [];
        let dataMl2 = [];
        for (let i in data) {
            if (data[i].ml == 1) {
                dataMl1.push(data[i])
            } else {
                dataMl2.push(data[i])
            }
        }

        return {
            dataMl1: dataMl1,
            dataMl2: dataMl2,
        }
    },
    downloadFileXlsHist(base64File, type) {
        const base64Content = base64File // conteúdo do arquivo em formato Base64
        const date = new Date()
        const fileName =
            `hist_ltda_${type}_${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate()}${date.getHours()}${date.getMinutes()}.xls`

        const link = document.createElement('a');
        link.href = 'data:application/octet-stream;base64,' + base64Content;
        link.download = fileName;
        link.click();
    },
}
