<template>
    <div>
        <div class="container py-5">
            <div class="row pt-5">
                <div class="col-12 col-md-7 col-lg-6 col-xl-4 mx-auto pt-2">
                    <div class="card border-0">
                        <div class="card-body p-lg-4">
                            <div class="px-lg-3">
                                <div class="text-center mb-5">
                                    <img src="@/assets/logo.png" alt="Zinzer" />
                                </div>

                                <div class="alert alert-danger alert-dismissible fade show" role="alert"
                                    v-if="msgError">
                                    {{ msgError }}
                                </div>

                                <div class="alert alert-warning" role="alert" v-if="this.$route.query.erro">
                                    Você precisa fazer login primeiro.
                                </div>

                                <!-- Formulário -->
                                <form action="#" method="post" @submit.prevent="login">
                                    <!-- E-mail -->
                                    <div class="mb-3">
                                        <label for="email" class="form-label mb-1">Nome de usuário</label>
                                        <input class="form-control" name="email" id="email" v-model="email"
                                            placeholder="Nickname" required />
                                    </div>
                                    <!-- Senha -->
                                    <div class="mb-3 pt-2">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label for="password" class="form-label mb-1">Senha</label>
                                            <!-- <router-link :to="{name: 'forgotpassword'}"
                                                class="text-green fs-13px fw-500 text-decoration-none">
                                                Esqueceu a senha?
                                            </router-link> -->
                                        </div>
                                        <div class="position-relative">
                                            <input type="password" class="form-control input-senha" name="password"
                                                id="password" ref="password" placeholder="" v-model="password"
                                                required />

                                            <button type="button"
                                                class="btn exibir-senha d-flex align-items-center h-100 pe-3"
                                                title="Exibir Senha" @click="showPassword">
                                                <span v-show="showPasswordBool">
                                                    <i class="fa-regular fa-eye-slash fa-sm"></i>
                                                </span>
                                                <span v-show="!showPasswordBool">
                                                    <i class="fa-regular fa-eye fa-sm"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <!-- Cotinuar conectado -->
                                    <div class="form-check form-check-custom">
                                        <input class="form-check-input rounded-0" type="checkbox" value=""
                                            id="continuar-cone" />
                                        <label class="form-check-label fs-14px" for="continuar-cone">
                                            Continuar conectado
                                        </label>
                                    </div>

                                    <div class="mt-3">
                                        <button type="submit" class="btn btn-green w-100">
                                            Login
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import authService from "@/services/AuthService";
    // import axios from "axios";
    export default {
        name: "LoginUser",
        data() {
            return {
                showPasswordBool: false,
                email: "",
                password: "",
                msgError: false,
            };
        },
        methods: {
            showPassword: function () {
                const elPassword = this.$refs.password;

                if (elPassword.type == "password") {
                    this.showPasswordBool = true;
                    elPassword.type = "text";
                } else {
                    elPassword.type = "password";
                    this.showPasswordBool = false;
                }
            },
            login: function () {
                let load = this.$loading.show();

                let formData = {
                    email: this.email,
                    senha: this.password,
                }

                authService
                    .login(formData)
                    .then((res) => {
                        localStorage.setItem("id", res.data.id);
                        localStorage.setItem("token", res.data.token);
                        this.$router.push({
                            name: "home.dashboard",
                        });
                        load.hide();
                        this.msgError = false;
                    })
                    .catch(() => {
                        this.msgError = "Usuário inválido. Não foi possível realizar o login.";
                        this.$router.replace({
                            erro: "",
                        });
                        load.hide();
                    });
            },
        },
        metaInfo: {
            titleTemplate: "Login - Zinzer",
        },
    };
</script>
<style scoped>
    @import "@/assets/css/auth.css";

    .exibir-senha {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
    }

    .input-senha {
        padding-right: 50px;
    }
</style>