<template>
    <div class="box-coluna">
        <div class="text-dark py-0 pt-1" style="margin-top: -10px; margin-bottom: -14px;" v-if="showChart">
            <apexchart width="100%" height="130" type="area" :options="chart.chartOptions"
                :series="chart.chartSeries" />
        </div>
        <!-- Load -->
        <div class="py-5" v-else>
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-secondary" style="width: 1rem; height: 1rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import apexchart from 'vue-apexcharts'
    export default {
        name: 'Chart7Days',
        data() {
            return {
                showChart: false,
                chart: {
                    chartOptions: {
                        show: true,
                        borderColor: '#fff',
                        chart: {
                            id: 'vuechart-example',
                            curve: 'smooth',
                            toolbar: {
                                show: false
                            },
                        },
                        tooltip: {
                            marker: {
                                fillColors: ['#E5C317']
                            },
                            y: {
                                formatter: function (val) {
                                    if (val >= 1000) {
                                        let numeroAbreviado = (val / 1000).toFixed(0);
                                        numeroAbreviado += "K";
                                        return numeroAbreviado;
                                    } else {
                                        return val.toFixed(0);
                                    }
                                }
                            }
                        },
                        fill: {
                            colors: ['#E5C317', '#E91E63', '#9C27B0'],
                            opacity: 1,
                            gradient: {
                                shade: 'dark',
                                type: "vertical",
                                shadeIntensity: 0.5,
                                gradientToColors: undefined,
                                inverseColors: false,
                                opacityFrom: 1,
                                opacityTo: '0.8',
                                colorStops: []
                            },
                        },
                        markers: {
                            size: 3,
                            strokeColors: '#fff',
                            colors: ['#fff'],
                            // offsetX: -5,
                            // offsetY: -5,
                            hover: {
                                size: 4
                            }
                        },
                        stroke: {
                            curve: 'straight',
                            colors: ['#E5C317'],
                            width: 3,
                        },
                        xaxis: {
                            categories: ['SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB', 'DOM'],
                            labels: {
                                style: {
                                    colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                        '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',
                                    ],
                                },
                            },
                            tooltip: {
                                enabled: false,
                            },
                        },
                        yaxis: {
                            show: true,
                            labels: {
                                show: false,
                                style: {
                                    colors: ['#fff'],
                                },
                            },
                        },
                        grid: {
                            show: true,
                            borderColor: '#1ca761',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: false,
                                    color: '#1ca761'
                                },
                            },
                            yaxis: {
                                lines: {
                                    show: false,
                                }
                            }
                        },
                        dataLabels: {
                            enabled: true, // tooltip visivel
                            // offsetX: -10,
                            offsetY: -7,
                            style: {
                                colors: [
                                    'rgba(0,0,0,.0)',
                                ],
                                fontSize: '11px',
                                borderColor: '#f00'
                            },
                            formatter: function (val) {
                                if (val >= 1000) {
                                    let numeroAbreviado = (val / 1000).toFixed(1);
                                    numeroAbreviado += "K";
                                    return numeroAbreviado;
                                } else {
                                    return val.toFixed(0);
                                }
                            },
                            background: {
                                enabled: true,
                                padding: 0,
                                borderRadius: 2,
                                borderWidth: 1,
                                borderColor: 'transparent',
                                opacity: 0.9,
                            },
                        },
                    },
                    chartSeries: [{
                        name: 'Entradas',
                        data: [2342, 8362, 9272, 2512, 3122, 2342, 6233],
                    }],
                }
            }
        },
        components: {
            apexchart
        },
        props: ['weeklyData'],
        watch: {
            weeklyData() {
                this.setDataChart()
            }
        },
        methods: {
            setDataChart() {
                this.chart.chartOptions.xaxis.categories = this.weeklyData.dias
                this.chart.chartSeries[0].data = this.weeklyData.dados
                this.showChart = true
            }
        },
    }
</script>
<style></style>