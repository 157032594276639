import axios from "axios";

const api = "https://mapapun.com:3500";

export default {
  getXlsFile(formData) {
    return api.post("/info/xls.php", formData);
  },
  getWithCnpj(cnpj) {
    return api.get(`https://mapapun.com:3500/notas/${cnpj}`);
  },
  getTableData() {
    return axios.get(`https://mapapun.com:3500/notas`);
  },
  getAllCnpjsEntries() {
    return axios.get(`https://mapapun.com:3500/notas/42782443000121/entradas/`);
  },
  getAllCnpjsExits() {
    return axios.get(`https://mapapun.com:3500/saidas/`);
  },
  getCpfData() {
    return axios.get("https://mapapun.com:3500/notas/cpf");
  },
  getCnpjData() {
    return axios.get("https://mapapun.com:3500/notas/cnpj");
  },
  getTableDataWithNote(notes) {
    return axios.get(`https://mapapun.com:3500/notas/${notes}`);
  },
  getAccounts() {
    return api.post(`${api}`);
  },
  getHistoricWithFilter(formData) {
    return api.post("/info/historico.php", formData);
  },
};
