<template>
    <div class="box-coluna">
        <div class="p-3" v-if="showChart">
            <div class="d-flex flex-column flex-lg-row justify-content-lg-between align-items-center ">
                <h2 class="h5 fs-5">
                    Estados que mais compram
                </h2>
                <div class="">
                    <div class="dropdown">
                        <button
                            :class="`btn btn-dark-zinzer text-white rounded-2   shadow  d-flex align-items-center gap-1 ${dataGraphics.estados['30d'].estados.length == 0 ? 'disabled':''}`"
                            type="button" id="drop-mei-123" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">

                            <span v-if="period == '30d'"> Últimos 30 dias </span>
                            <span v-else> Últimos 12 meses </span>
                            <img src="@/assets/img/icons/chevron-down-white.svg" alt="" width="20" class="">
                        </button>
                        <div class="dropdown-menu" aria-labelledby="drop-mei-123" style="">
                            <a :class="`dropdown-item dropdown-item-menu ${period == '30d' ? 'active' : ''}`"
                                type="button" @click="setPeriod('30d')">
                                Últimos 30 dias
                            </a>
                            <a :class="`dropdown-item dropdown-item-menu ${period == '12m' ? 'active' : ''}`"
                                type="button" @click="setPeriod('12m')">
                                Últimos 12 meses
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center py-4 px-3" v-if="dataGraphics.estados['30d'].estados.length == 0">
                <i class="fa-solid fa-triangle-exclamation fa-sm"></i>
                SEM REGISTROS
            </div>

            <div v-else class="d-flex justify-content-center align-items-center pt-4 pb-4">
                <div class="d-flex align-items-center gap-0 mt-2 div-estados-compram pe-5">
                    <!-- Gráfico -->
                    <div class="text-white" style="margin-left: -65px; margin-top: -100px; margin-bottom: -100px;">
                        <apexchart type="pie" width="460" :options="chart.chartOptions" :series="chart.series">
                        </apexchart>
                    </div>

                    <!-- Lista de estados -->
                    <div class="p-3">

                        <div class="d-flex gap-4 fs-14px">
                            <ul class="list-unstyled mb-0">
                                <li class="" v-for="(item, index) in statesCol1()" :key="index">
                                    {{ index + 1 }}. {{ item }}
                                </li>
                            </ul>
                            <ul class="list-unstyled mb-0">
                                <li class="" v-for="(item, index) in statesCol2()" :key="index">
                                    {{ index + 15 }}. {{ item }}
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <!-- Load -->
        <div class="py-5" v-else>
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-secondary" style="width: 1rem; height: 1rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import apexchart from 'vue-apexcharts'
    export default {
        name: 'StatesBuyMost',
        components: {
            apexchart
        },
        data() {
            return {
                period: '30d',
                showChart: false,
                chart: {
                    series: [44, 55, 13, 43, 22, 44, 55, 13],
                    chartOptions: {
                        stroke: {
                            show: false,
                        },
                        colors: ['#8044FF', '#44A5FF', '#FF44EC', '#DAFF44', '#44FF78', '#FF4471', '#FF7144', '#FFCB44',
                            '#F44336', '#E91E63', '#9C27B0'
                        ],
                        chart: {
                            width: 380,
                            type: 'pie',
                        },
                        legend: {
                            show: false,
                        },
                        labels: ['SP', 'RS', 'RJ', 'GO', 'PR', 'SC', 'DF', 'Out.'],
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontSize: '15px'
                            },
                            formatter: function (value, opts) {
                                return [opts.w.globals.labels[opts.seriesIndex], value.toFixed(0) + '%']
                            }
                        },
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }]
                    },
                }
            }
        },
        props: ['dataGraphics'],
        watch: {
            dataGraphics(data) {
                if (data.estados) {
                    this.setDataChartStates()
                }
            }
        },
        methods: {
            statesCol1() {
                let data = []
                for (let i in this.dataGraphics.estados[this.period].estados) {
                    if (i <= 13) {
                        data.push(this.dataGraphics.estados[this.period].estados[i])
                    }
                }
                return data
            },
            statesCol2() {
                let data = []
                for (let i in this.dataGraphics.estados[this.period].estados) {
                    if (i > 13) {
                        data.push(this.dataGraphics.estados[this.period].estados[i])
                    }
                }
                return data
            },
            setDataChartStates() {
                // dataGraphics
                let labels = []
                let data = []
                let percentOut = 0

                if (this.dataGraphics.estados[this.period].estados)
                    for (let i in this.dataGraphics.estados[this.period].estados) {


                        if (i < 7) {
                            // add state
                            labels.push(this.dataGraphics.estados[this.period].estados[i]);

                            // add data
                            if (this.dataGraphics.estados[this.period].quantidade[i]) {
                                data.push(this.dataGraphics.estados[this.period].quantidade[i]);
                            } else {
                                data.push(0);
                            }
                        } else {
                            if (this.dataGraphics.estados[this.period].quantidade[i])
                                percentOut += this.dataGraphics.estados[this.period].quantidade[i]
                        }
                    }

                // add valores de outros estados
                labels.push('Out.')
                data.push(percentOut)

                this.chart.chartOptions.labels = labels
                this.chart.series = data

                this.showChart = true
            },
            setPeriod(period) {
                this.showChart = false
                this.period = period

                setTimeout(() => {
                    this.setDataChartStates()
                }, 200);
            }
        },
    }
</script>
<style lang="">

</style>