<template>
    <div class="home">
        <div class="container-dashboard">
            <div class="container-fluid px-lg-4">
                <div class="row">

                    <!-- msg sem registro ml1 -->
                    <div class="col-12 col-lg-6 mx-auto mt-5 pt-4 min-vh-100" v-if="accountsMl1.length == 0">
                        <div class="border border-2 text-center p-4 text-white rounded-3 py-5 fs-4 mt-3">
                            SEM REGISTROS ML 1

                            <div class="mt-4">
                                <router-link :to="{ name: 'home.settings', query: { addCnpj: 'true', ml: 1 } }"
                                    class="btn btn-green  btn-sm px-4 rounded-pill">
                                    <i class="fa-solid fa-circle-plus fa-sm"></i>
                                    Cadastrar CNPJ
                                </router-link>
                            </div>

                        </div>
                    </div>

                    <!-- Início ML1 -->
                    <div class="col-12 col-lg-6 col-data-account py-4 position-relative " v-if="accountsMl1.length > 0">

                        <!-- Add cnpj -->
                        <div class="add-cnpj-home">
                            <div class="dropdown">
                                <button type="button" class="btn btn-none p-0 border-0 shadow-none"
                                    id="selecionar-mei-ml1" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <img src="@/assets/img/icons/plus-circle-2.svg" alt="Adicionar CNPJ" width="34"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Adicionar CNPJ">
                                </button>
                                <div class="dropdown-menu dropdown-mei-home bg-dark"
                                    aria-labelledby="selecionar-mei-ml1" style="z-index: 9999;">
                                    <div class="" v-for="(item, index) in dataApiHome" :key="index">

                                        <div class="" v-if="item.ml.toString().indexOf('1') !== -1">
                                            <a class="dropdown-item fs-12px" href="#/painel"
                                                @click="toggleMeiMl1(item.id)" v-if="item.id > 2">
                                                {{ item.nome }}
                                            </a>
                                        </div>
                                        <div class="" v-else>
                                            <a class="dropdown-item fs-12px" href="#/painel"
                                                @click="addCnpjMl1(item.id)" v-if="item.id > 2">
                                                {{ item.nome }}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <!-- MEIs -->
                            <div class="col-12 ">
                                <div class="">
                                    <!-- Dados -->
                                    <div class="data-account" style="margin-top: 60px;">
                                        <div class="" v-for="(dataCnpj, index) in accountsMl1" :key="index">
                                            <!-- Component -->
                                            <PanelMei :data-cnpj="dataCnpj" :index="index" ml="1" />
                                        </div>

                                        <!-- Alternar -->
                                        <button type="button" class="btn btn-none btn-toggle-account border-0"
                                            v-if="accountsMl1.length > 0" @click="toggleAccountMl1()">
                                            <img src="@/assets/img/icons/rotate.svg" alt="" class="w-100">
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <!-- Painale LTDA ML1 - Soma de todos os dados dos MEIs -->
                            <div class="col-12 ">
                                <div class="" v-if="dataLtda1 != ''">
                                    <div class="data-account" style="margin-top: 60px;">
                                        <PanelLtda :border-color="colorBorderLtda1" :data-ltda="dataLtda1" :ml="1" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- msg sem registro ml2 -->
                    <div class="col-12 col-lg-6 mx-auto mt-5 pt-4 min-vh-100" v-if="accountsMl2.length == 0">
                        <div class="border border-2 text-center p-4 text-white rounded-3 py-5 fs-4 mt-3">
                            SEM REGISTROS ML 2
                            <div class="mt-4">
                                <router-link :to="{ name: 'home.settings', query: { addCnpj: 'true', ml: 2 } }"
                                    class="btn btn-green  btn-sm px-4 rounded-pill">
                                    <i class="fa-solid fa-circle-plus fa-sm"></i>
                                    Cadastrar CNPJ
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- Início ML2 -->
                    <div class="col-12 col-lg-6 col-data-account py-4 position-relative " v-if="accountsMl2.length > 0">
                        <!-- Add cnpj -->

                        <div class="add-cnpj-home">
                            <div class="dropdown">
                                <button type="button" class="btn btn-none p-0 border-0 shadow-none"
                                    id="selecionar-mei-ml2" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <img src="@/assets/img/icons/plus-circle-3.svg" alt="Adicionar CNPJ" width="34"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Adicionar CNPJ">
                                </button>
                                <div class="dropdown-menu dropdown-mei-home bg-dark"
                                    aria-labelledby="selecionar-mei-ml2" style="z-index: 9999;">
                                    <div class="" v-for="(item, index) in dataApiHome" :key="index">

                                        <div class="" v-if="item.ml.toString().indexOf('2') !== -1">
                                            <a class="dropdown-item fs-12px" href="#/painel"
                                                @click="toggleMeiMl2(item.id)" v-if="item.id > 2">
                                                {{ item.nome }}
                                            </a>
                                        </div>
                                        <div class="" v-else>
                                            <a class="dropdown-item fs-12px" href="#/painel"
                                                @click="addCnpjMl2(item.id)" v-if="item.id > 2">
                                                {{ item.nome }}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <!-- MEIs -->
                            <div class="col-12 ">
                                <div class="">
                                    <!-- Dados -->
                                    <div class="data-account" style="margin-top: 60px;">
                                        <div class="" v-for="(dataCnpj, index) in accountsMl2" :key="index">
                                            <PanelMei :data-cnpj="dataCnpj" :index="index" ml="2" />
                                        </div>

                                        <!-- Alternar -->
                                        <button type="button" class="btn btn-none btn-toggle-account border-0"
                                            v-if="accountsMl2.length > 0" @click="toggleAccountMl2()">
                                            <img src="@/assets/img/icons/rotate.svg" alt="" class="w-100">
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <!-- Painel LTDA 2 - Soma de todos os dados dos MEIs -->
                            <div class="col-12 ">
                                <div class="" v-if="dataLtda2 != ''">
                                    <div class="data-account" style="margin-top: 60px;">
                                        <PanelLtda :border-color="colorBorderLtda2" :data-ltda="dataLtda2" :ml="2" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import PanelMei from '@/components/PanelMei'
    import PanelLtda from '@/components/PanelLtda'
    import alertService from '@/services/AlertService';
    import cnpjService from '@/services/CnpjService';
    import homeService from '@/services/HomeService';
    import {
        Tooltip
    } from 'bootstrap'

    export default {
        name: 'HomeDashboard',
        components: {
            PanelMei,
            PanelLtda
        },
        data() {
            return {
                accountsMl1: [],
                accountsMl2: [],
                dataLtda1: '',
                dataLtda2: '',
                indexAccountCurrent1: 0,
                indexAccountCurrent2: 0,
                colorBorderLtda1: '#f2f20c',
                colorBorderLtda2: '#f2f20c',
                dataApiHome: [],
            };
        },
        methods: {
            toggleAccountMl1() {
                this.indexAccountCurrent1 += 1
                if (this.indexAccountCurrent1 > (this.accountsMl1.length - 1)) {
                    this.indexAccountCurrent1 = 1
                }
                let indexCurrent = this.indexAccountCurrent1

                let elFirst = document.querySelectorAll(`.box-account-0-mei-1`)[0]
                let elNext = document.querySelectorAll(`.box-account-${indexCurrent}-mei-1`)[0]
                let classFirst = elFirst.className
                let classNext = elNext.className

                elFirst.className = classNext
                elNext.className = classFirst

                this.setBorderColorLtda1()

            },
            toggleAccountMl2() {
                this.indexAccountCurrent2 += 1
                if (this.indexAccountCurrent2 > (this.accountsMl2.length - 1)) {
                    this.indexAccountCurrent2 = 1
                }
                let indexCurrent = this.indexAccountCurrent2

                let elFirst = document.querySelectorAll(`.box-account-0-mei-2`)[0]
                let elNext = document.querySelectorAll(`.box-account-${indexCurrent}-mei-2`)[0]
                let classFirst = elFirst.className
                let classNext = elNext.className

                elFirst.className = classNext
                elNext.className = classFirst

                this.setBorderColorLtda2()
            },
            toggleMeiMl1(idCnpj) {

                let elAtual = document.querySelectorAll('.box-account-0-mei-1')[0]
                let elNovo = document.getElementById(idCnpj + '-ml1')

                let classAtual = elAtual.className
                let classNovo = elNovo.className

                elAtual.className = classNovo
                elNovo.className = classAtual

            },
            toggleMeiMl2(idCnpj) {
                let elAtual = document.querySelectorAll('.box-account-0-mei-2')[0]
                let elNovo = document.getElementById(idCnpj + '-ml2')

                let classAtual = elAtual.className
                let classNovo = elNovo.className

                elAtual.className = classNovo
                elNovo.className = classAtual
            },
            setBorderColorLtda1() {
                let elFirst = document.querySelectorAll(`.box-account-0-mei-1`)[0]
                this.colorBorderLtda1 = elFirst.style.borderColor
            },
            setBorderColorLtda2() {
                let elFirst = document.querySelectorAll(`.box-account-0-mei-2`)[0]
                this.colorBorderLtda2 = elFirst.style.borderColor
            },
            async getDataHomeAccounts() {
                let load = this.$loading.show();
                await homeService.getDataHome()
                    .then(res => {
                        load.hide()
                        this.setDataCnpjs(res.data)
                        this.dataApiHome = res.data
                    }).catch(err => {
                        console.log(err.response.data);
                        load.hide()
                    })
            },
            setDataCnpjs(data) {
                /* ml1 */
                this.accountsMl1 = data.filter(value => {
                    return value.id > 2 && value.ml.toString().indexOf('1') !== -1;
                })

                /* ml2 */
                this.accountsMl2 = data.filter(value => {
                    return value.id > 2 && value.ml.toString().indexOf('2') !== -1;
                })

                console.log('xx', this.accountsMl2);

                /* add dados LTDA */
                data.forEach(item => {
                    if (item.id == 1)
                        this.dataLtda1 = item;
                    if (item.id == 2)
                        this.dataLtda2 = item;
                });

                setTimeout(() => {
                    this.addPanelMeiFirstIfQueryString()
                }, 1000);
            },
            // ser for pasado parâmetros na url para selecionar qual o painel mei vai ser exibido primeiro
            addPanelMeiFirstIfQueryString() {
                console.log(this.$route.query.ml, this.$route.query.mei);
                if (this.$route.query.ml == 1 && this.$route.query.mei) {
                    this.toggleMeiMl1(this.$route.query.mei)
                }
                if (this.$route.query.ml == 2 && this.$route.query.mei) {
                    this.toggleMeiMl2(this.$route.query.mei)
                }
            },
            addCnpjMl1(id) {
                let load = this.$loading.show();
                let formData = {
                    ml: 1
                }
                cnpjService
                    .updateCnpj(id, formData)
                    // eslint-disable-next-line
                    .then(res => {
                        this.$router.push({
                            name: 'home.dashboard',
                            query: {
                                mei: id,
                                ml: 1
                            }
                        })
                        location.reload()
                    })
                    .catch(err => {
                        console.log(err.response);
                        load.hide()
                        alertService.error('Ouve um erro na solicitação')
                    })
            },
            addCnpjMl2(id) {
                let load = this.$loading.show();
                let formData = {
                    ml: 2
                }
                cnpjService
                    .updateCnpj(id, formData)
                    // eslint-disable-next-line
                    .then(res => {
                        this.$router.push({
                            name: 'home.dashboard',
                            query: {
                                mei: id,
                                ml: 2
                            }
                        })
                        location.reload()
                    })
                    .catch(err => {
                        console.log(err.response);
                        load.hide()
                        alertService.error('Ouve um erro na solicitação')
                    })
            },
        },
        mounted() {
            this.getDataHomeAccounts();
            new Tooltip(document.body, {
                selector: "[data-bs-toggle='tooltip']",
            })
        },
        metaInfo() {
            return {
                title: `Painel - ${this.$config.appName}`
            }
        },
    }
</script>
<style scoped>
</style>