import { render, staticRenderFns } from "./TaxesPayLtda1.vue?vue&type=template&id=60ded0d9&scoped=true&"
import script from "./TaxesPayLtda1.vue?vue&type=script&lang=js&"
export * from "./TaxesPayLtda1.vue?vue&type=script&lang=js&"
import style0 from "./TaxesPayLtda1.vue?vue&type=style&index=0&id=60ded0d9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60ded0d9",
  null
  
)

export default component.exports