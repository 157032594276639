import api from './ApiService';
import alertService from './AlertService';

export default {
   
    createPurchase(formData) {
        return api.post('/notes/cadastro.php', formData);
    },
    validate(formData) {
        // validar mei
        if (formData.mei == '') {
            alertService.error('Selecione o CNPJ que está comprando')
            return true;
        }
        // validar cnpj
        if (formData.cnpj == '') {
            alertService.error('Selecione o CNPJ do vendedor')
            return true;
        }

        // validar valor
        if (formData.valor == 0) {
            alertService.error('Adicione o valor da compra')
            return true;
        }

        return false
    }
}